import React, { useEffect, useState } from "react";
//import { Link } from 'react-router-dom';
import "./dbsearchcv.scss";
import { useDispatch, useSelector } from "react-redux";
// import Nav from 'react-bootstrap/Nav';
// import Tab from 'react-bootstrap/Tab';
import CreateNewModal from "../../../pages/candidate/projects/savedprojects/modal/createnew/CreateNew";
import SearchFilterModal from "./SearchFilterModal";
import DuplicateCheck from "../pipeline/modal/duplicateCheck";
import {
  showSuccess,
  showError,
  clearMessage,
} from "../../../../slice/utils/message/messageSlice";
import {
  projectDetails,
  deleteProject,
  clearState,
} from "../../../../slice/projects/projctsSlice";

import // totalRecordsCounts,
// recruitersCounts,
// categoryCounts,
// caseStatusCounts,
// caseProgressCounts,
// caseStageCounts,
// flagCounts,
// profileIndicatorCounts,
// genderCounts,
// nativeLanguageCounts,
// fluentLanguageCounts,
// intermediateLanguageCounts,
// nationalityCounts,
// workPermitCounts,
// countryCounts,
// cvSourceCounts,
// companyCounts,
// companyLanguageCounts,
// companyProjectCounts,
// companyRoleCounts,
// profileStatusCounts,
// accountMangerCounts,
// recAdminCounts,
// cityCounts,
// visaCounts,
// careerCounts,
// domainCounts,
// jobTitleCounts,
// industriesCounts,
// pipelineCount,
// itSkillsCounts,
// jobSkillsCounts,
// desiredSalaryCount,
// desiredLocationCount,
// desiredBenefitsCount,
// workingLocationgCount,
// relocationAbroadCount,
// jobCityCount,
// jobCountryCount,
// jobLocalityCount,
// jobProjectCount,
// jobLanguage1Count,
// jobLanguage2Count,
// educationsCount,
// getCanIdCreateProject,
"../../../../slice/searchCV/searchCvSlice";

import {
  FaCheck,
  FaCircle,
  FaUserAlt,
  FaFlag,
  FaRegCalendarAlt,
} from "react-icons/fa";

const DBSearchcv = () => {
  const dispatch = useDispatch();
  const {
    totalRecords,
    recruitersList,
    categoryList,
    caseStatusList,
    caseProgressList,
    caseStageList,
    flagList,
    profileIndicatorList,
    genderList,
    nativeLanguageList,
    fluentLanguageList,
    intermediateLanguageList,
    nationalityList,
    workPermitList,
    cvSourceList,
    companyList,
    companyLanguageList,
    companyProjectList,
    companyRoleList,
    profileStatusList,
    isLoading,
    isError,
    message,
    accountManagerList,
    recAdminList,
    cityList,
    countryList,
    visaList,
    careerList,
    domainList,
    industriesList,
    jobTitleList,
    pipelineList,
    itSkillsList,
    jobskillsList,
    desiredSalaryList,
    desiredLocationList,
    desiredBenefitsList,
    workingLocationgList,
    relocationAbroadList,
    jobCityList,
    jobCountryList,
    jobLocalityList,
    jobProjectList,
    jobLang1,
    jobLang2,
    educationList,
    canIdList,
  } = useSelector((state) => state.searchCV);

  const { candidateCategoryList } = useSelector((state) => state.common);

  const { projectList, isInsert, msg } = useSelector((state) => state.projects);

  // const { categoryCount, flagsCount, genderCount, cvSourcerCount, nationalityCount, languageCount, companyCount, recrutersCount, caseStatusCount, workPermitCount } = useSelector(state => state.candidateDatabase)

  const [search, setSearch] = useState([]);
  const [filter, setFilter] = useState({});
  const [candidateFilter, setCandidateFilter] = useState({});
  const [companyfilter, setCompanyfilter] = useState({});

  const [filterTags, setFilterTags] = useState([]);
  const [tagLength, setTagLength] = useState(0);

  const [isCategoryFilter, setIsCategoryFilter] = useState(false);
  const [categoryFilterText, setCategoryFilterText] = useState("");

  const [isFlagFilter, setIsFlagFilter] = useState(false);
  const [flagFilterText, setFlagFilterText] = useState("");

  const [isGenderFilter, setIsGenderFilter] = useState(false);
  const [genderFilterText, setGenderFilterText] = useState("");

  const [isProfileFilter, setIsProfileFilter] = useState(false);
  const [profileFilterText, setProfileFilterText] = useState("");

  const [isEuNonEuFilter, setIsEuNonEuFilter] = useState(false);
  const [euNonEuFilterText, setEuNonEuFilterText] = useState("");

  const [isRecruiterFilter, setIsRecruiterFilter] = useState(false);
  const [recruiterFilterText, setRecruiterFilterText] = useState("");

  const [isRecAdminFilter, setIsRecAdminFilter] = useState(false);
  const [recAdminFilterText, setRecAdminFilterText] = useState("");

  const [isAccountMangerFilter, setIsAccountMangerFilter] = useState(false);
  const [accountMangerFilterText, setAccountMangerFilterText] = useState("");

  const [isCaseStatusFilter, setIsCaseStatusFilter] = useState(false);
  const [caseStatusFilterText, setCaseStatusFilterText] = useState("");

  const [isCaseProgressFilter, setIsCaseProgressFilter] = useState(false);
  const [caseProgressFilterText, setCaseProgressFilterText] = useState("");

  const [isCaseStageFilter, setIsCaseStageFilter] = useState(false);
  const [caseStageFilterText, setCaseStageFilterText] = useState("");

  const [isCvsourceFilter, setIsCvsourceFilter] = useState(false);
  const [cvsourceFilterText, setCvsourceFilterText] = useState("");

  const [isNationalityFilter, setIsNationalityFilter] = useState(false);
  const [nationalityFilterText, setNationalityFilterText] = useState("");

  const [isJobCompanyFilter, setIsJobCompanyFilter] = useState(false);
  const [jobCompanyFilterText, setJobCompanyFilterText] = useState("");

  const [isJobLanguageFilter, setIsJobLanguageFilter] = useState(false);
  const [jobLanguageFilterText, setJobLanguageFilterText] = useState("");

  const [isJobProjectFilter, setIsJobProjectFilter] = useState(false);
  const [jobProjectFilterText, setJobProjectFilterText] = useState("");

  const [isJobRoleFilter, setIsJobRoleFilter] = useState(false);
  const [jobRoleFilterText, setJobRoleFilterText] = useState("");

  const [isNativeLanguageFilter, setIsNativeLanguageFilter] = useState(false);
  const [nativeLanguageFilterText, setNativeLanguageFilterText] = useState("");

  const [isFluentLanguageFilter, setIsFluentLanguageFilter] = useState(false);
  const [fluentLanguageFilterText, setFluentLanguageFilterText] = useState("");

  const [isIntermediateLanguageFilter, setIsIntermediateLanguageFilter] =
    useState(false);
  const [intermediateLanguageFilterText, setIntermediateLanguageFilterText] =
    useState("");

  const [isProfileStatusFilter, setIsProfileStatusFilter] = useState(false);
  const [profileStatusFilterText, setProfileStatusFilterText] = useState("");

  const [isCityFilter, setIsCityFilter] = useState(false);
  const [cityFilterText, setCityFilterText] = useState("");

  const [isCountryFilter, setIsCountryFilter] = useState(false);
  const [countryFilterText, setCountryFilterText] = useState("");

  const [isVisaFilter, setIsVisaFilter] = useState(false);
  const [visaFilterText, setVisaFilterText] = useState("");

  const [isCareerFilter, setIsCareerFilter] = useState(false);
  const [careerFilterText, setCareerFilterText] = useState("");

  const [isDomainFilter, setIsDomainFilter] = useState(false);
  const [domainFilterText, setDomainFilterText] = useState("");

  const [isIndustriesFilter, setIsIndustriesFilter] = useState(false);
  const [industriesFilterText, setIndustriesFilterText] = useState("");

  const [isITSkillsFilter, setIsITSkillsFilter] = useState(false);
  const [iTSkillsFilterText, setITSkillsFilterText] = useState("");

  const [isJobSkillsFilter, setIsJobSkillsFilter] = useState(false);
  const [jobSkillsFilterText, setJobSkillsFilterText] = useState("");

  const [isJobTitleFilter, setIsJobTitleFilter] = useState(false);
  const [jobTitleFilterText, setJobTitleFilterText] = useState("");

  const [isPipelineFilter, setIsPipelineFilter] = useState(false);
  const [pipelineFilterText, setPipelineFilterText] = useState("");

  const [isDSalaryFilter, setIsDSalaryFilter] = useState(false);
  const [dSalaryFilterText, setDSalaryFilterText] = useState("");

  const [isDLocationFilter, setIsDLocationFilter] = useState(false);
  const [dLocationFilterText, setDLocationFilterText] = useState("");

  const [isDBenefitsFilter, setIsDBenefitsFilter] = useState(false);
  const [dBenefitsFilterText, setDBenefitsFilterText] = useState("");

  const [isWorkingLocationFilter, setIsWorkingLocationFilter] = useState(false);
  const [workingLocationFilterText, setWorkingLocationFilterText] =
    useState("");

  const [isRelocationFilter, setIsRelocationAbroadFilter] = useState(false);
  const [relocationFilterText, setRelocationAbroadFilterText] = useState("");

  const [isJobCityFilter, setIsJobCityFilter] = useState(false);
  const [jobCityFilterText, setJobCityFilterText] = useState("");

  const [isJobCountryFilter, setIsJobCountryFilter] = useState(false);
  const [jobCountryFilterText, setJobCountryFilterText] = useState("");

  const [isLocalityFilter, setIsLocalityFilter] = useState(false);
  const [localityFilterText, setLocalityFilterText] = useState("");

  const [isProjectFilter, setIsProjectFilter] = useState(false);
  const [projectFilterText, setProjectFilterText] = useState("");

  const [isJobLang1Filter, setIsJobLang1Filter] = useState(false);
  const [jobLang1FilterText, setJobLang1FilterText] = useState("");

  const [isJobLang2Filter, setIsJobLang2Filter] = useState(false);
  const [jobLang2FilterText, setJobLang2FilterText] = useState("");

  const [isEducationFilter, setIsEducationFilter] = useState(false);
  const [educationFilterText, setEducationFilterText] = useState("");

  const [removeFilter, setRemoveFilter] = useState(0);
  const getRequestParams = (filter) => {
    let params = {};
    params["filter"] = filter;
    params["search"] = search;
    return params;
  };

  const hideFilterModal = () => {
    setFilterModalShow(false);
  };

  const [isCreateNew, setIsCreateNew] = useState(false);
  const [isJsonFilter, setJsonFilter] = useState("");

  const showAddpopup = () => {
    setIsCreateNew(true);
  };
  const showcreatenewModal = () => {
    // console.log(isJsonFilter)
    setJsonFilter(canIdList);
    setIsCreateNew(true);
  };
  const hidecreatenewModal = () => {
    setIsCreateNew(false);
  };
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (isError) {
      dispatch(showError({ msg: msg }));
      setIsCreateNew(false);
      dispatch(clearState());
      setSuccess(true);
      setLoading(false);
    }
    if (isInsert) {
      setIsCreateNew(false);
      dispatch(showSuccess({ msg: msg }));
      dispatch(clearState());
      setSuccess(true);
      setLoading(false);
    }
  }, [isError, isInsert]);

  const getFilterRequestParams = (candidateFilter, companyfilter) => {
    let params = {};
    params["search"] = search;
    params["filter"] = {
      candidateFilter: candidateFilter,
      companyfilter: companyfilter,
    };
    return params;
  };

  const isEmpty = (obj) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  useEffect(() => {
    if (removeFilter > 0 && isEmpty(filter)) {
      try {
        const params = getRequestParams(filter);
        // dispatch(categoryCounts(params));
        // dispatch(flagCounts(params));
        // dispatch(genderCounts(params));
        // dispatch(profileIndicatorCounts(params));
        // dispatch(workPermitCounts(params));
        // dispatch(recruitersCounts(params));
        // dispatch(caseStatusCounts(params));
        // dispatch(caseProgressCounts(params));
        // dispatch(caseStageCounts(params));
        // dispatch(cvSourceCounts(params));
        // dispatch(nationalityCounts(params));
        // dispatch(companyCounts(params));
        // dispatch(companyLanguageCounts(params));
        // dispatch(companyProjectCounts(params));
        // dispatch(companyRoleCounts(params));
        // dispatch(nativeLanguageCounts(params));
        // dispatch(fluentLanguageCounts(params));
        // dispatch(educationsCount(params));
        // dispatch(intermediateLanguageCounts(params));
        // dispatch(profileStatusCounts(params));
        // dispatch(accountMangerCounts(params));
        // dispatch(recAdminCounts(params));
        // dispatch(cityCounts(params));
        // dispatch(visaCounts(params));
        // dispatch(careerCounts(params));
        // dispatch(domainCounts(params));
        // dispatch(jobTitleCounts(params));
        // dispatch(industriesCounts(params));
        // dispatch(itSkillsCounts(params));
        // dispatch(jobSkillsCounts(params));
        // dispatch(pipelineCount(params));
        // dispatch(countryCounts(params));
        // dispatch(desiredSalaryCount(params));
        // dispatch(desiredLocationCount(params));
        // dispatch(desiredBenefitsCount(params));
        // dispatch(workingLocationgCount(params));
        // dispatch(relocationAbroadCount(params));
        // dispatch(jobCityCount(params));
        // dispatch(jobCountryCount(params));
        // dispatch(jobLocalityCount(params));
        // dispatch(jobProjectCount(params));
        // dispatch(jobLanguage1Count(params));
        // dispatch(jobLanguage2Count(params));
      } catch (err) {
        console.log(err);
      }
    }
  }, [removeFilter]);

  useEffect(() => {
    async function fetchData() {
      try {
        const params = getRequestParams(filter);
        // dispatch(totalRecordsCounts(params))
        // dispatch(recruitersCounts(params))
        // dispatch(categoryCounts(params))
        // dispatch(caseStatusCounts(params))
        // dispatch(caseProgressCounts(params))
        // dispatch(caseStageCounts(params))
        // dispatch(flagCounts(params))
        // dispatch(profileIndicatorCounts(params))
        // dispatch(genderCounts(params))
        // dispatch(nativeLanguageCounts(params))
        // dispatch(fluentLanguageCounts(params))
        // dispatch(educationsCount(params))
        // dispatch(intermediateLanguageCounts(params))
        // dispatch(nationalityCounts(params))
        // dispatch(workPermitCounts(params))
        // dispatch(countryCounts(params))
        // dispatch(cvSourceCounts(params))
        // dispatch(profileStatusCounts(params))
        // dispatch(companyCounts(params))
        // dispatch(companyLanguageCounts(params))
        // dispatch(companyProjectCounts(params))
        // dispatch(companyRoleCounts(params))
        // dispatch(accountMangerCounts(params))
        // dispatch(recAdminCounts(params))
        // dispatch(cityCounts(params))
        // dispatch(visaCounts(params))
        // dispatch(careerCounts(params))
        // dispatch(domainCounts(params))
        // dispatch(jobTitleCounts(params))
        // dispatch(industriesCounts(params))
        // dispatch(itSkillsCounts(params))
        // dispatch(jobSkillsCounts(params))
        // dispatch(pipelineCount(params))
        // dispatch(desiredSalaryCount(params))
        // dispatch(desiredLocationCount(params))
        // dispatch(desiredBenefitsCount(params))
        // dispatch(workingLocationgCount(params))
        // dispatch(relocationAbroadCount(params))
        // dispatch(jobCityCount(params))
        // dispatch(jobCountryCount(params))
        // dispatch(jobLocalityCount(params))
        // dispatch(jobProjectCount(params))
        // dispatch(jobLanguage1Count(params))
        // dispatch(jobLanguage2Count(params))
      } catch (err) {
        console.log(err);
      }
    }
    if (isEmpty(filter)) {
      fetchData();
    }
  }, [filter]);

  useEffect(() => {
    if (!isEmpty(filter)) {
      const params = getFilterRequestParams(candidateFilter, companyfilter);
      // dispatch(getCanIdCreateProject(params))
      // dispatch(categoryCounts(params))
      // dispatch(flagCounts(params))
      // dispatch(genderCounts(params))
      // dispatch(profileIndicatorCounts(params))
      // dispatch(workPermitCounts(params))
      // dispatch(recruitersCounts(params))
      // dispatch(caseStatusCounts(params))
      // dispatch(caseProgressCounts(params))
      // dispatch(caseStageCounts(params))
      // dispatch(cvSourceCounts(params))
      // dispatch(nationalityCounts(params))
      // dispatch(companyCounts(params))
      // dispatch(companyLanguageCounts(params))
      // dispatch(companyProjectCounts(params))
      // dispatch(companyRoleCounts(params))
      // dispatch(nativeLanguageCounts(params))
      // dispatch(educationsCount(params))
      // dispatch(fluentLanguageCounts(params))
      // dispatch(intermediateLanguageCounts(params))
      // dispatch(profileStatusCounts(params))
      // dispatch(accountMangerCounts(params))
      // dispatch(recAdminCounts(params))
      // dispatch(cityCounts(params))
      // dispatch(visaCounts(params))
      // dispatch(careerCounts(params))
      // dispatch(domainCounts(params))
      // dispatch(jobTitleCounts(params))
      // dispatch(industriesCounts(params))
      // dispatch(itSkillsCounts(params))
      // dispatch(jobSkillsCounts(params))
      // dispatch(pipelineCount(params))
      // dispatch(countryCounts(params))
      // dispatch(desiredSalaryCount(params))
      // dispatch(desiredLocationCount(params))
      // dispatch(desiredBenefitsCount(params))
      // dispatch(workingLocationgCount(params))
      // dispatch(relocationAbroadCount(params))
      // dispatch(jobCityCount(params))
      // dispatch(jobCountryCount(params))
      // dispatch(jobLocalityCount(params))
      // dispatch(jobProjectCount(params))
      // dispatch(jobLanguage1Count(params))
      // dispatch(jobLanguage2Count(params))
    }
  }, [filter, removeFilter]);

  const removeTag = async (e) => {
    const type = e.target.dataset.type;
    delete filter[type];

    switch (type) {
      case "category":
        setIsCategoryFilter(false);
        setCategoryFilterText("");
        delete candidateFilter[type];
        delete filter[type];
        setCandidateFilter(candidateFilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      case "flag_type":
        setIsFlagFilter(false);
        setFlagFilterText("");
        delete candidateFilter[type];
        delete filter[type];
        setCandidateFilter(candidateFilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      case "gender":
        setIsGenderFilter(false);
        setGenderFilterText("");
        delete candidateFilter[type];
        delete filter[type];
        setCandidateFilter(candidateFilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      case "profileIndicator":
        setIsProfileFilter(false);
        setProfileFilterText("");
        delete candidateFilter[type];
        delete filter[type];
        setCandidateFilter(candidateFilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      case "EuNoEu":
        setIsEuNonEuFilter(false);
        setEuNonEuFilterText("");
        break;

      case "rec_id":
        setIsRecruiterFilter(false);
        setRecruiterFilterText("");
        delete candidateFilter[type];
        delete filter[type];
        setCandidateFilter(candidateFilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      case "am_id":
        setIsAccountMangerFilter(false);
        setAccountMangerFilterText("");
        delete candidateFilter[type];
        delete filter[type];
        setCandidateFilter(candidateFilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      case "rec_admin":
        setIsRecAdminFilter(false);
        setRecAdminFilterText("");
        delete candidateFilter[type];
        delete filter[type];
        setCandidateFilter(candidateFilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      case "education":
        setIsEducationFilter(false);
        setEducationFilterText("");
        delete candidateFilter[type];
        delete filter[type];
        setCandidateFilter(candidateFilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      case "caseStatus":
        setIsCaseStatusFilter(false);
        setCaseStatusFilterText("");
        delete candidateFilter[type];
        delete filter[type];
        setCandidateFilter(candidateFilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      case "case_progress":
        setIsCaseProgressFilter(false);
        setCaseProgressFilterText("");
        delete candidateFilter[type];
        delete filter[type];
        setCandidateFilter(candidateFilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      case "case_stage":
        setIsCaseStageFilter(false);
        setCaseStageFilterText("");
        delete candidateFilter[type];
        delete filter[type];
        setCandidateFilter(candidateFilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      case "cv_source":
        setIsCvsourceFilter(false);
        setCvsourceFilterText("");
        delete candidateFilter[type];
        delete filter[type];
        setCandidateFilter(candidateFilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      case "nationality":
        setIsNationalityFilter(false);
        setNationalityFilterText("");
        delete candidateFilter[type];
        delete filter[type];
        setCandidateFilter(candidateFilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      case "company":
        setIsJobCompanyFilter(false);
        setJobCompanyFilterText("");
        delete candidateFilter[type];
        delete filter[type];
        setCompanyfilter(candidateFilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      case "lang":
        setIsJobLanguageFilter(false);
        setJobLanguageFilterText("");
        delete candidateFilter[type];
        delete filter[type];
        setCandidateFilter(candidateFilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      case "joblang":
        setIsJobLang1Filter(false);
        setJobLang1FilterText("");
        delete candidateFilter["lang"];
        delete filter["lang"];
        setCompanyfilter(candidateFilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;
      case "joblang2":
        setIsJobLang2Filter(false);
        setJobLang2FilterText("");
        delete candidateFilter["lang"];
        delete filter["lang"];
        setCompanyfilter(candidateFilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      case "project":
        setIsJobProjectFilter(false);
        setJobProjectFilterText("");
        delete candidateFilter[type];
        delete filter[type];
        setCompanyfilter(candidateFilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      case "role":
        setIsJobRoleFilter(false);
        setJobRoleFilterText("");
        delete candidateFilter[type];
        delete filter[type];
        setCompanyfilter(candidateFilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      case "nativeLanguage":
        setIsNativeLanguageFilter(false);
        setNativeLanguageFilterText("");
        delete candidateFilter[type];
        delete filter[type];
        setCandidateFilter(candidateFilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      case "fluentLanguage":
        setIsFluentLanguageFilter(false);
        setFluentLanguageFilterText("");
        delete candidateFilter[type];
        delete filter[type];
        setCandidateFilter(candidateFilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      case "intermediate":
        setIsIntermediateLanguageFilter(false);
        setIntermediateLanguageFilterText("");
        delete candidateFilter[type];
        delete filter[type];
        setCandidateFilter(candidateFilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;
      case "c_city":
        setIsCityFilter(false);
        setCityFilterText("");
        delete candidateFilter[type];
        delete filter[type];
        setCandidateFilter(candidateFilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;
      case "c_country":
        setIsCountryFilter(false);
        setCountryFilterText("");
        delete candidateFilter[type];
        delete filter[type];
        setCandidateFilter(candidateFilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;
      case "visaStatus":
        setIsVisaFilter(false);
        setVisaFilterText("");
        delete candidateFilter[type];
        delete filter[type];
        setCandidateFilter(candidateFilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;
      case "career_level":
        setIsCareerFilter(false);
        setCareerFilterText("");
        delete candidateFilter[type];
        delete filter[type];
        setCandidateFilter(candidateFilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      case "priority":
        setIsDomainFilter(false);
        setDomainFilterText("");
        delete candidateFilter[type];
        delete filter[type];
        setCandidateFilter(candidateFilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      case "industries":
        setIsIndustriesFilter(false);
        setIndustriesFilterText("");
        delete candidateFilter[type];
        delete filter[type];
        setCandidateFilter(candidateFilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      case "technical_skills":
        setIsITSkillsFilter(false);
        setITSkillsFilterText("");
        delete candidateFilter[type];
        delete filter[type];
        setCandidateFilter(candidateFilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      case "name":
        setIsJobTitleFilter(false);
        setJobTitleFilterText("");
        delete candidateFilter[type];
        delete filter[type];
        setCandidateFilter(candidateFilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      case "status":
        setIsPipelineFilter(false);
        setCaseStatusFilterText("");
        delete candidateFilter[type];
        delete filter[type];
        setCandidateFilter(candidateFilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      case "location":
        setIsJobCityFilter(false);
        setJobCityFilterText("");
        delete setCompanyfilter[type];
        delete filter[type];
        setCompanyfilter({ companyfilter });
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      case "project":
        setIsProjectFilter(false);
        setProjectFilterText("");
        delete setCompanyfilter[type];
        delete filter[type];
        setCompanyfilter({ companyfilter });
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      case "job_country":
        setIsJobCountryFilter(false);
        setJobCountryFilterText("");
        delete setCompanyfilter[type];
        delete filter[type];
        setCompanyfilter({ companyfilter });
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      case "locality":
        setIsLocalityFilter(false);
        setLocalityFilterText("");
        delete setCompanyfilter[type];
        delete filter[type];
        setCompanyfilter({ companyfilter });
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;
      case "desired_salary":
        setIsDSalaryFilter(false);
        setDSalaryFilterText("");
        delete candidateFilter[type];
        delete filter[type];
        setCandidateFilter(candidateFilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;
      case "desired_location":
        setIsDLocationFilter(false);
        setDLocationFilterText("");
        delete candidateFilter[type];
        delete filter[type];
        setCandidateFilter(candidateFilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;
      case "desired_benefits":
        setIsDBenefitsFilter(false);
        setDBenefitsFilterText("");
        delete candidateFilter[type];
        delete filter[type];
        setCandidateFilter(candidateFilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;
      case "working_location":
        setIsWorkingLocationFilter(false);
        setWorkingLocationFilterText("");
        delete candidateFilter[type];
        delete filter[type];
        setCandidateFilter(candidateFilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;
      case "relocation_abroad":
        setIsRelocationAbroadFilter(false);
        setRelocationAbroadFilterText("");
        delete candidateFilter[type];
        delete filter[type];
        setCandidateFilter(candidateFilter);
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        break;

      default:
        break;
    }
    //     setFilter(filter)
    // setTagLength(tagLength - 1)
    // setFilterTags(filterTags.filter((item) => item.type !== type))
  };

  const selectFilter = (e) => {
    const type = e.target.dataset.type;
    const value = e.target.dataset.value;
    let i;
    switch (type) {
      case "status":
        setIsPipelineFilter(true);
        setPipelineFilterText(e.target.dataset.value);
        setCandidateFilter({ ...candidateFilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "category":
        setIsCategoryFilter(true);
        setCategoryFilterText(e.target.dataset.value);
        setCandidateFilter({ ...candidateFilter, [type]: value });
        setFilter({ ...filter, [type]: e.target.dataset.value });
        break;

      case "flag_type":
        setIsFlagFilter(true);
        setFlagFilterText(e.target.dataset.text);
        setCandidateFilter({ ...candidateFilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "gender":
        setIsGenderFilter(true);
        setGenderFilterText(value);
        setCandidateFilter({ ...candidateFilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "profileIndicator":
        setIsProfileFilter(true);
        setProfileFilterText(value);
        setCandidateFilter({ ...candidateFilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "EuNoEu":
        setIsEuNonEuFilter(true);
        setEuNonEuFilterText(value);
        break;

      case "rec_id":
        const text = e.target.dataset.text;
        setIsRecruiterFilter(true);
        setRecruiterFilterText(text);
        setCandidateFilter({ ...candidateFilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "am_id":
        // const accountMtext = e.target.dataset.text;
        setIsAccountMangerFilter(true);
        setAccountMangerFilterText(e.target.dataset.text);
        setCandidateFilter({ ...candidateFilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;
      case "rec_admin":
        const recAdmintext = e.target.dataset.text;
        setIsRecAdminFilter(true);
        setRecAdminFilterText(recAdmintext);
        setCandidateFilter({ ...candidateFilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "education":
        setIsEducationFilter(true);
        setEducationFilterText(e.target.dataset.value);
        setCandidateFilter({ ...candidateFilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "caseStatus":
        i = filterTags.findIndex(function (x) {
          return x.type === "caseStatus";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setIsCaseStatusFilter(true);
        setCaseStatusFilterText(value);
        setCandidateFilter({ ...candidateFilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "case_progress":
        i = filterTags.findIndex(function (x) {
          return x.type === "case_progress";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setIsCaseProgressFilter(true);
        setCaseProgressFilterText(value);
        setCandidateFilter({ ...candidateFilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "case_stage":
        i = filterTags.findIndex(function (x) {
          return x.type === "case_stage";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setIsCaseStageFilter(true);
        setCaseStageFilterText(value);
        setCandidateFilter({ ...candidateFilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "cv_source":
        setIsCvsourceFilter(true);
        setCvsourceFilterText(value);
        setCandidateFilter({ ...candidateFilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "profileStatus":
        setIsProfileStatusFilter(true);
        setProfileStatusFilterText(e.target.dataset.text);
        setCandidateFilter({ ...candidateFilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "nationality":
        setIsNationalityFilter(true);
        setNationalityFilterText(value);
        setCandidateFilter({ ...candidateFilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "company":
        setIsJobCompanyFilter(true);
        setJobCompanyFilterText(value);
        i = filterTags.findIndex(function (x) {
          return x.type === type;
        });
        if (i === -1) {
          filterTags.push({ type, value });
        }
        setCompanyfilter({ ...companyfilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "lang":
        setIsJobLanguageFilter(true);
        setJobLanguageFilterText(value);
        i = filterTags.findIndex(function (x) {
          return x.type === type;
        });
        if (i === -1) {
          filterTags.push({ type, value });
        }
        setCompanyfilter({ ...companyfilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;
      case "joblang":
        setIsJobLang1Filter(true);
        setJobLang1FilterText(value);
        i = filterTags.findIndex(function (x) {
          return x.type === type;
        });
        if (i === -1) {
          filterTags.push({ type, value });
        }
        setCompanyfilter({ ...companyfilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;
      case "joblang2":
        setIsJobLang2Filter(true);
        setJobLang2FilterText(value);
        i = filterTags.findIndex(function (x) {
          return x.type === type;
        });
        if (i === -1) {
          filterTags.push({ type, value });
        }
        setCompanyfilter({ ...companyfilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "project":
        setIsJobProjectFilter(true);
        setJobProjectFilterText(value);
        i = filterTags.findIndex(function (x) {
          return x.type === type;
        });
        if (i === -1) {
          filterTags.push({ type, value });
        }
        setCompanyfilter({ ...companyfilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "role":
        setIsJobRoleFilter(true);
        setJobRoleFilterText(value);
        i = filterTags.findIndex(function (x) {
          return x.type === type;
        });
        if (i === -1) {
          filterTags.push({ type, value });
        }
        setCompanyfilter({ ...companyfilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "native1":
        setIsNativeLanguageFilter(true);
        setNativeLanguageFilterText(value);
        setCandidateFilter({ ...candidateFilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "fluent1":
        setIsFluentLanguageFilter(true);
        setFluentLanguageFilterText(value);
        setCandidateFilter({ ...candidateFilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "intermediate1":
        setIsIntermediateLanguageFilter(true);
        setIntermediateLanguageFilterText(value);
        setCandidateFilter({ ...candidateFilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "c_city":
        setIsCityFilter(true);
        setCityFilterText(value);
        setCandidateFilter({ ...candidateFilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "c_country":
        setIsCountryFilter(true);
        setCountryFilterText(value);
        setCandidateFilter({ ...candidateFilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "visaStatus":
        setIsVisaFilter(true);
        setVisaFilterText(value);
        setCandidateFilter({ ...candidateFilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "career_level":
        setIsCareerFilter(true);
        setCareerFilterText(value);
        setCandidateFilter({ ...candidateFilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "priority":
        setIsDomainFilter(true);
        setDomainFilterText(value);
        setCandidateFilter({ ...candidateFilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "industries":
        setIsIndustriesFilter(true);
        setIndustriesFilterText(value);
        setCandidateFilter({ ...candidateFilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "technical_skills":
        setIsITSkillsFilter(true);
        setITSkillsFilterText(value);
        setCandidateFilter({ ...candidateFilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "name":
        setIsJobTitleFilter(true);
        setJobTitleFilterText(value);
        setCandidateFilter({ ...candidateFilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "location":
        setIsJobCityFilter(true);
        setJobCityFilterText(value);
        setCompanyfilter({ ...companyfilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "job_country":
        setIsJobCountryFilter(true);
        setJobCountryFilterText(value);
        setCompanyfilter({ ...companyfilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;
      case "project":
        setIsProjectFilter(true);
        setProjectFilterText(value);
        setCompanyfilter({ ...companyfilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "locality":
        setIsLocalityFilter(false);
        setLocalityFilterText("");
        setCompanyfilter({ ...companyfilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;
      case "desired_location":
        setIsDLocationFilter(true);
        setDLocationFilterText(value);
        setCandidateFilter({ ...candidateFilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "desired_benefits":
        setIsDBenefitsFilter(true);
        setDBenefitsFilterText(value);
        setCandidateFilter({ ...candidateFilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "desired_salary":
        setIsDSalaryFilter(true);
        setDSalaryFilterText(value);
        setCandidateFilter({ ...candidateFilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "working_location":
        setIsWorkingLocationFilter(true);
        setWorkingLocationFilterText(value);
        setCandidateFilter({ ...candidateFilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;

      case "relocation_abroad":
        setIsRelocationAbroadFilter(true);
        setRelocationAbroadFilterText(value);
        setCandidateFilter({ ...candidateFilter, [type]: value });
        setFilter({ ...filter, [type]: value });
        break;
      default:
        break;
    }
  };

  const [isFilterModal, setFilterModalShow] = useState(false);

  /* const filterDesiredInfoTab = (e) => {

        //  setFilterModalShow(true);
        const type = e.target.dataset.type;
        const value = e.target.dataset.value;
       
        let i;
        switch (type) {
            case 'desired_location':
                setIsDLocationFilter(true)
                setDLocationFilterText(value)
                setCandidateFilter({ ...candidateFilter, [type]: value })
                setFilter({ ...filter, [type]: value })
                break;

            case 'desired_benefits':
                setIsDBenefitsFilter(true)
                setDBenefitsFilterText(value)
                setCandidateFilter({ ...candidateFilter, [type]: value })
                setFilter({ ...filter, [type]: value })
                break;

            case 'desired_salary':
                setIsDSalaryFilter(true)
                setDSalaryFilterText(value)
                setCandidateFilter({ ...candidateFilter, [type]: value })
                setFilter({ ...filter, [type]: value })
                break;

            case 'working_location':
                setIsWorkingLocationFilter(true)
                setWorkingLocationFilterText(value)
                setCandidateFilter({ ...candidateFilter, [type]: value })
                setFilter({ ...filter, [type]: value })
                break;

            case 'relocation_abroad':
                setIsRelocationAbroadFilter(true)
                setRelocationAbroadFilterText(value)
                setCandidateFilter({ ...candidateFilter, [type]: value })
                setFilter({ ...filter, [type]: value })
                break;

            default:
                break;
        }
    }

    const removeTagDesiredInfo = async (e) => {
        const type = e.target.dataset.type
        delete filter[type];

        switch (type) {

            case 'desired_salary':
                setIsDSalaryFilter(false)
                setDSalaryFilterText('')
                delete candidateFilter[type]
                delete filter[type]
                setCandidateFilter(candidateFilter)
                setFilter(filter)
                setRemoveFilter(removeFilter + 1)
                break;
            case 'desired_location':
                setIsDLocationFilter(false)
                setDLocationFilterText('')
                delete candidateFilter[type]
                delete filter[type]
                setCandidateFilter(candidateFilter)
                setFilter(filter)
                setRemoveFilter(removeFilter + 1)
                break;
            case 'desired_benefits':
                setIsDBenefitsFilter(false)
                setDBenefitsFilterText('')
                delete candidateFilter[type]
                delete filter[type]
                setCandidateFilter(candidateFilter)
                setFilter(filter)
                setRemoveFilter(removeFilter + 1)
                break;
            case 'working_location':
                setIsWorkingLocationFilter(false)
                setWorkingLocationFilterText('')
                delete candidateFilter[type]
                delete filter[type]
                setCandidateFilter(candidateFilter)
                setFilter(filter)
                setRemoveFilter(removeFilter + 1)
                break;
            case 'relocation_abroad':
                setIsRelocationAbroadFilter(false)
                setRelocationAbroadFilterText('')
                delete candidateFilter[type]
                delete filter[type]
                setCandidateFilter(candidateFilter)
                setFilter(filter)
                setRemoveFilter(removeFilter + 1)
                break;

            default:
                break;
        }
        //     setFilter(filter)       
        // setTagLength(tagLength - 1)
        // setFilterTags(filterTags.filter((item) => item.type !== type))

    }

*/

  return (
    <>
      {isFilterModal ? (
        <SearchFilterModal handleClose={hideFilterModal} />
      ) : null}
      {isCreateNew ? (
        <CreateNewModal
          hideModal={hidecreatenewModal}
          showAddpopup={showAddpopup}
          canIdList={canIdList}
          allCandidate={canIdList.length}
        />
      ) : null}
      <div className="dk-dbsearchcv-summMain container-fluid">
        <div className="dk-db-searchcv-pageHeader d-none">
          <div className="d-flex align-items-center justify-content-between">
            <div className="dk-filterMainLeft d-flex">
              <div className="input-group  dk-db-srchinput-grp mr-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Keywords"
                  aria-label="Search Keywords"
                />
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    <i className="fas fa-search"></i>
                  </span>
                </div>
              </div>
              <div className="dk-db-duplicate-btnCon">
                <DuplicateCheck />
              </div>
            </div>

            {/* <div className="dk-dBSearch-SwitchTabs">
                            <Link to="#" className="active">Profile</Link>
                            <Link to="#">Job Info</Link>
                        </div> */}

            <div className="dk-ctretabCon">
              {/* <li className="searchTagCategory">Ana Maria Buescu
                                        <Link to="#" className="dk-close"><i className="fas fa-times"></i></Link>
                                    </li> */}
            </div>
            <div className="dk-filterMainRight">
              {/* <button onClick={showcreatenewModal} className="d-none"><i className="fas fa-search"></i> Create Search List</button> */}
              <button onClick={showcreatenewModal}>
                <i className="fas fa-search"></i> Create Search List
              </button>
            </div>
          </div>
        </div>
        <div className="row dkg-dbsummbox-mainCon">
          <div className="col-md-12">
            <div className="d-flex dkg-db-motop-boxCon">
              <div className="dk-summaryCols xl dk-listing">
                <div
                  className={`${
                    !isRecAdminFilter ? "dk-toptitle" : "dk-toptitle-filterBg"
                  }`}
                >
                  {!isRecAdminFilter ? (
                    <div className="dk-colstitle">YEARS</div>
                  ) : (
                    <div className="dk-dbsuMM-filterHeader">
                      {recAdminFilterText}
                      <span>
                        <i
                          className="fa fa-times-circle  ml-2"
                          data-type="rec_admin"
                          onClick={removeTag}
                        ></i>
                      </span>
                    </div>
                  )}
                  <span className="dk-totalCvCount">
                    {/* {recAdminList.reduce(
                      (total, currentValue) =>
                        (total = total + currentValue.count),
                      0
                    )} */}
                  </span>
                </div>
                <div className="dk-summaryList">
                  <div className="dk-listScrollX">
                    {/* <li>
                      2022
                      <span>45677</span>
                    </li>
                    <li>
                      2021
                      <span>5677</span>
                    </li>
                    <li>
                      2020
                      <span>677</span>
                    </li>
                    <li>
                      2019
                      <span>8677</span>
                    </li>
                    <li>
                      2018
                      <span>8677</span>
                    </li>
                    <li>
                      2017
                      <span>3077</span>
                    </li>
                    <li>
                      2016
                      <span>5677</span>
                    </li> */}
                  </div>
                </div>
              </div>
              <div className="dk-summaryCols xl dk-listing">
                <div
                  className={`${
                    !isRecAdminFilter ? "dk-toptitle" : "dk-toptitle-filterBg"
                  }`}
                >
                  {!isRecAdminFilter ? (
                    <div className="dk-colstitle">MONTHS</div>
                  ) : (
                    <div className="dk-dbsuMM-filterHeader">
                      {recAdminFilterText}
                      <span>
                        <i
                          className="fa fa-times-circle  ml-2"
                          data-type="rec_admin"
                          onClick={removeTag}
                        ></i>
                      </span>
                    </div>
                  )}
                  <span className="dk-totalCvCount">233</span>
                </div>
                <div className="dk-summaryList">
                  <div className="dk-listScrollX">
                    {/* <li>
                      January
                      <span>124</span>
                    </li>
                    <li>
                      February
                      <span>89</span>
                    </li>
                    <li>
                      March
                      <span>103</span>
                    </li>
                    <li>
                      April
                      <span>94</span>
                    </li>
                    <li>
                      May
                      <span>59</span>
                    </li>
                    <li>
                      June
                      <span>64</span>
                    </li>
                    <li>
                      July
                      <span>139</span>
                    </li>
                    <li>
                      August
                      <span>119</span>
                    </li>
                    <li>
                      September
                      <span>62</span>
                    </li>
                    <li>
                      October
                      <span>52</span>
                    </li>
                    <li>
                      November
                      <span>40</span>
                    </li>
                    <li>
                      December
                      <span>25</span>
                    </li> */}
                  </div>
                </div>
              </div>
              <div className="dk-summaryCols xl dk-listing">
                <div
                  className={`${
                    !isCategoryFilter ? "dk-toptitle" : "dk-toptitle-filterBg"
                  }`}
                >
                  {!isCategoryFilter ? (
                    <div className="dk-colstitle">CATEGORY</div>
                  ) : (
                    <div className="dk-dbsuMM-filterHeader">
                      {categoryFilterText}
                      <span>
                        <i
                          className="fa fa-times-circle  ml-2"
                          data-type="category"
                          onClick={removeTag}
                        ></i>
                      </span>
                    </div>
                  )}
                  <span className="dk-totalCvCount">
                    {/* {categoryList.reduce(
                      (total, currentValue) =>
                        (total = total + currentValue.count),
                      0
                    )} */}
                  </span>
                </div>
                {/* <li data-type="category" data-value="ML" onClick={selectFilter}>
                  <div
                    data-type="category"
                    data-value="ML"
                    onClick={selectFilter}
                  >
                    <i
                      className="fas mr-2 fa-language"
                      aria-hidden="true"
                      data-type="category"
                      data-value="ML"
                      onClick={selectFilter}
                    ></i>
                    ML
                  </div>
                  <span
                    className="mlCount"
                    data-type="category"
                    data-value="ML"
                    onClick={selectFilter}
                  >
                    {categoryList
                      .filter((items) => items.category === "ML")
                      .map((item, index) => (
                        <span
                          className="recNoCount"
                          data-type="category"
                          data-value="ML"
                          onClick={selectFilter}
                        >
                          {item.count}
                        </span>
                      ))}
                  </span>
                </li>
                <li data-type="category" data-value="IT" onClick={selectFilter}>
                  <div
                    data-type="category"
                    data-value="IT"
                    onClick={selectFilter}
                  >
                    <i
                      className="fas mr-2 fa-tv"
                      aria-hidden="true"
                      data-type="category"
                      data-value="IT"
                      onClick={selectFilter}
                    ></i>
                    IT
                  </div>
                  <span
                    className="mlCount"
                    data-type="category"
                    data-value="IT"
                    onClick={selectFilter}
                  >
                    {categoryList
                      .filter((items) => items.category === "IT")
                      .map((item, index) => (
                        <span
                          className="recNoCount"
                          data-type="category"
                          data-value="IT"
                          onClick={selectFilter}
                        >
                          {item.count}
                        </span>
                      ))}
                  </span>
                </li>
                <li
                  data-type="category"
                  data-value="None"
                  onClick={selectFilter}
                >
                  <div
                    data-type="category"
                    data-value="None"
                    onClick={selectFilter}
                  >
                    <i
                      className="far mr-2 fa-circle"
                      aria-hidden="true"
                      data-type="category"
                      data-value="None"
                      onClick={selectFilter}
                    ></i>
                    Blank
                  </div>
                  <span
                    className="mlCount"
                    data-type="category"
                    data-value="None"
                    onClick={selectFilter}
                  >
                    {categoryList
                      .filter((items) => items.category === "None")
                      .map((item, index) => (
                        <span
                          className="recNoCount"
                          data-type="category"
                          data-value="None"
                          onClick={selectFilter}
                        >
                          {item.count}
                        </span>
                      ))}
                  </span>
                </li> */}
              </div>
              <div className="dk-summaryCols xl dk-listing">
                <div
                  className={`${
                    !isCvsourceFilter ? "dk-toptitle" : "dk-toptitle-filterBg"
                  }`}
                >
                  {!isCvsourceFilter ? (
                    <div className="dk-colstitle">RR</div>
                  ) : (
                    <div className="dk-dbsuMM-filterHeader">
                      {cvsourceFilterText}
                      <span>
                        <i
                          className="fa fa-times-circle  ml-2"
                          data-type="cv_source"
                          onClick={removeTag}
                        ></i>
                      </span>
                    </div>
                  )}
                  <span className="dk-totalCvCount">
                    {cvSourceList.reduce(
                      (total, currentValue) =>
                        (total = total + currentValue.count),
                      0
                    )}
                  </span>
                </div>
                <div className="dk-summaryList">
                  <div className="dk-listScrollX">
                    {cvSourceList.map((item, index) => (
                      <li
                        key={`cvsource${index}`}
                        data-type="cv_source"
                        data-value={item.name}
                        onClick={selectFilter}
                      >
                        {item.name !== "" ? item.name : "Blank"}
                        <span
                          data-type="cv_source"
                          data-value={item.name}
                          onClick={selectFilter}
                        >
                          {item.count}
                        </span>
                      </li>
                    ))}
                  </div>
                </div>
              </div>
              <div className="dk-summaryCols xl dk-listing">
                <div
                  className={`${
                    !isRecruiterFilter ? "dk-toptitle" : "dk-toptitle-filterBg"
                  }`}
                >
                  {!isRecruiterFilter ? (
                    <div className="dk-colstitle">RC</div>
                  ) : (
                    <div className="dk-dbsuMM-filterHeader">
                      {recruiterFilterText}
                      <span>
                        <i
                          className="fa fa-times-circle  ml-2"
                          data-type="rec_id"
                          onClick={removeTag}
                        ></i>
                      </span>
                    </div>
                  )}
                  <span className="dk-totalCvCount">
                    {recruitersList.reduce(
                      (total, currentValue) =>
                        (total = total + currentValue.count),
                      0
                    )}
                  </span>
                </div>
                <ul>
                  <div className="dk-listScrollX">
                    {recruitersList.map((data, index) => (
                      <li
                        key={index + 1}
                        data-type="rec_id"
                        data-text={
                          data.recInfo.firstName + " " + data.recInfo.lastName
                        }
                        data-value={data.recInfo.id}
                        onClick={selectFilter}
                      >
                        <div
                          data-type="rec_id"
                          data-text={
                            data.recInfo.firstName + " " + data.recInfo.lastName
                          }
                          data-value={data.recInfo.id}
                          onClick={selectFilter}
                        >
                          <img
                            src={
                              process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                              data.recInfo.profileImage
                            }
                            className="summary-rec-img"
                            data-type="rec_id"
                            data-text={
                              data.recInfo.firstName +
                              " " +
                              data.recInfo.lastName
                            }
                            data-value={data.recInfo.id}
                            onClick={selectFilter}
                          />
                          {data.recInfo.firstName + " " + data.recInfo.lastName}
                        </div>
                        <span
                          data-type="rec_id"
                          data-text={
                            data.recInfo.firstName + " " + data.recInfo.lastName
                          }
                          data-value={data.recInfo.id}
                          onClick={selectFilter}
                        >
                          {data.count}
                        </span>
                      </li>
                    ))}
                  </div>
                </ul>
              </div>
              <div className="dk-summaryCols xl dk-listing">
                <div
                  className={`${
                    !isAccountMangerFilter
                      ? "dk-toptitle"
                      : "dk-toptitle-filterBg"
                  }`}
                >
                  {!isAccountMangerFilter ? (
                    <div className="dk-colstitle">AM</div>
                  ) : (
                    <div className="dk-dbsuMM-filterHeader">
                      {accountMangerFilterText}
                      <span>
                        <i
                          className="fa fa-times-circle  ml-2"
                          data-type="am_id"
                          onClick={removeTag}
                        ></i>
                      </span>
                    </div>
                  )}
                  <span className="dk-totalCvCount">
                    {accountManagerList.reduce(
                      (total, currentValue) =>
                        (total = total + currentValue.count),
                      0
                    )}
                  </span>
                </div>
                <ul>
                  <div className="dk-listScrollX">
                    {accountManagerList.map((data, index) => (
                      <li
                        key={index + 1}
                        data-type="am_id"
                        data-text={
                          data.recInfo.firstName + " " + data.recInfo.lastName
                        }
                        data-value={data.recInfo.id}
                        onClick={selectFilter}
                      >
                        <div
                          data-type="am_id"
                          data-text={
                            data.recInfo.firstName + " " + data.recInfo.lastName
                          }
                          data-value={data.recInfo.id}
                          onClick={selectFilter}
                        >
                          <img
                            src={
                              process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                              data.recInfo.profileImage
                            }
                            className="summary-rec-img"
                            data-type="am_id"
                            data-text={
                              data.recInfo.firstName +
                              " " +
                              data.recInfo.lastName
                            }
                            data-value={data.recInfo.id}
                            onClick={selectFilter}
                          />
                          {data.recInfo.firstName + " " + data.recInfo.lastName}
                        </div>
                        <span
                          data-type="am_id"
                          data-text={
                            data.recInfo.firstName + " " + data.recInfo.lastName
                          }
                          data-value={data.recInfo.id}
                          onClick={selectFilter}
                        >
                          {data.count}
                        </span>
                      </li>
                    ))}
                  </div>
                </ul>
              </div>
              {/* <div className="dk-summaryCols">

                                            <div className={`${(!isPipelineFilter) ? 'dk-toptitle' : 'dk-toptitle-filterBg'}`}>
                                                {
                                                    (!isPipelineFilter) ?
                                                        <div className="dk-colstitle">PIPELINES</div>
                                                        :
                                                        <div className='dk-dbsuMM-filterHeader'>
                                                            {pipelineFilterText}
                                                            <span><i className='fa fa-times-circle  ml-2' data-type="status" onClick={removeTag}></i></span>
                                                        </div>
                                                }
                                                <span className="dk-totalCvCount">
                                                    {pipelineList.reduce((total, currentValue) => total = total + currentValue.count, 0)}
                                                </span>
                                            </div>
                                            <ul className="dk-bottomLeftLists dk-case-status-bgColor23 dk-pipelineCols-scroll">
                                                {
                                                    (pipelineList.filter(function (val) {
                                                        return val.status === 'New CV';
                                                    }).length !== 0) ?
                                                        <li style={{ background: '#306f88', color: '#fff' }} data-type="status" data-value="New CV" onClick={selectFilter}>
                                                            <span className="recuriterItem d-flex align-items-center" style={{ color: '#fff' }} data-type="status" data-value="New CV" onClick={selectFilter}>
                                                                New Case
                                                            </span>
                                                            <span className="caseStatusCounter" data-type="status" data-value="New Case" style={{ color: '#fff' }} onClick={selectFilter}>
                                                                {
                                                                    pipelineList.length > 0 && pipelineList.map((data, index) => {
                                                                        if (data.status === "New CV") {
                                                                            return data.count;
                                                                        }
                                                                    })
                                                                }
                                                            </span>
                                                        </li> : null
                                                }

                                                {
                                                    (pipelineList.filter(function (val) {
                                                        return val.status === 'Selected';
                                                    }).length !== 0) ?
                                                        <li style={{ background: '#26596d', color: '#fff' }} data-type="status" data-value="Selected" onClick={selectFilter}>
                                                            <span className="recuriterItem d-flex align-items-center" style={{ color: '#fff' }} data-type="status" data-value="Selected" onClick={selectFilter}>
                                                                Selected
                                                            </span>
                                                            <span className="caseStatusCounter" data-type="status" data-value="Selected" style={{ color: '#fff' }} onClick={selectFilter}>
                                                                {
                                                                    pipelineList.length > 0 && pipelineList.map((data, index) => {
                                                                        if (data.status === "Selected") {
                                                                            return data.count;
                                                                        }
                                                                    })
                                                                }
                                                            </span>
                                                        </li> : null
                                                }
                                                {
                                                    (pipelineList.filter(function (val) {
                                                        return val.status === 'Today Work';
                                                    }).length !== 0) ? <li style={{ backgroundColor: "#715f8f", color: "#fff" }} data-type="status" data-value="Today Work" onClick={selectFilter}>
                                                        <span className="recuriterItem d-flex align-items-center" data-type="status" data-value="Today Work" onClick={selectFilter} style={{ color: '#fff' }}>
                                                            Today Work
                                                        </span>
                                                        <span className="caseStatusCounter" data-type="status" data-value="Today Work" style={{ color: '#fff' }} onClick={selectFilter}>
                                                            {
                                                                pipelineList.length > 0 && pipelineList.map((data, index) => {
                                                                    if (data.status === "Today Work") {
                                                                        return data.count;
                                                                    }
                                                                })
                                                            }
                                                        </span>
                                                    </li> : null
                                                }
                                                {
                                                    (pipelineList.filter(function (val) {
                                                        return val.status === 'Processing';
                                                    }).length !== 0) ? <li style={{ backgroundColor: "#57496d", color: "#fff" }} data-type="status" data-value="Processing" onClick={selectFilter}>
                                                        <span className="recuriterItem d-flex align-items-center" style={{ color: '#fff' }} data-type="status" data-value="Processing" onClick={selectFilter}>
                                                            Processing
                                                        </span>
                                                        <span className="caseStatusCounter" data-type="status" style={{ color: '#fff' }} data-value="Processing" onClick={selectFilter}>
                                                            {
                                                                pipelineList.length > 0 && pipelineList.map((data, index) => {
                                                                    if (data.status === "Processing") {
                                                                        return data.count;
                                                                    }
                                                                })
                                                            }
                                                        </span>
                                                    </li> : null
                                                }
                                                {
                                                    (pipelineList.filter(function (val) {
                                                        return val.status === 'Not Reachable';
                                                    }).length !== 0) ? <li style={{ backgroundColor: "#c75d85", color: "#fff" }} data-type="status" data-value="Not Reachable" onClick={selectFilter}>
                                                        <span className="recuriterItem d-flex align-items-center" style={{ color: '#fff' }} data-type="status" data-value="Not Reachable" onClick={selectFilter}>
                                                            Not Reachable
                                                        </span>
                                                        <span className="caseStatusCounter" data-type="status" style={{ color: '#fff' }} data-value="Not Reachable" onClick={selectFilter}>
                                                            {
                                                                pipelineList.length > 0 && pipelineList.map((data, index) => {
                                                                    if (data.status === "Not Reachable") {
                                                                        return data.count;
                                                                    }
                                                                })
                                                            }
                                                        </span>
                                                    </li> : null
                                                }
                                                {
                                                    (pipelineList.filter(function (val) {
                                                        return val.status === 'Queries';
                                                    }).length !== 0) ? <li style={{ backgroundColor: "#42908c", color: "#fff" }} data-type="status" data-value="Queries" onClick={selectFilter}>
                                                        <span className="recuriterItem d-flex align-items-center" style={{ color: '#fff' }} data-type="status" data-value="Queries" onClick={selectFilter}>
                                                            Queries
                                                        </span>
                                                        <span className="caseStatusCounter" data-type="status" style={{ color: '#fff' }} data-value="Queries" onClick={selectFilter}>
                                                            {
                                                                pipelineList.length > 0 && pipelineList.map((data, index) => {
                                                                    if (data.status === "Queries") {
                                                                        return data.count;
                                                                    }
                                                                })
                                                            }
                                                        </span>
                                                    </li> : null
                                                }
                                                {
                                                    (pipelineList.filter(function (val) {
                                                        return val.status === 'Sendout';
                                                    }).length !== 0) ? <li style={{ backgroundColor: "#316a67", color: "#fff" }} data-type="status" data-value="Sendout" onClick={selectFilter}>
                                                        <span className="recuriterItem d-flex align-items-center" style={{ color: '#fff' }} data-type="status" data-value="Sendout" onClick={selectFilter}>
                                                            Sendout
                                                        </span>
                                                        <span className="caseStatusCounter" data-type="status" style={{ color: '#fff' }} data-value="Sendout" onClick={selectFilter}>
                                                            {
                                                                pipelineList.length > 0 && pipelineList.map((data, index) => {
                                                                    if (data.status === "Sendout") {
                                                                        return data.count;
                                                                    }
                                                                })
                                                            }
                                                        </span>
                                                    </li> : null
                                                }
                                                {
                                                    (pipelineList.filter(function (val) {
                                                        return val.status === 'Job Offer';
                                                    }).length !== 0) ? <li style={{ backgroundColor: "#629bd3", color: "#fff" }} data-type="status" data-value="Job Offer" onClick={selectFilter}>
                                                        <span className="recuriterItem d-flex align-items-center" style={{ color: '#fff' }} data-type="status" data-value="Job Offer" onClick={selectFilter}>
                                                            Job Offer
                                                        </span>
                                                        <span className="caseStatusCounter" data-type="status" style={{ color: '#fff' }} data-value="Job Offer" onClick={selectFilter}>
                                                            {
                                                                pipelineList.length > 0 && pipelineList.map((data, index) => {
                                                                    if (data.status === "Job Offer") {
                                                                        return data.count;
                                                                    }
                                                                })
                                                            }
                                                        </span>
                                                    </li> : null
                                                }
                                                {
                                                    (pipelineList.filter(function (val) {
                                                        return val.status === 'Joined';
                                                    }).length !== 0) ? <li style={{ backgroundColor: "#4768b0", color: "#fff" }} data-type="status" data-value="Joined" onClick={selectFilter}>
                                                        <span className="recuriterItem d-flex align-items-center" style={{ color: '#fff' }} data-type="status" data-value="Joined" onClick={selectFilter}>
                                                            Joined
                                                        </span>
                                                        <span className="caseStatusCounter" data-type="status" style={{ color: '#fff' }} data-value="Joined" onClick={selectFilter}>
                                                            {
                                                                pipelineList.length > 0 && pipelineList.map((data, index) => {
                                                                    if (data.status === "Joined") {
                                                                        return data.count;
                                                                    }
                                                                })
                                                            }
                                                        </span>
                                                    </li> : null
                                                }
                                                {
                                                    (pipelineList.filter(function (val) {
                                                        return val.status === 'Refused Offer';
                                                    }).length !== 0) ?
                                                        <li style={{ backgroundColor: "#a84d4d", color: "#fff" }} data-type="status" data-value="Refused Offer" onClick={selectFilter}>
                                                            <span className="recuriterItem d-flex align-items-center" style={{ color: '#fff' }} data-type="status" data-value="Refused Offer" onClick={selectFilter}>
                                                                Refused Offer
                                                            </span>
                                                            <span className="caseStatusCounter" data-type="status" style={{ color: '#fff' }} data-value="Refused Offer" onClick={selectFilter}>
                                                                {
                                                                    pipelineList.length > 0 && pipelineList.map((data, index) => {
                                                                        if (data.status === "Refused Offer") {
                                                                            return data.count;
                                                                        }
                                                                    })
                                                                }
                                                            </span>
                                                        </li> : null
                                                }
                                                {
                                                    (pipelineList.filter(function (val) {
                                                        return val.status === 'Credit';
                                                    }).length !== 0) ? <li style={{ backgroundColor: "#d9714e", color: "#fff" }} data-type="status" data-value="Credit" onClick={selectFilter}>
                                                        <span className="recuriterItem d-flex align-items-center" style={{ color: '#fff' }} data-type="status" data-value="Credit" onClick={selectFilter}>
                                                            Credit
                                                        </span>
                                                        <span className="caseStatusCounter" data-type="status" style={{ color: '#fff' }} data-value="Credit" onClick={selectFilter}>
                                                            {
                                                                pipelineList.length > 0 && pipelineList.map((data, index) => {
                                                                    if (data.status === "Credit") {
                                                                        return data.count;
                                                                    }
                                                                })
                                                            }
                                                        </span>
                                                    </li>
                                                        : null

                                                }
                                                {
                                                    (pipelineList.filter(function (val) {
                                                        return val.status === 'Invoice';
                                                    }).length !== 0) ? <li style={{ backgroundColor: "#39485d", color: "#fff" }} data-type="status" data-value="Invoice" onClick={selectFilter}>
                                                        <span className="recuriterItem d-flex align-items-center" style={{ color: '#fff' }} data-type="status" data-value="Invoice" onClick={selectFilter}>
                                                            Invoice
                                                        </span>
                                                        <span className="caseStatusCounter" data-type="status" style={{ color: '#fff' }} data-value="Invoice" onClick={selectFilter}>
                                                            {
                                                                pipelineList.length > 0 && pipelineList.map((data, index) => {
                                                                    if (data.status === "Invoice") {
                                                                        return data.count;
                                                                    }
                                                                })
                                                            }
                                                        </span>
                                                    </li> : null
                                                }

                                                {
                                                    (pipelineList.filter(function (val) {
                                                        return val.status === 'Closed';
                                                    }).length !== 0) ? <li style={{ backgroundColor: "#da3d5e", color: "#fff" }} data-type="status" data-value="Closed" onClick={selectFilter}>
                                                        <span className="recuriterItem d-flex align-items-center" style={{ color: '#fff' }} data-type="status" data-value="Closed" onClick={selectFilter}>
                                                            Closed
                                                        </span>
                                                        <span className="caseStatusCounter" data-type="status" style={{ color: '#fff' }} data-value="Closed" onClick={selectFilter}>
                                                            {
                                                                pipelineList.length > 0 && pipelineList.map((data, index) => {
                                                                    if (data.status === "Closed") {
                                                                        return data.count;
                                                                    }
                                                                })
                                                            }
                                                        </span>
                                                    </li> : null
                                                }

                                            </ul>

                                        </div> */}
            </div>
          </div>
          <div className="col-md-12">
            <div className="d-flex dkg-db-motop-boxCon">
              <div className="dk-summaryCols xl dk-listing">
                <div
                  className={`${
                    !isCaseStatusFilter ? "dk-toptitle" : "dk-toptitle-filterBg"
                  }`}
                >
                  {!isCaseStatusFilter ? (
                    <div className="dk-colstitle">Case Status</div>
                  ) : (
                    <div className="dk-dbsuMM-filterHeader">
                      {caseStatusFilterText}
                      <span>
                        <i
                          className="fa fa-times-circle  ml-2"
                          data-type="caseStatus"
                          onClick={removeTag}
                        ></i>
                      </span>
                    </div>
                  )}
                  <span className="dk-totalCvCount">
                    {caseStatusList.reduce(
                      (total, currentValue) =>
                        (total = total + currentValue.count),
                      0
                    )}
                  </span>
                </div>
                <div className="dk-summaryList">
                  <div className="dk-listScrollX">
                    {caseStatusList.filter(function (val) {
                      return val.caseStatus === "New CV";
                    }).length !== 0 ? (
                      <li
                        style={{ backgroundColor: "#5c737d", color: " #fff" }}
                        data-type="caseStatus"
                        data-value="New CV"
                        onClick={selectFilter}
                        className="dkg-srchcv-casestus-hover"
                      >
                        New CV
                        <span
                          className="dk-srchcv-casecounter"
                          data-type="caseStatus"
                          data-value="New CV"
                          onClick={selectFilter}
                        >
                          {caseStatusList
                            .filter((items) => items.caseStatus === "New CV")
                            .map((item, index) => {
                              return item.count;
                            })}
                        </span>
                      </li>
                    ) : null}
                    {caseStatusList.filter(function (val) {
                      return val.caseStatus === "To Process";
                    }).length !== 0 ? (
                      <li
                        style={{ backgroundColor: "#796698", color: "#fff" }}
                        data-type="caseStatus"
                        data-value="To Process"
                        onClick={selectFilter}
                        className="dkg-srchcv-casestus-hover"
                      >
                        To Process
                        <span
                          className="dk-srchcv-casecounter"
                          data-type="caseStatus"
                          data-value="To Process"
                          onClick={selectFilter}
                        >
                          {caseStatusList
                            .filter(
                              (items) => items.caseStatus === "To Process"
                            )
                            .map((item, index) => {
                              return item.count;
                            })}
                        </span>
                      </li>
                    ) : null}
                    {caseStatusList.filter(function (val) {
                      return val.caseStatus === "Calling";
                    }).length !== 0 ? (
                      <li
                        style={{ backgroundColor: "#55496b", color: "#fff" }}
                        data-type="caseStatus"
                        data-value="Calling"
                        onClick={selectFilter}
                        className="dkg-srchcv-casestus-hover"
                      >
                        Calling
                        <span
                          className="dk-srchcv-casecounter"
                          data-type="caseStatus"
                          data-value="Calling"
                          onClick={selectFilter}
                        >
                          {caseStatusList
                            .filter((items) => items.caseStatus === "Calling")
                            .map((item, index) => {
                              return item.count;
                            })}
                        </span>
                      </li>
                    ) : null}
                    {caseStatusList.filter(function (val) {
                      return val.caseStatus === "Job Spec";
                    }).length !== 0 ? (
                      <li
                        style={{ backgroundColor: "#2d9b99", color: "#fff" }}
                        data-type="caseStatus"
                        data-value="Job Spec"
                        onClick={selectFilter}
                        className="dkg-srchcv-casestus-hover"
                      >
                        Job Spec
                        <span
                          className="dk-srchcv-casecounter"
                          data-type="caseStatus"
                          data-value="Job Spec"
                          onClick={selectFilter}
                        >
                          {caseStatusList
                            .filter((items) => items.caseStatus === "Job Spec")
                            .map((item, index) => {
                              return item.count;
                            })}
                        </span>
                      </li>
                    ) : null}
                    {caseStatusList.filter(function (val) {
                      return val.caseStatus === "Sendouts";
                    }).length !== 0 ? (
                      <li
                        style={{ backgroundColor: "#286967", color: "#fff" }}
                        data-type="caseStatus"
                        data-value="Sendouts"
                        onClick={selectFilter}
                        className="dkg-srchcv-casestus-hover"
                      >
                        Sendouts
                        <span
                          className="dk-srchcv-casecounter"
                          data-type="caseStatus"
                          data-value="Sendouts"
                          onClick={selectFilter}
                        >
                          {caseStatusList
                            .filter((items) => items.caseStatus === "Sendouts")
                            .map((item, index) => {
                              return item.count;
                            })}
                        </span>
                      </li>
                    ) : null}
                    {caseStatusList.filter(function (val) {
                      return val.caseStatus === "Job Offer";
                    }).length !== 0 ? (
                      <li
                        style={{ backgroundColor: "#629bd3", color: " #fff" }}
                        data-type="caseStatus"
                        data-value="Job Offer"
                        onClick={selectFilter}
                        className="dkg-srchcv-casestus-hover"
                      >
                        Job Offer
                        <span
                          className="dk-srchcv-casecounter"
                          data-type="caseStatus"
                          data-value="Job Offer"
                          onClick={selectFilter}
                        >
                          {caseStatusList
                            .filter((items) => items.caseStatus === "Job Offer")
                            .map((item, index) => {
                              return item.count;
                            })}
                        </span>
                      </li>
                    ) : null}
                    {caseStatusList.filter(function (val) {
                      return val.caseStatus === "Placement";
                    }).length !== 0 ? (
                      <li
                        style={{ backgroundColor: "#4768b0", color: " #fff" }}
                        data-type="caseStatus"
                        data-value="Placement"
                        onClick={selectFilter}
                        className="dkg-srchcv-casestus-hover"
                      >
                        Placement
                        <span
                          className="dk-srchcv-casecounter"
                          data-type="caseStatus"
                          data-value="Placement"
                          onClick={selectFilter}
                        >
                          {caseStatusList
                            .filter((items) => items.caseStatus === "Placement")
                            .map((item, index) => {
                              return item.count;
                            })}
                        </span>
                      </li>
                    ) : null}
                    {caseStatusList.filter(function (val) {
                      return val.caseStatus === "On Hold";
                    }).length !== 0 ? (
                      <li
                        style={{ backgroundColor: "#7f5347", color: " #fff" }}
                        data-type="caseStatus"
                        data-value="On Hold"
                        onClick={selectFilter}
                        className="dkg-srchcv-casestus-hover"
                      >
                        On Hold
                        <span
                          className="dk-srchcv-casecounter"
                          data-type="caseStatus"
                          data-value="On Hold"
                          onClick={selectFilter}
                        >
                          {caseStatusList
                            .filter((items) => items.caseStatus === "On Hold")
                            .map((item, index) => {
                              return item.count;
                            })}
                        </span>
                      </li>
                    ) : null}
                    {caseStatusList.filter(function (val) {
                      return val.caseStatus === "Refused Offer";
                    }).length !== 0 ? (
                      <li
                        style={{ backgroundColor: "#a84d4d", color: " #fff" }}
                        data-type="caseStatus"
                        data-value="Refused Offer"
                        onClick={selectFilter}
                        className="dkg-srchcv-casestus-hover"
                      >
                        Redfused Offer
                        <span
                          className="dk-srchcv-casecounter"
                          data-type="caseStatus"
                          data-value="Refused Offer"
                          onClick={selectFilter}
                        >
                          {caseStatusList
                            .filter(
                              (items) => items.caseStatus === "Refused Offer"
                            )
                            .map((item, index) => {
                              return item.count;
                            })}
                        </span>
                      </li>
                    ) : null}
                    {caseStatusList.filter(function (val) {
                      return val.caseStatus === "Credit";
                    }).length !== 0 ? (
                      <li
                        style={{ backgroundColor: "#d9714e", color: " #fff" }}
                        data-type="caseStatus"
                        data-value="Credit"
                        onClick={selectFilter}
                        className="dkg-srchcv-casestus-hover"
                      >
                        Credit
                        <span
                          className="dk-srchcv-casecounter"
                          data-type="caseStatus"
                          data-value="Credit"
                          onClick={selectFilter}
                        >
                          {caseStatusList
                            .filter((items) => items.caseStatus === "Credit")
                            .map((item, index) => {
                              return item.count;
                            })}
                        </span>
                      </li>
                    ) : null}
                    {caseStatusList.filter(function (val) {
                      return val.caseStatus === "Closed";
                    }).length !== 0 ? (
                      <li
                        style={{ backgroundColor: "#da3d5e", color: " #fff" }}
                        data-type="caseStatus"
                        data-value="Closed"
                        onClick={selectFilter}
                        className="dkg-srchcv-casestus-hover"
                      >
                        Closed
                        <span
                          className="dk-srchcv-casecounter"
                          data-type="caseStatus"
                          data-value="Closed"
                          onClick={selectFilter}
                        >
                          {caseStatusList
                            .filter((items) => items.caseStatus === "Closed")
                            .map((item, index) => {
                              return item.count;
                            })}
                        </span>
                      </li>
                    ) : null}
                    {caseStatusList.filter(function (val) {
                      return val.caseStatus === "Escalated";
                    }).length !== 0 ? (
                      <li
                        style={{ backgroundColor: "#670004", color: " #fff" }}
                        data-type="caseStatus"
                        data-value="Escalated"
                        onClick={selectFilter}
                        className="dkg-srchcv-casestus-hover"
                      >
                        Escalated
                        <span
                          className="dk-srchcv-casecounter"
                          data-type="caseStatus"
                          data-value="Escalated"
                          onClick={selectFilter}
                        >
                          {caseStatusList
                            .filter((items) => items.caseStatus === "Escalated")
                            .map((item, index) => {
                              return item.count;
                            })}
                        </span>
                      </li>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="dk-summaryCols xl dk-listing">
                <div
                  className={`${
                    !isCaseStageFilter ? "dk-toptitle" : "dk-toptitle-filterBg"
                  }`}
                >
                  {!isCaseStageFilter ? (
                    <div className="dk-colstitle">Case Stage</div>
                  ) : (
                    <div className="dk-dbsuMM-filterHeader">
                      {caseStageFilterText}
                      <span>
                        <i
                          className="fa fa-times-circle  ml-2"
                          data-type="case_stage"
                          onClick={removeTag}
                        ></i>
                      </span>
                    </div>
                  )}
                  <span className="dk-totalCvCount">
                    {caseStageList.reduce(
                      (total, currentValue) =>
                        (total = total + currentValue.count),
                      0
                    )}
                  </span>
                </div>
                <div className="dk-summaryList">
                  <div className="dk-listScrollX">
                    {caseStageList.map((item, index) => (
                      <li
                        key={`casestage${index}`}
                        data-type="case_stage"
                        data-value={item.name}
                        onClick={selectFilter}
                      >
                        {item.name !== "" ? item.name : "Blank"}
                        <span
                          data-type="case_stage"
                          data-value={item.name}
                          onClick={selectFilter}
                        >
                          {item.count}
                        </span>
                      </li>
                    ))}
                  </div>
                </div>
              </div>
              <div className="dk-summaryCols xl dk-listing">
                <div
                  className={`${
                    !isCaseProgressFilter
                      ? "dk-toptitle"
                      : "dk-toptitle-filterBg"
                  }`}
                >
                  {!isCaseProgressFilter ? (
                    <div className="dk-colstitle">Case Progress</div>
                  ) : (
                    <div className="dk-dbsuMM-filterHeader">
                      {caseProgressFilterText}
                      <span>
                        <i
                          className="fa fa-times-circle  ml-2"
                          data-type="case_progress"
                          onClick={removeTag}
                        ></i>
                      </span>
                    </div>
                  )}
                  <span className="dk-totalCvCount">
                    {caseProgressList.reduce(
                      (total, currentValue) =>
                        (total = total + currentValue.count),
                      0
                    )}
                  </span>
                </div>
                <div className="dk-summaryList">
                  <div className="dk-listScrollX">
                    {caseProgressList.map((item, index) => (
                      <li
                        key={`caseprogress${index}`}
                        data-type="case_progress"
                        data-value={item.name}
                        onClick={selectFilter}
                      >
                        {item.name !== "" ? item.name : "Blank"}
                        <span
                          data-type="case_progress"
                          data-value={item.name}
                          onClick={selectFilter}
                        >
                          {item.count}
                        </span>
                      </li>
                    ))}
                  </div>
                </div>
              </div>
              <div className="dk-summaryCols xl dk-listing">
                <div
                  className={`${
                    !isCvsourceFilter ? "dk-toptitle" : "dk-toptitle-filterBg"
                  }`}
                >
                  {!isCvsourceFilter ? (
                    <div className="dk-colstitle">CASE SITUATION</div>
                  ) : (
                    <div className="dk-dbsuMM-filterHeader">
                      {cvsourceFilterText}
                      <span>
                        <i
                          className="fa fa-times-circle  ml-2"
                          data-type="cv_source"
                          onClick={removeTag}
                        ></i>
                      </span>
                    </div>
                  )}
                  <span className="dk-totalCvCount">
                    {cvSourceList.reduce(
                      (total, currentValue) =>
                        (total = total + currentValue.count),
                      0
                    )}
                  </span>
                </div>
                <div className="dk-summaryList">
                  <div className="dk-listScrollX">
                    {cvSourceList.map((item, index) => (
                      <li
                        key={`cvsource${index}`}
                        data-type="cv_source"
                        data-value={item.name}
                        onClick={selectFilter}
                      >
                        {item.name !== "" ? item.name : "Blank"}
                        <span
                          data-type="cv_source"
                          data-value={item.name}
                          onClick={selectFilter}
                        >
                          {item.count}
                        </span>
                      </li>
                    ))}
                  </div>
                </div>
              </div>
              <div className="dk-summaryCols xl dk-listing">
                <div
                  className={`${
                    !isCvsourceFilter ? "dk-toptitle" : "dk-toptitle-filterBg"
                  }`}
                >
                  {!isCvsourceFilter ? (
                    <div className="dk-colstitle">CV SOURCE</div>
                  ) : (
                    <div className="dk-dbsuMM-filterHeader">
                      {cvsourceFilterText}
                      <span>
                        <i
                          className="fa fa-times-circle  ml-2"
                          data-type="cv_source"
                          onClick={removeTag}
                        ></i>
                      </span>
                    </div>
                  )}
                  <span className="dk-totalCvCount">
                    {cvSourceList.reduce(
                      (total, currentValue) =>
                        (total = total + currentValue.count),
                      0
                    )}
                  </span>
                </div>
                <div className="dk-summaryList">
                  <div className="dk-listScrollX">
                    {cvSourceList.map((item, index) => (
                      <li
                        key={`cvsource${index}`}
                        data-type="cv_source"
                        data-value={item.name}
                        onClick={selectFilter}
                      >
                        {item.name !== "" ? item.name : "Blank"}
                        <span
                          data-type="cv_source"
                          data-value={item.name}
                          onClick={selectFilter}
                        >
                          {item.count}
                        </span>
                      </li>
                    ))}
                  </div>
                </div>
              </div>
              <div className="dk-summaryCols xl dk-listing">
                <div
                  className={`${
                    !isCityFilter ? "dk-toptitle" : "dk-toptitle-filterBg"
                  }`}
                >
                  {!isCityFilter ? (
                    <div className="dk-colstitle">CITY/TOWN</div>
                  ) : (
                    <div className="dk-dbsuMM-filterHeader">
                      {cityFilterText}
                      <span>
                        <i
                          className="fa fa-times-circle  ml-2"
                          data-type="c_city"
                          onClick={removeTag}
                        ></i>
                      </span>
                    </div>
                  )}
                  <span className="dk-totalCvCount">
                    {cityList.reduce(
                      (total, currentValue) =>
                        (total = total + currentValue.count),
                      0
                    )}
                  </span>
                </div>
                <div className="dk-summaryList">
                  <div className="dk-listScrollX">
                    {cityList.map((item, index) => (
                      <li
                        key={`c_city${index}`}
                        data-type="c_city"
                        data-value={item.c_city}
                        onClick={selectFilter}
                      >
                        {item.c_city !== "" ? item.c_city : "Blank"}
                        <span
                          data-type="c_city"
                          data-value={item.c_city}
                          onClick={selectFilter}
                        >
                          {item.count}
                        </span>
                      </li>
                    ))}
                  </div>
                </div>
              </div>
              {/* <div className="dk-summaryCols xl dk-listing">
                                <div className={`${(!isRecAdminFilter) ? 'dk-toptitle' : 'dk-toptitle-filterBg'}`}>
                                    {
                                        (!isRecAdminFilter) ?
                                            <div className="dk-colstitle">REC ADMIN</div>
                                            :
                                            <div className='dk-dbsuMM-filterHeader'>
                                                {recAdminFilterText}
                                                <span><i className='fa fa-times-circle  ml-2' data-type="rec_admin" onClick={removeTag}></i></span>
                                            </div>
                                    }
                                    <span className="dk-totalCvCount">
                                        {recAdminList.reduce((total, currentValue) => total = total + currentValue.count, 0)}
                                    </span>
                                </div>
                                <ul>
                                    <div className="dk-listScrollX">

                                        {
                                            recAdminList.map((data, index) => (
                                                <li key={index + 1} data-type="rec_admin" data-text={data.recInfo.firstName + " " + data.recInfo.lastName} data-value={data.recInfo.id} onClick={selectFilter}>
                                                    <div data-type="rec_admin" data-text={data.recInfo.firstName + " " + data.recInfo.lastName} data-value={data.recInfo.id} onClick={selectFilter}>
                                                        <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + data.recInfo.profileImage} className="summary-rec-img" data-type="rec_admin" data-text={data.recInfo.firstName + " " + data.recInfo.lastName} data-value={data.recInfo.id} onClick={selectFilter} />
                                                        {data.recInfo.firstName + " " + data.recInfo.lastName}
                                                    </div>
                                                    <span data-type="rec_admin" data-text={data.recInfo.firstName + " " + data.recInfo.lastName} data-value={data.recInfo.id} onClick={selectFilter}>{data.count}</span>
                                                </li>
                                            ))
                                        }
                                    </div>
                                </ul>
                            </div> */}
            </div>
            <div className="d-flex dkg-db-motop-boxCon">
              <div className="dk-summaryCols xl dk-listing">
                <div
                  className={`${
                    !isCountryFilter ? "dk-toptitle" : "dk-toptitle-filterBg"
                  }`}
                >
                  {!isCountryFilter ? (
                    <div className="dk-colstitle">COUNTRY</div>
                  ) : (
                    <div className="dk-dbsuMM-filterHeader">
                      {countryFilterText}
                      <span>
                        <i
                          className="fa fa-times-circle  ml-2"
                          data-type="c_country"
                          onClick={removeTag}
                        ></i>
                      </span>
                    </div>
                  )}
                  <span className="dk-totalCvCount">
                    {countryList.reduce(
                      (total, currentValue) =>
                        (total = total + currentValue.count),
                      0
                    )}
                  </span>
                </div>
                <div className="dk-summaryList">
                  <div className="dk-listScrollX">
                    {countryList.map((item, index) => (
                      <li
                        key={`c_country${index}`}
                        data-type="c_country"
                        data-value={item.c_country}
                        onClick={selectFilter}
                      >
                        {item.c_country !== "" ? item.c_country : "Blank"}
                        <span
                          data-type="c_country"
                          data-value={item.c_country}
                          onClick={selectFilter}
                        >
                          {item.count}
                        </span>
                      </li>
                    ))}
                  </div>
                </div>
              </div>
              <div className="dk-summaryCols xl dk-listing">
                <div
                  className={`${
                    !isNationalityFilter
                      ? "dk-toptitle"
                      : "dk-toptitle-filterBg"
                  }`}
                >
                  {!isNationalityFilter ? (
                    <div className="dk-colstitle">NATIONALITY</div>
                  ) : (
                    <div className="dk-dbsuMM-filterHeader">
                      {nationalityFilterText}
                      <span>
                        <i
                          className="fa fa-times-circle  ml-2"
                          data-type="nationality"
                          onClick={removeTag}
                        ></i>
                      </span>
                    </div>
                  )}
                  <span className="dk-totalCvCount">
                    {nationalityList.reduce(
                      (total, currentValue) =>
                        (total = total + currentValue.count),
                      0
                    )}
                  </span>
                </div>
                <div className="dk-summaryList">
                  <div className="dk-listScrollX">
                    {nationalityList.map((item, index) => (
                      <li
                        key={`nationality${index}`}
                        data-type="nationality"
                        data-value={item.name}
                        onClick={selectFilter}
                      >
                        {item.name !== "" ? item.name : "Blank"}
                        <span
                          data-type="nationality"
                          data-value={item.name}
                          onClick={selectFilter}
                        >
                          {item.count}
                        </span>
                      </li>
                    ))}
                  </div>
                </div>
              </div>
              <div className="dk-summaryCols xl dk-listing">
                <div
                  className={`${
                    !isVisaFilter ? "dk-toptitle" : "dk-toptitle-filterBg"
                  }`}
                >
                  {!isVisaFilter ? (
                    <div className="dk-colstitle">VISA STATUS</div>
                  ) : (
                    <div className="dk-dbsuMM-filterHeader">
                      {visaFilterText}
                      <span>
                        <i
                          className="fa fa-times-circle  ml-2"
                          data-type="visaStatus"
                          onClick={removeTag}
                        ></i>
                      </span>
                    </div>
                  )}
                  <span className="dk-totalCvCount">
                    {visaList.reduce(
                      (total, currentValue) =>
                        (total = total + currentValue.count),
                      0
                    )}
                  </span>
                </div>
                <div className="dk-summaryList">
                  <div className="dk-listScrollX">
                    {visaList.map((item, index) => (
                      <li
                        key={`visaStatus${index}`}
                        data-type="visaStatus"
                        data-value={item.visaStatus}
                        onClick={selectFilter}
                      >
                        {item.visaStatus !== "" ? item.visaStatus : "Blank"}
                        <span
                          data-type="visaStatus"
                          data-value={item.visaStatus}
                          onClick={selectFilter}
                        >
                          {item.count}
                        </span>
                      </li>
                    ))}
                  </div>
                </div>
              </div>
              <div className="dk-summaryCols xl dk-listing">
                <div
                  className={`${
                    !isEducationFilter ? "dk-toptitle" : "dk-toptitle-filterBg"
                  }`}
                >
                  {!isEducationFilter ? (
                    <div className="dk-colstitle">EDUCATION</div>
                  ) : (
                    <div className="dk-dbsuMM-filterHeader">
                      {educationFilterText}
                      <span>
                        <i
                          className="fa fa-times-circle  ml-2"
                          data-type="education"
                          onClick={removeTag}
                        ></i>
                      </span>
                    </div>
                  )}
                  <span className="dk-totalCvCount">
                    {educationList.reduce(
                      (total, currentValue) =>
                        (total = total + currentValue.count),
                      0
                    )}
                  </span>
                </div>
                <div className="dk-summaryList">
                  <div className="dk-listScrollX">
                    {educationList.map((item, index) => (
                      <li
                        key={`education${index}`}
                        data-type="education"
                        data-value={item.education}
                        onClick={selectFilter}
                      >
                        {item.education !== "" ? item.education : "Blank"}
                        <span
                          data-type="education"
                          data-value={item.education}
                          onClick={selectFilter}
                        >
                          {item.count}
                        </span>
                      </li>
                    ))}
                  </div>
                </div>
              </div>
              <div className="dk-summaryCols xl dk-listing">
                <div
                  className={`${
                    !isCareerFilter ? "dk-toptitle" : "dk-toptitle-filterBg"
                  }`}
                >
                  {!isCareerFilter ? (
                    <div className="dk-colstitle">CAREER LEVEL</div>
                  ) : (
                    <div className="dk-dbsuMM-filterHeader">
                      {careerFilterText}
                      <span>
                        <i
                          className="fa fa-times-circle  ml-2"
                          data-type="career_level"
                          onClick={removeTag}
                        ></i>
                      </span>
                    </div>
                  )}
                  <span className="dk-totalCvCount">
                    {careerList.reduce(
                      (total, currentValue) =>
                        (total = total + currentValue.count),
                      0
                    )}
                  </span>
                </div>
                <div className="dk-summaryList">
                  <div className="dk-listScrollX">
                    {careerList.map((item, index) => (
                      <li
                        key={`career_level${index}`}
                        data-type="career_level"
                        data-value={item.career_level}
                        onClick={selectFilter}
                      >
                        {item.career_level !== "" ? item.career_level : "Blank"}
                        <span
                          data-type="career_level"
                          data-value={item.career_level}
                          onClick={selectFilter}
                        >
                          {item.count}
                        </span>
                      </li>
                    ))}
                  </div>
                </div>
              </div>
              <div className="dk-summaryCols xl dk-listing">
                <div
                  className={`${
                    !isJobTitleFilter ? "dk-toptitle" : "dk-toptitle-filterBg"
                  }`}
                >
                  {!isJobTitleFilter ? (
                    <div className="dk-colstitle">CURRENT JOB</div>
                  ) : (
                    <div className="dk-dbsuMM-filterHeader">
                      {jobTitleFilterText}
                      <span>
                        <i
                          className="fa fa-times-circle  ml-2"
                          data-type="name"
                          onClick={removeTag}
                        ></i>
                      </span>
                    </div>
                  )}
                  <span className="dk-totalCvCount">
                    {jobTitleList.reduce(
                      (total, currentValue) =>
                        (total = total + currentValue.count),
                      0
                    )}
                  </span>
                </div>
                <div className="dk-summaryList">
                  <div className="dk-listScrollX">
                    {jobTitleList.map((item, index) => (
                      <li
                        key={`name${index}`}
                        data-type="name"
                        data-value={item.jobDetail.name}
                        onClick={selectFilter}
                      >
                        {item.jobDetail.name !== ""
                          ? item.jobDetail.name
                          : "Blank"}
                        <span
                          data-type="name"
                          data-value={item.jobDetail.name}
                          onClick={selectFilter}
                        >
                          {item.count}
                        </span>
                      </li>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex dkg-db-motop-boxCon">
              <div className="dk-summaryCols xl dk-listing">
                <div
                  className={`${
                    !isIndustriesFilter ? "dk-toptitle" : "dk-toptitle-filterBg"
                  }`}
                >
                  {!isIndustriesFilter ? (
                    <div className="dk-colstitle">INDUSTRY</div>
                  ) : (
                    <div className="dk-dbsuMM-filterHeader">
                      {industriesFilterText}
                      <span>
                        <i
                          className="fa fa-times-circle  ml-2"
                          data-type="industries"
                          onClick={removeTag}
                        ></i>
                      </span>
                    </div>
                  )}
                  <span className="dk-totalCvCount">
                    {industriesList.reduce(
                      (total, currentValue) =>
                        (total = total + currentValue.count),
                      0
                    )}
                  </span>
                </div>
                <div className="dk-summaryList">
                  <div className="dk-listScrollX">
                    {industriesList.map((item, index) => (
                      <li
                        key={`industries${index}`}
                        data-type="industries"
                        data-value={item.industries}
                        onClick={selectFilter}
                      >
                        {item.industries !== "" ? item.industries : "Blank"}
                        <span
                          data-type="industries"
                          data-value={item.industries}
                          onClick={selectFilter}
                        >
                          {item.count}
                        </span>
                      </li>
                    ))}
                  </div>
                </div>
              </div>
              <div className="dk-summaryCols xl dk-listing">
                <div
                  className={`${
                    !isJobLang1Filter ? "dk-toptitle" : "dk-toptitle-filterBg"
                  }`}
                >
                  {!isJobLang1Filter ? (
                    <div className="dk-colstitle">Native Language</div>
                  ) : (
                    <div className="dk-dbsuMM-filterHeader">
                      {jobLang1FilterText}
                      <span>
                        <i
                          className="fa fa-times-circle  ml-2"
                          data-type="joblang"
                          onClick={removeTag}
                        ></i>
                      </span>
                    </div>
                  )}
                  <span className="dk-totalCvCount">
                    {jobLang1.reduce(
                      (total, currentValue) =>
                        (total = total + currentValue.count),
                      0
                    )}
                  </span>
                </div>
                <div className="dk-summaryList">
                  {jobLang1.map((item, index) => (
                    <li
                      key={`lang${index}`}
                      data-type="joblang"
                      data-value={item.jobDetail.lang}
                      onClick={selectFilter}
                    >
                      {item.jobDetail.lang !== ""
                        ? item.jobDetail.lang
                        : "Blank"}
                      <span
                        data-type="joblang"
                        data-value={item.jobDetail.lang}
                        onClick={selectFilter}
                      >
                        {item.count}
                      </span>
                    </li>
                  ))}
                </div>
              </div>
              <div className="dk-summaryCols xl dk-listing">
                <div
                  className={`${
                    !isJobLang2Filter ? "dk-toptitle" : "dk-toptitle-filterBg"
                  }`}
                >
                  {!isJobLang2Filter ? (
                    <div className="dk-colstitle">Fluent Language</div>
                  ) : (
                    <div className="dk-dbsuMM-filterHeader">
                      {jobLang2FilterText}
                      <span>
                        <i
                          className="fa fa-times-circle  ml-2"
                          data-type="joblang2"
                          onClick={removeTag}
                        ></i>
                      </span>
                    </div>
                  )}
                  <span className="dk-totalCvCount">
                    {jobLang2.reduce(
                      (total, currentValue) =>
                        (total = total + currentValue.count),
                      0
                    )}
                  </span>
                </div>
                <div className="dk-summaryList">
                  {jobLang2.map((item, index) => (
                    <li
                      key={`lang${index}`}
                      data-type="joblang2"
                      data-value={item.jobDetail.lang2}
                      onClick={selectFilter}
                    >
                      {item.jobDetail.lang2 !== ""
                        ? item.jobDetail.lang2
                        : "Blank"}
                      <span
                        data-type="joblang2"
                        data-value={item.jobDetail.lang2}
                        onClick={selectFilter}
                      >
                        {item.count}
                      </span>
                    </li>
                  ))}
                </div>
              </div>
              <div className="dk-summaryCols xl dk-listing">
                <div
                  className={`${
                    !isIntermediateLanguageFilter
                      ? "dk-toptitle"
                      : "dk-toptitle-filterBg"
                  }`}
                >
                  {!isIntermediateLanguageFilter ? (
                    <div className="dk-colstitle">INTERMEDIATE LANG</div>
                  ) : (
                    <div className="dk-dbsuMM-filterHeader">
                      {intermediateLanguageFilterText}
                      <span>
                        <i
                          className="fa fa-times-circle  ml-2"
                          data-type="intermediate"
                          onClick={removeTag}
                        ></i>
                      </span>
                    </div>
                  )}
                  <span className="dk-totalCvCount">
                    {intermediateLanguageList.reduce(
                      (total, currentValue) =>
                        (total = total + currentValue.count),
                      0
                    )}
                  </span>
                </div>
                <div className="dk-summaryList">
                  <div className="dk-listScrollX">
                    {intermediateLanguageList.map((item, index) => (
                      <li
                        key={`intermediate${index}`}
                        data-type="intermediate1"
                        data-value={item.name}
                        onClick={selectFilter}
                      >
                        {item.name !== "" ? item.name : "Blank"}
                        <span
                          data-type="intermediate1"
                          data-value={item.name}
                          onClick={selectFilter}
                        >
                          {item.count}
                        </span>
                      </li>
                    ))}
                  </div>
                </div>
              </div>
              <div className="dk-summaryCols xl dk-listing">
                <div
                  className={`${
                    !isDomainFilter ? "dk-toptitle" : "dk-toptitle-filterBg"
                  }`}
                >
                  {!isDomainFilter ? (
                    <div className="dk-colstitle">Experience</div>
                  ) : (
                    <div className="dk-dbsuMM-filterHeader">
                      {domainFilterText}
                      <span>
                        <i
                          className="fa fa-times-circle  ml-2"
                          data-type="priority"
                          onClick={removeTag}
                        ></i>
                      </span>
                    </div>
                  )}
                  <span className="dk-totalCvCount">
                    {domainList.reduce(
                      (total, currentValue) =>
                        (total = total + currentValue.count),
                      0
                    )}
                  </span>
                </div>
                <div className="dk-summaryList">
                  <div className="dk-listScrollX">
                    {domainList.map((item, index) => (
                      <li
                        key={`priority${index}`}
                        data-type="priority"
                        data-value={item.priority}
                        onClick={selectFilter}
                      >
                        {item.priority !== "" ? item.priority : "Blank"}
                        <span
                          data-type="priority"
                          data-value={item.priority}
                          onClick={selectFilter}
                        >
                          {item.count}
                        </span>
                      </li>
                    ))}
                  </div>
                </div>
              </div>
              <div className="dk-summaryCols xl dk-listing">
                <div
                  className={`${
                    !isITSkillsFilter ? "dk-toptitle" : "dk-toptitle-filterBg"
                  }`}
                >
                  {!isITSkillsFilter ? (
                    <div className="dk-colstitle">Technical Skills</div>
                  ) : (
                    <div className="dk-dbsuMM-filterHeader">
                      {iTSkillsFilterText}
                      <span>
                        <i
                          className="fa fa-times-circle  ml-2"
                          data-type="technical_skills"
                          onClick={removeTag}
                        ></i>
                      </span>
                    </div>
                  )}
                  <span className="dk-totalCvCount">
                    {itSkillsList.reduce(
                      (total, currentValue) =>
                        (total = total + currentValue.count),
                      0
                    )}
                  </span>
                </div>
                <div className="dk-summaryList">
                  <div className="dk-listScrollX">
                    {itSkillsList.map((item, index) => (
                      <li
                        key={`technical_skills${index}`}
                        data-type="technical_skills"
                        data-value={item.technical_skills}
                        onClick={selectFilter}
                      >
                        {item.technical_skills !== "" &&
                        item.technical_skills !== null
                          ? item.technical_skills
                          : "Blank"}
                        <span
                          data-type="technical_skills"
                          data-value={item.technical_skills}
                          onClick={selectFilter}
                        >
                          {item.count}
                        </span>
                      </li>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="d-flex dkg-db-motop-boxCon">
                         
                        </div> */}
            <div className="d-flex dkg-db-motop-boxCon">
              <div className="dk-summaryCols xl dk-listing">
                <div
                  className={`${
                    !isJobSkillsFilter ? "dk-toptitle" : "dk-toptitle-filterBg"
                  }`}
                >
                  {!isJobSkillsFilter ? (
                    <div className="dk-colstitle">Job Profile Skills</div>
                  ) : (
                    <div className="dk-dbsuMM-filterHeader">
                      {jobSkillsFilterText}
                      <span>
                        <i
                          className="fa fa-times-circle  ml-2"
                          data-type="job_profile_skills"
                          onClick={removeTag}
                        ></i>
                      </span>
                    </div>
                  )}
                  <span className="dk-totalCvCount">
                    {jobskillsList.reduce(
                      (total, currentValue) =>
                        (total = total + currentValue.count),
                      0
                    )}
                  </span>
                </div>
                <div className="dk-summaryList">
                  <div className="dk-listScrollX">
                    {jobskillsList.map((item, index) => (
                      <li
                        key={`job_profile_skills${index}`}
                        data-type="job_profile_skills"
                        data-value={item.job_profile_skills}
                        onClick={selectFilter}
                      >
                        {item.job_profile_skills !== "" &&
                        item.job_profile_skills !== null
                          ? item.job_profile_skills
                          : "Blank"}
                        <span
                          data-type="job_profile_skills"
                          data-value={item.job_profile_skills}
                          onClick={selectFilter}
                        >
                          {item.count}
                        </span>
                      </li>
                    ))}
                  </div>
                </div>
              </div>
              <div className="dk-summaryCols xl dk-listing">
                <div
                  className={`${
                    !isProfileStatusFilter
                      ? "dk-toptitle"
                      : "dk-toptitle-filterBg"
                  }`}
                >
                  <div className="dk-dbsuMM-filterHeader">
                    {!isProfileStatusFilter ? (
                      <div className="dk-colstitle">CASE PRIORITY</div>
                    ) : (
                      <div className="dk-dbsuMM-filterHeader">
                        {profileStatusFilterText}
                        <span>
                          <i
                            className="fa fa-times-circle  ml-2"
                            data-type="profileStatus"
                            onClick={removeTag}
                          ></i>
                        </span>
                      </div>
                    )}
                  </div>
                  <span className="dk-totalCvCount">
                    {profileStatusList.reduce(function (tot, arr) {
                      return tot + arr.count;
                    }, 0)}
                  </span>
                </div>

                <li
                  data-type="profileStatus"
                  data-text="Clear"
                  data-value="1"
                  onClick={selectFilter}
                >
                  <div
                    data-type="profileStatus"
                    data-text="Clear"
                    data-value="1"
                    onClick={selectFilter}
                  >
                    <FaCheck
                      style={{
                        color: "green",
                        fontSize: "16px",
                        marginRight: "4px",
                      }}
                    />{" "}
                    100% Top Priority
                  </div>
                  <span
                    className="mlCount"
                    data-type="profileStatus"
                    data-text="Clear"
                    data-value="1"
                    onClick={selectFilter}
                  >
                    {profileStatusList
                      .filter((items) => items.name === 1)
                      .map((item, index) => (
                        <span
                          className="recNoCount"
                          data-type="profileStatus"
                          data-text="Clear"
                          data-value="1"
                          onClick={selectFilter}
                        >
                          {item.count}
                        </span>
                      ))}
                  </span>
                </li>
                <li
                  data-type="profileStatus"
                  data-text="Red Alert"
                  data-value="2"
                  onClick={selectFilter}
                >
                  <div
                    data-type="profileStatus"
                    data-text="Red Alert"
                    data-value="2"
                    onClick={selectFilter}
                  >
                    <FaCheck
                      style={{
                        color: "#d58b5d",
                        fontSize: "16px",
                        marginRight: "4px",
                      }}
                    />{" "}
                    75% Mid Priority
                  </div>
                  <span
                    className="mlCount"
                    data-type="profileStatus"
                    data-text="Red Alert"
                    data-value="2"
                    onClick={selectFilter}
                  >
                    {profileStatusList
                      .filter((items) => items.name === 2)
                      .map((item, index) => (
                        <span
                          className="recNoCount"
                          data-type="profileStatus"
                          data-text="Red Alert"
                          data-value="2"
                          onClick={selectFilter}
                        >
                          {item.count}
                        </span>
                      ))}
                    0
                  </span>
                </li>
                <li
                  data-type="profileStatus"
                  data-text="Blocked"
                  data-value="0"
                  onClick={selectFilter}
                >
                  <div
                    data-type="profileStatus"
                    data-text="Blocked"
                    data-value="0"
                    onClick={selectFilter}
                  >
                    <FaCheck
                      style={{
                        color: "#d60606",
                        fontSize: "16px",
                        marginRight: "4px",
                      }}
                    />{" "}
                    0% Low Priority
                  </div>
                  <span
                    className="mlCount"
                    data-type="profileStatus"
                    data-text="Blocked"
                    data-value="0"
                    onClick={selectFilter}
                  >
                    {profileStatusList
                      .filter((items) => items.name === 0)
                      .map((item, index) => (
                        <span
                          className="recNoCount"
                          data-type="profileStatus"
                          data-text="Blocked"
                          data-value="0"
                          onClick={selectFilter}
                        >
                          {item.count}
                        </span>
                      ))}
                    0
                  </span>
                </li>
                <li
                  data-type="profileStatus"
                  data-text="Blocked"
                  data-value="0"
                  onClick={selectFilter}
                >
                  <div
                    data-type="profileStatus"
                    data-text="Blocked"
                    data-value="0"
                    onClick={selectFilter}
                  >
                    <FaCheck
                      style={{
                        color: "#d3d3d3",
                        fontSize: "16px",
                        marginRight: "4px",
                      }}
                    />{" "}
                    Blank
                  </div>
                  <span
                    className="mlCount"
                    data-type="profileStatus"
                    data-text="Blocked"
                    data-value="0"
                    onClick={selectFilter}
                  >
                    {profileStatusList
                      .filter((items) => items.name === 0)
                      .map((item, index) => (
                        <span
                          className="recNoCount"
                          data-type="profileStatus"
                          data-text="Blocked"
                          data-value="0"
                          onClick={selectFilter}
                        >
                          {item.count}
                        </span>
                      ))}
                    0
                  </span>
                </li>
              </div>
              <div className="dk-summaryCols xl dk-listing">
                <div
                  className={`${
                    !isDBenefitsFilter ? "dk-toptitle" : "dk-toptitle-filterBg"
                  }`}
                >
                  {!isDBenefitsFilter ? (
                    <div className="dk-colstitle">CRM PROFILES</div>
                  ) : (
                    <div className="dk-dbsuMM-filterHeader">
                      {dBenefitsFilterText}
                      <span>
                        <i
                          className="fa fa-times-circle  ml-2"
                          data-type="desired_benefits"
                          onClick={removeTag}
                        ></i>
                      </span>
                    </div>
                  )}
                  <span className="dk-totalCvCount">
                    {desiredBenefitsList.reduce(
                      (total, currentValue) =>
                        (total = total + currentValue.count),
                      0
                    )}
                  </span>
                </div>
                <div className="dk-summaryList">
                  <div className="dk-listScrollX">
                    {desiredBenefitsList.map((item, index) => (
                      <li
                        key={`desired_benefits${index}`}
                        data-type="desired_benefits"
                        data-value={item.desired_benefits}
                        onClick={selectFilter}
                      >
                        {item.desired_benefits !== "" &&
                        item.desired_benefits !== null
                          ? item.desired_benefits
                          : "Blank"}
                        <span
                          data-type="desired_benefits"
                          data-value={item.desired_benefits}
                          onClick={selectFilter}
                        >
                          {item.count}
                        </span>
                      </li>
                    ))}
                  </div>
                </div>
              </div>
              <div className="dk-summaryCols xl dk-listing">
                <div
                  className={`${
                    !isFlagFilter ? "dk-toptitle" : "dk-toptitle-filterBg"
                  }`}
                >
                  <div className="dk-dbsuMM-filterHeader">
                    {!isFlagFilter ? (
                      <div className="dk-colstitle">CANDIDATE</div>
                    ) : (
                      <div className="dk-dbsuMM-filterHeader">
                        {flagFilterText}
                        <span>
                          <i
                            className="fa fa-times-circle  ml-2"
                            data-type="flag_type"
                            onClick={removeTag}
                          ></i>
                        </span>
                      </div>
                    )}
                  </div>
                  <span className="dk-totalCvCount">
                    {flagList.reduce(function (tot, arr) {
                      return tot + arr.count;
                    }, 0)}
                  </span>
                </div>
                <li
                  data-type="flag_type"
                  data-text="Blank"
                  data-value="1"
                  onClick={selectFilter}
                >
                  <div
                    data-type="flag_type"
                    data-text="Blank"
                    data-value="1"
                    onClick={selectFilter}
                  >
                    <FaUserAlt
                      style={{
                        color: "green",
                        fontSize: "16px",
                        marginRight: "4px",
                      }}
                    />{" "}
                    Motivated
                  </div>
                  <span
                    className="mlCount"
                    data-type="flag_type"
                    data-text="Blank"
                    data-value="1"
                    onClick={selectFilter}
                  >
                    {flagList
                      .filter((items) => items.name === 1)
                      .map((item, index) => (
                        <span
                          className="recNoCount"
                          data-type="flag_type"
                          data-text="Blank"
                          data-value="1"
                          onClick={selectFilter}
                        >
                          {item.count}
                        </span>
                      ))}
                  </span>
                </li>
                <li
                  data-type="flag_type"
                  data-text="Red"
                  data-value="3"
                  onClick={selectFilter}
                >
                  <div
                    data-type="flag_type"
                    data-text="Red"
                    data-value="3"
                    onClick={selectFilter}
                  >
                    <FaUserAlt
                      style={{
                        color: "#d58b5d",
                        fontSize: "16px",
                        marginRight: "4px",
                      }}
                    />{" "}
                    Doubtful
                  </div>
                  <span
                    className="mlCount"
                    data-type="flag_type"
                    data-text="Red"
                    data-value="3"
                    onClick={selectFilter}
                  >
                    {flagList
                      .filter((items) => items.name === 3)
                      .map((item, index) => (
                        <span
                          className="recNoCount"
                          data-type="flag_type"
                          data-text="Red"
                          data-value="3"
                          onClick={selectFilter}
                        >
                          {item.count}
                        </span>
                      ))}
                  </span>
                </li>
                <li
                  data-type="flag_type"
                  data-text="Green"
                  data-value="2"
                  onClick={selectFilter}
                >
                  <div
                    data-type="flag_type"
                    data-text="Green"
                    data-value="2"
                    onClick={selectFilter}
                  >
                    <FaUserAlt
                      style={{
                        color: "#d60101",
                        fontSize: "16px",
                        marginRight: "4px",
                      }}
                    />{" "}
                    Unmotivated
                  </div>
                  <span
                    className="mlCount"
                    data-type="flag_type"
                    data-text="Green"
                    data-value="2"
                    onClick={selectFilter}
                  >
                    {flagList
                      .filter((items) => items.name === 2)
                      .map((item, index) => (
                        <span
                          className="recNoCount"
                          data-type="flag_type"
                          data-text="Green"
                          data-value="2"
                          onClick={selectFilter}
                        >
                          {item.count}
                        </span>
                      ))}
                  </span>
                </li>
                <li>
                  <div>
                    <FaUserAlt
                      style={{
                        color: "#d3d3d3",
                        fontSize: "16px",
                        marginRight: "4px",
                      }}
                    />{" "}
                    Blank
                  </div>
                  <span className="mlCount">
                    {flagList
                      .filter((items) => items.name === 2)
                      .map((item, index) => (
                        <span className="recNoCount">{item.count}</span>
                      ))}
                  </span>
                </li>
              </div>
              <div className="dk-summaryCols xl dk-listing">
                <div
                  className={`${
                    !isGenderFilter ? "dk-toptitle" : "dk-toptitle-filterBg"
                  }`}
                >
                  <div className="dk-dbsuMM-filterHeader">
                    {!isGenderFilter ? (
                      <div className="dk-colstitle">INTERVIEW</div>
                    ) : (
                      <div className="dk-dbsuMM-filterHeader">
                        {genderFilterText}
                        <span>
                          <i
                            className="fa fa-times-circle  ml-2"
                            data-type="gender"
                            onClick={removeTag}
                          ></i>
                        </span>
                      </div>
                    )}
                  </div>
                  <span className="dk-totalCvCount">
                    {genderList.reduce(function (tot, arr) {
                      return tot + arr.count;
                    }, 0)}
                  </span>
                </div>
                <li>
                  <div>
                    <FaFlag
                      style={{
                        color: "green",
                        fontSize: "16px",
                        marginRight: "4px",
                      }}
                    />{" "}
                    On Time
                  </div>
                  <span className="mlCount">66</span>
                </li>
                <li>
                  <div>
                    <FaFlag
                      style={{
                        color: "#d58b5d",
                        fontSize: "16px",
                        marginRight: "4px",
                      }}
                    />{" "}
                    Delayed
                  </div>
                  <span className="mlCount">89</span>
                </li>
                <li>
                  <div>
                    <FaFlag
                      style={{
                        color: "#d60101",
                        fontSize: "16px",
                        marginRight: "4px",
                      }}
                    />{" "}
                    Stopped
                  </div>
                  <span className="mlCount">87</span>
                </li>
                <li>
                  <div>
                    <FaFlag
                      style={{
                        color: "#d3d3d3",
                        fontSize: "16px",
                        marginRight: "4px",
                      }}
                    />{" "}
                    Blank
                  </div>
                  <span className="mlCount">34</span>
                </li>
              </div>
              <div className="dk-summaryCols xl dk-listing">
                <div
                  className={`${
                    !isProfileFilter ? "dk-toptitle" : "dk-toptitle-filterBg"
                  }`}
                >
                  <div className="dk-dbsuMM-filterHeader">
                    {!isProfileFilter ? (
                      <div className="dk-colstitle">Gender</div>
                    ) : (
                      <div className="dk-dbsuMM-filterHeader">
                        {profileFilterText}
                        <span>
                          <i
                            className="fa fa-times-circle  ml-2"
                            data-type="profileIndicator"
                            onClick={removeTag}
                          ></i>
                        </span>
                      </div>
                    )}
                  </div>
                  <span className="dk-totalCvCount">
                    {profileIndicatorList.reduce(function (tot, arr) {
                      return tot + arr.count;
                    }, 0)}
                  </span>
                </div>
                <li
                  data-type="profileIndicator"
                  data-value="Updated"
                  onClick={selectFilter}
                >
                  <div
                    data-type="profileIndicator"
                    data-text="Green"
                    data-value="Updated"
                    onClick={selectFilter}
                  >
                    {/* <i className="fas mr-2 fa-circle" data-type="profileIndicator" data-text="Green" data-value="Updated" onClick={selectFilter} style={{ color: "#008000" }}></i>Updated */}
                    <FaCircle
                      style={{
                        color: "Green",
                        fontSize: "16px",
                        marginRight: "4px",
                      }}
                    />{" "}
                    Updated
                  </div>
                  <span
                    className="mlCount"
                    data-type="profileIndicator"
                    data-text="Green"
                    data-value="Updated"
                    onClick={selectFilter}
                  >
                    {profileIndicatorList
                      .filter((items) => items.name === 4)
                      .map((item, index) => (
                        <span
                          className="recNoCount"
                          data-type="profileIndicator"
                          data-text="Green"
                          data-value="Updated"
                          onClick={selectFilter}
                        >
                          {item.count}
                        </span>
                      ))}
                    0
                  </span>
                </li>
                <li
                  data-type="profileIndicator"
                  data-value="Not Updated"
                  onClick={selectFilter}
                >
                  <div
                    data-type="profileIndicator"
                    data-text="Red"
                    data-value="Not Updated"
                    onClick={selectFilter}
                  >
                    <FaCircle
                      style={{
                        color: "#d3d3d3",
                        fontSize: "16px",
                        marginRight: "4px",
                      }}
                    />
                    Not Updated
                  </div>
                  <span
                    className="mlCount"
                    data-type="profileIndicator"
                    data-text="Red"
                    data-value="Not Updated"
                    onClick={selectFilter}
                  >
                    {profileIndicatorList
                      .filter((items) => items.name === 3)
                      .map((item, index) => (
                        <span
                          className="recNoCount"
                          data-type="profileIndicator"
                          data-text="Red"
                          data-value="Not Updated"
                          onClick={selectFilter}
                        >
                          {item.count}
                        </span>
                      ))}
                    0
                  </span>
                </li>
              </div>
              <div className="dk-summaryCols xl dk-listing dk-dbsrch-last-row d-none">
                <div
                  className={`${
                    !isLocalityFilter ? "dk-toptitle" : "dk-toptitle-filterBg"
                  }`}
                >
                  {!isLocalityFilter ? (
                    <div className="dk-colstitle">LOCALITY</div>
                  ) : (
                    <div className="dk-dbsuMM-filterHeader">
                      {localityFilterText}
                      <span>
                        <i
                          className="fa fa-times-circle  ml-2"
                          data-type="locality"
                          onClick={removeTag}
                        ></i>
                      </span>
                    </div>
                  )}
                  <span className="dk-totalCvCount">
                    {jobLocalityList.reduce(
                      (total, currentValue) =>
                        (total = total + currentValue.count),
                      0
                    )}
                  </span>
                </div>
                <div className="dk-summaryList">
                  {jobLocalityList.map((item, index) => (
                    <li
                      key={`locality${index}`}
                      data-type="locality"
                      data-value={item.jobDetail.locality}
                      onClick={selectFilter}
                    >
                      {item.jobDetail.locality !== ""
                        ? item.jobDetail.locality
                        : "Blank"}
                      <span
                        data-type="locality"
                        data-value={item.jobDetail.locality}
                        onClick={selectFilter}
                      >
                        {item.count}
                      </span>
                    </li>
                  ))}
                </div>
              </div>
              <div className="dk-summaryCols xl dk-listing dk-dbsrch-last-row d-none">
                <div
                  className={`${
                    !isProjectFilter ? "dk-toptitle" : "dk-toptitle-filterBg"
                  }`}
                >
                  {!isProjectFilter ? (
                    <div className="dk-colstitle">PROJECT</div>
                  ) : (
                    <div className="dk-dbsuMM-filterHeader">
                      {projectFilterText}
                      <span>
                        <i
                          className="fa fa-times-circle  ml-2"
                          data-type="project"
                          onClick={removeTag}
                        ></i>
                      </span>
                    </div>
                  )}
                  <span className="dk-totalCvCount">
                    {jobProjectList.reduce(
                      (total, currentValue) =>
                        (total = total + currentValue.count),
                      0
                    )}
                  </span>
                </div>
                <div className="dk-summaryList">
                  {jobProjectList.map((item, index) => (
                    <li
                      key={`project${index}`}
                      data-type="project"
                      data-value={item.jobDetail.project}
                      onClick={selectFilter}
                    >
                      {item.jobDetail.project !== ""
                        ? item.jobDetail.project
                        : "Blank"}
                      <span
                        data-type="project"
                        data-value={item.jobDetail.project}
                        onClick={selectFilter}
                      >
                        {item.count}
                      </span>
                    </li>
                  ))}
                </div>
              </div>
              {/* <div className="dk-summaryCols xl dk-listing dk-dbsrch-last-row dk-opacity-none"></div>
                                        <div className="dk-summaryCols xl dk-listing dk-dbsrch-last-row dk-opacity-none"></div> */}
            </div>
          </div>
        </div>
        {/* </Tab.Pane> */}
      </div>
    </>
  );
};

export default DBSearchcv;
