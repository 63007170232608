import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./my-referals.scss";
import MoreInfoPopover from "./MoreInfoPopover";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { FaFileInvoice, FaEuroSign, FaHandshake } from "react-icons/fa";
import {
  getCMS,
  // updatecontent,
  // clearState,
} from '../../../../../../../slice/cmsSlice'
// import AddNewReferrals from "./modal/AddNewReferrals";
const MyServices = () => {
  //   const [isAddNewReferral, setIsAddNewReferral] = useState(false);

  //   const showreferralModal = () => {
  //     setIsAddNewReferral(true);
  //   };

  //   const hidereferralModal = () => {
  //     setIsAddNewReferral(false);
  //   };
  //  const { userInfo } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { isSuccess, cmsList } = useSelector((state) => state.cmsdata);
  const [details, setDetails] = useState("");
  useEffect(() => {
    dispatch(getCMS({}));
  }, [dispatch]);

  useEffect(() => {
    if (!isSuccess && cmsList.length > 0) {
      setDetails(cmsList[50].description);
    }
  }, [isSuccess]);

  const cardItems= [1,2,3,4]

  return (
    <>
      <div className="dkg-cand-myreferals-mainCon">
        <div className="dkg-cand-myreferals-header">
          <div className="dkg-cand-myreferals-headerleftCon">
            <div className="dk-mailshotsSearch mr-2">
              <div className="input-group">
                <input
                  className="form-control"
                  placeholder="Search ..."
                  value=""
                />
                <div className="input-group-append">
                  <button className="btn btn-secondary" type="button">
                    <i className="fa fa-search"></i>
                  </button>
                </div>
              </div>
              <div className="dkg-crossicon-Con d-none">
                <i className="fas fa-times-circle" data-type="search text"></i>
              </div>
            </div>
            {/* <div className="dkg-referal-addnew-btnCon">
              <button
                className="dkg-referal-addnew-btn"
                onClick={showreferralModal}
              >
                <i className="fas fa-plus"></i>
              </button>
  </div> */}
          </div>
          <div className="dkg-cand-myreferals-centreCon">
            <div className="dkg-docs-title">
            {/* Message History <MoreInfoPopover details={details} /> */}
            Message History <span className="dkg-message-number-badge">
                <span className="dkg-number-counter">0</span>
            </span>
            </div>
          </div>
          <div className="dkg-cand-myreferals-rightCon">
            <Dropdown className="dkg-referal-status-drpdwn">
              <Dropdown.Toggle
                variant=""
                id=""
                className="dkg-referal-status-drpdwn-toggle"
              >
                Email From
              </Dropdown.Toggle>
              <Dropdown.Menu className="dkg-referal-status-drpdwn-menu">
                <Dropdown.Item
                  href="#"
                  className="dkg-referal-status-item"
                  style={{ backgroundColor: "#3a9469", color: "#fff" }}
                >
                  Open
                  <span className="dkg-lv-statuscounter">4</span>
                </Dropdown.Item>
                <Dropdown.Item
                  href="#"
                  className="dkg-referal-status-item"
                  style={{ backgroundColor: "#dc3545", color: "#fff" }}
                >
                  Closed
                  <span className="dkg-lv-statuscounter">6</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="dkg-msg-history-con">
          <div className="dkg-msg-history-card-con">
            {
              cardItems.map((item, index) => (
                <div className="dkg-msg-history-card" key={item}>
                <div className="dkg-msg-history-leftcon">
                  <h2 className="dkg-msg-history-title">Candadite Name</h2>
                  <h5 className="dkg-msg-history-time">Friday March 28 2025 </h5>
                  <h5 className="dkg-msg-history-time">Friday March 28 2025 </h5>
                </div>
                <div className="dkg-msg-history-rightcon">
                  <div className="dkg-msg-history-header">
                    <div className="dkg-msg-history-drop-con">
                        <Dropdown className="dkg-translate-drpdwn">
                          <Dropdown.Toggle variant="" id="" className="dkg-translate-drpdwn-toggle">Translate</Dropdown.Toggle>
                          <Dropdown.Menu className="dkg-translate-drpdwn-menu">
                            <Dropdown.Item href="#"className="dkg-translate-item">Open</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                  <div className="dkg-msg-history-content">
                    <p className="dkg-msg-history-para">Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium totam vitae perspiciatis dolorem, corrupti culpa ipsum illo aperiam quas. Quaerat magnam numquam odio repellat incidunt?</p>
                    <Link to="#" className="dkg-msg-history-link">https://dkglobalcrm.com/</Link>
                    <p className="dkg-msg-history-para">Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium totam vitae perspiciatis dolorem, corrupti culpa ipsum illo aperiam quas. Quaerat magnam numquam odio repellat incidunt?</p>
                    <p className="dkg-msg-history-para">Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium totam vitae perspiciatis dolorem, corrupti culpa ipsum illo aperiam quas. Quaerat magnam numquam odio repellat incidunt?</p>
                    <p className="dkg-msg-history-para">Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium totam vitae perspiciatis dolorem, corrupti culpa ipsum illo aperiam quas. Quaerat magnam numquam odio repellat incidunt?</p>
  
                  </div>
                </div>
                </div>
              ))
            }
           
          </div>
        </div>
      </div>
    </>
  );
};

export default MyServices;
