import React from 'react';
import Header from "../../elements/header";
import Navbar from '../../elements/header/navbar/mainMenu/dkglobaladmin/SupplierMenu';

const AdminSupplierLayout = ({ children }) => {
    return (
        <React.Fragment>
            <Header mainheaderLink="Dahsboard -" moduleLink="/dashboard/plateform" linkcolor="#3c4c62" mainheaderTitle="SUPPLIERS" textcolor="#01796f" />
            <Navbar />
            {children}
        </React.Fragment>
    )
}
export default AdminSupplierLayout;
