import React from 'react';
import './search-db.scss'

const index = () => {
    return (
        <>
            <div className="dkg-search-db-mainPage-57">
                <div className="d-flex">
                    <div className="dk-search-db-Cols">
                        <div className="d-flex dk-bankingBox-left-two-cols">
                            <div className="dk-search-db-col">
                                <div className="sbtitle">Year</div>
                                <div className="dk-search-db-columns-panel" style={{ minHeight: '250px' }}></div>
                            </div>
                            <div className="dk-search-db-col">
                                <div className="sbtitle">STATUS</div>
                                <div className="dk-search-db-columns-panel" style={{ minHeight: '250px' }}></div>
                            </div>
                            <div className="dk-search-db-col">
                                <div className="sbtitle">CATEGORY</div>
                                <div className="dk-search-db-columns-panel" style={{ minHeight: '250px' }}></div>
                            </div>
                        </div>
                        <div className="d-flex dk-bankingBox-left-two-cols">
                            <div className="dk-search-db-col">
                                <div className="sbtitle">Month</div>
                                <div className="dk-search-db-columns-panel" style={{ minHeight: '250px' }}></div>
                            </div>
                            <div className="dk-search-db-col">
                                <div className="sbtitle">stage</div>
                                <div className="dk-search-db-columns-panel" style={{ minHeight: '250px' }}></div>
                            </div>
                            <div className="dk-search-db-col">
                                <div className="sbtitle">Pipeline</div>
                                <div className="dk-search-db-columns-panel" style={{ minHeight: '250px' }}></div>
                            </div>
                        </div>
                    </div>
                    <div className="dk-search-db-Cols">
                        <div className="dk-search-db-columns">
                            <div className="sbtitle">Nationality</div>
                            <div className="dk-search-db-columns-panel" style={{ minHeight: '573px' }}></div>
                        </div>
                    </div>
                    <div className="dk-search-db-Cols">
                        <div className="dk-search-db-columns">
                            <div className="sbtitle">Language</div>
                            <div className="dk-search-db-columns-panel" style={{ minHeight: '573px' }}></div>
                        </div>
                    </div>
                    <div className="dk-search-db-Cols">
                        <div className="dk-search-db-columns">
                            <div className="sbtitle">CV Source</div>
                            <div className="dk-search-db-columns-panel" style={{ minHeight: '573px' }}></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default index;
