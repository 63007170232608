import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPaperclip,
  faXmark,
  faPaperPlane,
  faSignature,
  faFloppyDisk,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import IconButton from "../../../../mainmenu/mailbox/utils/button/IconSubmitButton";
import { sendMail, clearState } from "../../../../../../slice/utils/message/messageSlice";
import {
  showSuccess,
  showError,
  clearMessage,
} from "../../../../../../slice/utils/message/messageSlice";

import { useDispatch, useSelector } from "react-redux";
import { Form, Row,Dropdown ,Tab,Nav } from "react-bootstrap";
import { Autocomplete, TextField } from '@mui/material';
import {GetData} from "../../../../../../services";
import {createFilterOptions} from "@material-ui/lab";
import AttachmentFileModal from './attachment/AttachmentFileModal'

const BulkEmail = (props) => {
  const dispatch = useDispatch();
  const { isLoading, isBtnSuccess } = useSelector((state) => state.mailbox);
  const { userInfo } = useSelector((state) => state.auth);
  const fileInput = useRef();

  const [mailList, setMailList] = useState([]);
  const [from, setFrom] = useState("jobs@dkgrecruitment.com");
  const [to, setTo] = useState([]);
  const [cc, setCc] = useState([]);
  const [bcc, setBCc] = useState([]);
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [isAttachment, setIsAttachment] = useState(1);
  const [attachment, setAttachment] = useState([]);
  const [displayFileAttachment, setDisplayFileAttachment] = useState([]);

  // const [loading, setLoading] = useState(false);
  // const [success, setSuccess] = useState(false);
  const [fileName, setFileName] = useState("");
  const [currentAccount, setCurrentAccount] = useState("Form");

  const filterOptions = createFilterOptions({
    limit: 30,
  });

  useEffect(() => {
    if (props.show) {
      clearForm();
      getMailList()
    }
  }, [props.show]);

  useEffect(() => {
    if (props.currentAccount) {
      setCurrentAccount(props.currentAccount)
    }
  }, [props.currentAccount]);

  const getMailList = async () => {
    try {
      const res = await GetData(true, '/mail/candidates/list');
      const mailList = res.payload.result
      if (mailList.length) {
        setMailList(mailList.map(item => item.email1));
      }
    } catch (e) {
      console.error(e);
    }
  }

  const fileUploadHandler = (e) => {
    for (const item of e.target.files) {
      attachment.push(item);
    }
    if (attachment.length > 1) {
      setFileName(attachment.length + " files");
    } else {
      setFileName(e.target.files[0].name);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (currentAccount) {
      const formData = new FormData();
      // formData.append("uid", userInfo.id);
      formData.append("from", currentAccount.id);
      // formData.append("from", from);
      to.forEach(item => {
        formData.append("to[]", item);
      })
      cc.forEach(item => {
        formData.append("cc[]", item);
      })
      bcc.forEach(item => {
        formData.append("bcc[]", item);
      })
      formData.append("subject", subject);
      formData.append("html", body);
      // console.log(attachment.length);
      if (attachment.length > 0) {
        formData.append("isAttachment", 1);
        for (let i = 0; i < attachment.length; i++) {
          formData.append("attachments[]", attachment[i]);
        }
      }

      // setLoading(true);
      //await dispatch(sendMail({ from, to, cc, bcc, subject, body }));
      // await dispatch(sendMail(formData));
      // setLoading(false);
      props.onHide();
    }
  };

  const clearForm = () => {
    // setLoading(false);
    setFileName("");
    setTo([]);
    setCc([]);
    setBCc([]);
    setSubject("");
    setBody("");
    // fileInput.current.value = ''
    setIsAttachment(0);
    setAttachment([]);
    setDisplayFileAttachment([]);
  };

  const deleteImg = () => {
    setFileName("");
    fileInput.current.value = "";
    setIsAttachment(0);
    setAttachment([]);
    setDisplayFileAttachment([]);
  };

  useEffect(() => {
    if (isBtnSuccess) {
      // setLoading(false);
      setFileName("");
      setTo([]);
      setCc([]);
      setBCc([]);
      setSubject("");
      setBody("");
      // fileInput.current.value = ''
      setIsAttachment(0);
      setAttachment([]);
      setDisplayFileAttachment([]);
      // dispatch(clearState());
    }
  }, [isBtnSuccess]);

  const addFrom = (data) => {
    setFrom(data);
  };
    const[isAttachmentModal , setIsAttachmentModal] = useState(false);
    const showAttachmentModal = () => {
      setIsAttachmentModal(true);
    };
    const hideAttachmentModal = () => {
      setIsAttachmentModal(false);
    };
  

  return (
    <>
           {
            isAttachmentModal ? <AttachmentFileModal title={fileName}
            show={showAttachmentModal}
            onHide={hideAttachmentModal}
            /> : null
          }
      <Modal className="dk-composeMailModal right" {...props}>
        <Modal.Header closeButton>
          {/* <button className="dk-saveBtn" title="Save">
            <FontAwesomeIcon icon={faFloppyDisk} />
          </button> */}
          <Modal.Title>COMPOSE NEW EMAIL</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tab.Container id="left-tabs-example" defaultActiveKey="tabs_1">
            <div className="d-flex dkg-compose-email-block">
              <div className="dk-mailboxLeft-panel dkg-compose-bottom-panel dkg-bluk-compose-panel">
                <Nav variant=""
                    className="dkg-compose-email-navpills"
                >
                  <Nav.Item  className="dkg-compose-email-navitem">
                    <Nav.Link eventKey="tabs_1"
                     className="dkg-compose-email-navlink"
                    >New Email</Nav.Link>
                  </Nav.Item>
                  <Nav.Item  className="dkg-compose-email-navitem">
                    <Nav.Link eventKey="tabs_2"
                     className="dkg-compose-email-navlink"
                    >Mailshot</Nav.Link>
                  </Nav.Item>
                  <Nav.Item  className="dkg-compose-email-navitem">
                    <Nav.Link eventKey="tabs_3"
                     className="dkg-compose-email-navlink"
                    >Templates</Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
              <div className="dk-mailboxRight-panel dk-bluk-mailboxRight-panel">
                <Tab.Content>
                  <Tab.Pane eventKey="tabs_1">
                    <div className="dk-composeMailForm">
                      <form
                        onSubmit={handleSubmit}
                        enctype="multipart/form-data"
                      >
                        <div className="d-flex">
                          <div className="dk-mailField">
                            <div className="dk-mailSendOption">
                              <div className="dk-mailOption-items">From</div>
                              <input
                                type="text"
                                value={currentAccount?.domain}
                                className="form-control readonly"
                              />
                              {/* <div className="dk-attachFile-cols">
                                {
                                                                    displayFileAttachment.map((item, index) =>
                                                                        <div className="attachItems" key={index}>{item.name} <span><FontAwesomeIcon icon={faXmark} /></span></div>
                                                                    )
                                                                }
                              </div> */}
                               <div className="dk-attachFile-cols dkg-attachment-dropdwn-con">
                                  <Link to="#" className="dkg-attachment-dropdwn">
                                    <FontAwesomeIcon icon={faPaperclip} className="mr-2" onClick={showAttachmentModal} />View Attached File</Link>
                                </div>
                              <div className="dk-composeMail-option d-none">
                                <div className="d-flex align-items-center justify-content-end">
                                  <div className="dk-composeMail-PanelCols">
                                    <div className="dk-mailAttach-file">
                                      {/* <input type="file" name="" id="" name="attachment-files" onChange={fileUploadHandler} ref={fileInput} multiple /> */}
                                      <span>
                                        <FontAwesomeIcon icon={faPaperclip} />
                                      </span>
                                      <Form.File
                                        type="file"
                                        className="custom-file-label"
                                        ref={fileInput}
                                        id="inputGroupFile01"
                                        label={fileName}
                                        onClick={(e) => setAttachment([])}
                                        onChange={fileUploadHandler}
                                        multiple
                                        custom
                                      />
                                      {/* <span><FontAwesomeIcon icon={faPaperclip} /> Attach File</span> */}
                                    </div>
                                  </div>
                                  <div className="dk-composeMail-PanelCols d-none">
                                    <div className="dk-mailAttach-file">
                                      <Link to="#" onClick={deleteImg} className="d-none">
                                        <FontAwesomeIcon icon={faTrashAlt} />{" "}
                                        Delete
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="dk-mailSendOption">
                              <div className="dk-mailOption-items">To...</div>
                              {/*<input*/}
                              {/*  type="text"*/}
                              {/*  className="form-control"*/}
                              {/*  value={to}*/}
                              {/*  onChange={(e) => setTo(e.target.value)}*/}
                              {/*/>*/}
                              <Autocomplete
                                  disablePortal
                                  freeSolo
                                  multiple
                                  getOptionLabel={(option) => option}
                                  className="form-control dk-autocomplete-input m-lb-5x"
                                  options={mailList}
                                  filterOptions={filterOptions}
                                  onChange={(event, selectedList) => {
                                    setTo(selectedList)
                                  }}
                                  renderInput={(params) => <TextField {...params} placeholder="To" />}
                              />
                              {/* <div className="dk-mailsTagMain">
                                                                <div className="dk-mailtags">mukul@gmail.com
                                                                    <span className="dk-mailTag-remove"><FontAwesomeIcon icon={faXmark} /></span>
                                                                </div>
                                                                <div className="dk-mailtags">deepak@dkmassterbox.com
                                                                    <span className="dk-mailTag-remove"><FontAwesomeIcon icon={faXmark} /></span>
                                                                </div>
                                                            </div> */}
                            </div>
                            <div className="dk-mailSendOption">
                              <div className="dk-mailOption-items">Cc...</div>
                              {/*<input*/}
                              {/*  type="text"*/}
                              {/*  className="form-control"*/}
                              {/*  value={cc}*/}
                              {/*  onChange={(e) => setCc(e.target.value)}*/}
                              {/*/>*/}
                              <Autocomplete
                                  disablePortal
                                  multiple
                                  freeSolo
                                  getOptionLabel={(option) => option}
                                  className="form-control dk-autocomplete-input m-lb-5x"
                                  options={mailList}
                                  filterOptions={filterOptions}
                                  onChange={(event, selectedList) => {
                                    setCc(selectedList)
                                  }}
                                  renderInput={(params) => <TextField {...params} placeholder="Cc" />}
                              />
                              {/* <div className="dk-mailsTagMain">
                                                                <div className="dk-mailtags">mukul@gmail.com
                                                                    <span className="dk-mailTag-remove"><FontAwesomeIcon icon={faXmark} /></span>
                                                                </div>
                                                                <div className="dk-mailtags">deepak@dkmassterbox.com
                                                                    <span className="dk-mailTag-remove"><FontAwesomeIcon icon={faXmark} /></span>
                                                                </div>
                                                            </div> */}
                            </div>
                            <div className="dk-mailSendOption">
                              <div className="dk-mailOption-items">Bcc...</div>
                              {/*<input*/}
                              {/*  type="text"*/}
                              {/*  className="form-control"*/}
                              {/*  onChange={(e) => setBCc(e.target.value)}*/}
                              {/*/>*/}
                              <Autocomplete
                                  disablePortal
                                  multiple
                                  freeSolo
                                  getOptionLabel={(option) => option}
                                  className="form-control dk-autocomplete-input m-lb-5x"
                                  options={mailList}
                                  filterOptions={filterOptions}
                                  onChange={(event, selectedList) => {
                                    setBCc(selectedList)
                                  }}
                                  renderInput={(params) => <TextField {...params} placeholder="Bcc" />}
                              />
                              {/* <div className="dk-mailsTagMain">
                                                                <div className="dk-mailtags">mukul@gmail.com
                                                                    <span className="dk-mailTag-remove"><FontAwesomeIcon icon={faXmark} /></span>
                                                                </div>
                                                                <div className="dk-mailtags">deepak@dkmassterbox.com
                                                                    <span className="dk-mailTag-remove"><FontAwesomeIcon icon={faXmark} /></span>
                                                                </div>
                                                            </div> */}
                            </div>
                            <div className="dk-mailSendOption">
                              <div className="dk-mailOption-items br-none">
                                Subject
                              </div>
                              <div className="d-flex w-100" style={{paddingLeft: "5px"}}>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(e) => setSubject(e.target.value)}
                                />
                                <div className="dk-mailsCategory">
                                  <Dropdown>
                                    <Dropdown.Toggle
                                      variant=""
                                      id="dropdown-basic"
                                    >
                                      Email Category
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <Dropdown.Item href="#/action-1">
                                        Category 1
                                      </Dropdown.Item>
                                      <Dropdown.Item href="#/action-2">
                                        Category 2
                                      </Dropdown.Item>
                                      <Dropdown.Item href="#/action-3">
                                        Category 3
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div className="dk-sendMail-btn">
                                                        <IconButton txt="Send" loading={loading} success={success} onClick={handleSubmit} position="justify-content-center" />
                                                    </div> */}
                        </div>

                        <div className="dk-textCompose-mail">
                          <div className="dk-mailAttach-file d-none">
                            {/* <Link to="#"><FontAwesomeIcon icon={faSignature} /> Add Signature</Link> */}
                            <Dropdown>
                              <Dropdown.Toggle variant="" id="dropdown-basic">
                                <FontAwesomeIcon icon={faSignature} /> Add
                                Signature
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">
                                  DK Global
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-2">
                                  Deepak Kumar
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-3">
                                  DK Masterbox
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                          <CKEditor
                            editor={ClassicEditor}
                            data=""
                            onReady={(editor) => {
                              // You can store the "editor" and use when it's needed.
                              console.log("Editor is ready to use!", editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setBody(data);
                            }}
                          />
                        </div>

                        <div className="dk-composeMail-option">
                          <div className="d-flex align-items-center justify-content-between">
                            {/* <div className="dk-composeMail-PanelCols">
                                                            <div className="dk-mailAttach-file">
                                                                <span><FontAwesomeIcon icon={faPaperclip} /></span>
                                                                <Form.File
                                                                    type="file"
                                                                    className="custom-file-label"
                                                                    id="inputGroupFile01"
                                                                    label={fileName}
                                                                    onChange={(e) => setFileName(e.target.files[0].name)}
                                                                    custom
                                                                />
                                                                
                                                            </div>

                                                        </div> */}
                            <div className="dk-composeMail-PanelCols">
                              <div className="dk-mailAttach-file dk-sendBtn">
                                <FontAwesomeIcon icon={faPaperPlane} />
                                <IconButton
                                  txt="Send Email"
                                  loading={isLoading}
                                  success={isBtnSuccess}
                                  position="justify-content-center"
                                />
                              </div>
                              {/* <div className="dk-mailAttach-file">
                                                                <Link to="#"><FontAwesomeIcon icon={faTrashAlt} onClick={clearForm} /> Delete</Link>
                                                            </div> */}
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="tabs_2"></Tab.Pane>
                  <Tab.Pane eventKey="tabs_2"></Tab.Pane>
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BulkEmail;
