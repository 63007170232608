import React from 'react';
import { Link } from 'react-router-dom'
import './main-cards.scss'
import ManagerLogin from '../../../assets/images/clients.png'
import RecruiterLogin from '../../../assets/images/managers.png'
import PartnersLogin from '../../../assets/images/partners.png'
import ClientsLogin from '../../../assets/images/recruiter-market.png'
import SuppliersLogin from '../../../assets/images/recruiter.png'
// import SuppliersLogin from '../../../assets/images/suppliers.png'
import CandidateeLogin from '../../../assets/images/candidatee.png'
import Button from 'react-bootstrap/Button'
import LoginHeader from '../../elements/header/LoginHeader'



const LoginComp = () => {

    return (
        <React.Fragment>
            <LoginHeader headerTitle="DK GLOBAL RECRUITMENT HUB" textcolor="#3c97b6" />
            <div className='dkg-crm-main-pageCon'>
                <div className="dkg-crm-main-contentCon">
                    <div className="dkg-crm-card-container">
                       
                        <div className="dkg-crm-main-card">
                            <div className="dkg-usertype-image-crop">
                                <img src={RecruiterLogin} className='dkg-usertype-image' alt="Account Manager" />
                            </div>
                            <div className="dkg-acc-type-tabcon">
                                <Link to="/recruters/login" className="dkg-acc-type-tabname">
                                   INHOUSE RECRUITER
                                </Link>
                            </div>
                        </div>
                        <div className="dkg-crm-main-card">
                            <div className="dkg-usertype-image-crop">
                                <img src={PartnersLogin} className='dkg-usertype-image' alt="PARTNER RECRUITER" />
                            </div>
                            <div className="dkg-acc-type-tabcon">
                                <Button href={"https://partner.dkglobalcrm.com"} className="dkg-acc-type-tabname">
                                PARTNER RECRUITER
                                </Button>
                            </div>
                        </div>
                        <div className="dkg-crm-main-card">
                            <div className="dkg-usertype-image-crop">
                                <img src={SuppliersLogin} className='dkg-usertype-image' alt="Account Manager" />
                            </div>
                            <div className="dkg-acc-type-tabcon">
                                <Button href={"https://www.freelance.dkglobalcrm.com/"} className="dkg-acc-type-tabname">
                                FREELANCE RECRUITER
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="dkg-crm-card-container">
                        <div className="dkg-crm-main-card">
                            <div className="dkg-usertype-image-crop">
                                <img src={ManagerLogin} className='dkg-usertype-image' alt="RECRUITMENT PARTNER" />
                            </div>
                            <div className="dkg-acc-type-tabcon">
                                <Button href={"https://dkglobaloperations.dkglobalcrm.com/"} className="dkg-acc-type-tabname">
                                RECRUITMENT PARTNER
                                </Button>
                            </div>
                        </div>
                        <div className="dkg-crm-main-card">
                            <div className="dkg-usertype-image-crop">
                                <img src={ClientsLogin} className='dkg-usertype-image' alt="Clients Manager" />
                            </div>
                            <div className="dkg-acc-type-tabcon">
                                <Button href={"http://recruitersmarket.dkglobalcrm.com/"} className="dkg-acc-type-tabname">
                                RESUME MARKETPLACE
                                </Button>
                            </div>
                        </div>
                        <div className="dkg-crm-main-card">
                            <div className="dkg-usertype-image-crop">
                                <img src={CandidateeLogin} className='dkg-usertype-image' alt="Account Manager" />
                            </div>
                            <div className="dkg-acc-type-tabcon">
                                <Button href={"https://jobseeker.dkglobalcrm.com/"} className="dkg-acc-type-tabname">
                                  JOBSEEKER ACCOUNT
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dkg-crm-main-mo-contentCon">
                    <div className="dkg-mo-headerTitle">DK GLOBAL RECRUITMENT HUB</div>
                    <div className="dkg-crm-mo-card-container">
                        <Button href={"https://management.dkglobalcrm.com"} className="dkg-crm-main-mo-card dkg-mangemement-bg mb-2" style={{ marginRight: "8px" }}>
                            <div className="dkg-usertype-image-crop">
                                <img src={ManagerLogin} className='dkg-usertype-image' alt="RECRUITMENT PARTNER" />
                            </div>
                            <div className="dkg-acc-type-tabcon">
                                <div href={"https://management.dkglobalcrm.com"} className="dkg-acc-type-tabname">
                                RECRUITMENT<span className='dkg-mo-box-btmtitle'>PARTNER</span>
                                </div>
                            </div>
                        </Button>
                        <Link to="/recruters/login" className="dkg-crm-main-mo-card dkg-recruiter-bg mb-2" style={{ marginLeft: "7px" }}>
                            <div className="dkg-usertype-image-crop">
                                <img src={RecruiterLogin} className='dkg-usertype-image' alt="Account Manager" />
                            </div>
                            <div className="dkg-acc-type-tabcon">
                                <div className="dkg-acc-type-tabname">
                                   INHOUSE <span className='dkg-mo-box-btmtitle'>RECRUITER</span>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="dkg-crm-mo-card-container">
                        <Button href={"https://supplier.dkglobalcrm.com"} className="dkg-crm-main-mo-card dkg-supplier-bg mt-2" style={{ marginRight: "8px" }}>
                            <div className="dkg-usertype-image-crop">
                                <img src={SuppliersLogin} className='dkg-usertype-image' alt="Account Manager" />
                            </div>
                            <div className="dkg-acc-type-tabcon">
                                <div className="dkg-acc-type-tabname">
                                    Freelance <span className='dkg-mo-box-btmtitle'>Recruiter</span>
                                </div>
                            </div>
                        </Button>
                        <Button href={"https://client.dkglobalcrm.com"} className="dkg-crm-main-mo-card  dkg-partner-bg  mt-2 mb-2" style={{ marginLeft: "7px" }}>
                            <div className="dkg-usertype-image-crop">
                                <img src={PartnersLogin} className='dkg-usertype-image' alt="Clients Manager" />
                            </div>
                            <div className="dkg-acc-type-tabcon">
                                <div className="dkg-acc-type-tabname">
                                Partner<span className='dkg-mo-box-btmtitle'>Recruiter</span>
                                </div>
                            </div>
                        </Button>
                    </div>
                    <div className="dkg-crm-mo-card-container">
                        <Button href={"https://partner.dkglobalcrm.com"} className="dkg-crm-main-mo-card dkg-client-bg mt-2"  style={{ marginRight: "8px" }}>
                            <div className="dkg-usertype-image-crop">
                                <img src={ClientsLogin} className='dkg-usertype-image' alt="Partners Manager" />
                            </div>
                            <div className="dkg-acc-type-tabcon">
                                <div className="dkg-acc-type-tabname">
                                Resume<span className='dkg-mo-box-btmtitle'>Marketplace</span>
                                </div>
                            </div>
                        </Button>
                        <Button href={"https://jobseeker.dkglobalcrm.com/"} className="dkg-crm-main-mo-card dkg-candidate-bg mt-2 mb-2" style={{ marginLeft: "7px" }}>
                            <div className="dkg-usertype-image-crop">
                                <img src={CandidateeLogin} className='dkg-usertype-image' alt="Account Manager" />
                            </div>
                            <div className="dkg-acc-type-tabcon">
                                <div className="dkg-acc-type-tabname">
                                    Jobseeker <span className='dkg-mo-box-btmtitle'>Account</span>
                                </div>
                            </div>
                        </Button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default LoginComp;