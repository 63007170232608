import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../../../../../../node_modules/froala-editor/css/froala_style.min.css";
import "../../../../../../../../node_modules/froala-editor/js/plugins.pkgd.min.js";
import "../../../../../../../../node_modules/froala-editor/css/froala_editor.pkgd.min.css";
///import FroalaEditorComponent from "react-froala-wysiwyg";
import SimpleDropDwn from "../../summary/SimpleDropDwn";
import { Tab, Nav, Dropdown } from "react-bootstrap";
import SubmitButton from "../../../../../../ui/submitButton";
import UserList from "../../../../../../ui/userList/userListByRole";
import UserSearchFilter from "../../summary/SearchInputDropDwn";
import JobStatus from "../../summary/JobStatus";
import {
  clearState,
  updateJob,
} from "../../../../../../../slice/jobs/jobsSlice";
import EditorComponents from "./Editor";
import DatePicker from "./DatePicker";
import moment from "moment-timezone";
import "date-fns";

const Adplan = ({ jobId }) => {
  const dispatch = useDispatch();

  const {
    jobsProjectList,
    jobsRoleTypeList,
    jobsLocalityList,
    jobsWorkPermitList,
    jobsNoofJobsList,
    jobsWorkplaceList,
    jobsWorkdaysList,
    jobsWorkTypeList,
    jobsContracttypeList,
    jobStatusList,
    jobsCompanyList,
    jobsIndustryIdList,
    jobsCountryList,
    jobsCityList,
    jobsCategoryList,
    jobsLanguageList,
    jobCompList,
  } = useSelector((state) => state.common);

  const { isDetailsLoading, jobDetails, isUpdate } = useSelector(
    (state) => state.jobs
  );
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [titleDesc, setTitleDesc] = useState("");

  useEffect(() => {
    if (jobDetails && !isDetailsLoading) {
      setData(jobDetails);
      setTitleDesc(jobDetails.title_description);
    } else {
      setTitleDesc("");
    }
  }, [isDetailsLoading, jobId]);

  useEffect(() => {
    if (isUpdate) {
      dispatch(clearState());
      setData(jobDetails);
      setSuccess(false);
      setLoading(false);
    }
  }, [isUpdate]);

  let ids = data.id;

  const onItemClick = (id, columnId, value) => {
    dispatch(updateJob({ id, key: columnId, value }));
  };
  const updateMyData = (e) => {
    let values = "";
    let columnId = e.target.dataset.columnid;
    if (columnId === "gross_title") {
      values = e.target.dataset.value;
    } else {
      values = e.target.value;
    }
    dispatch(updateJob({ id: ids, key: columnId, value: values }));
  };

  const handleButtonClick = async () => {
    setSuccess(true);
    setLoading(true);
    dispatch(
      updateJob({ id: ids, key: "title_description", value: titleDesc })
    );
  };
  const [newStatus, setNewStatus] = useState("Active");
  const updateProfile = async (e) => {
    //const id = e.target.dataset.id;
    // const keys = e.target.dataset.key;
    // const values = e.target.dataset.value;
    // await dispatch(updateJob({ id: jobId.jobId, key: keys, value: values }));
  };
  const [selectedDate, setSelectedDate] = useState(
    moment(new Date()).format("DD MMM YYYY")
  );
  const handleDateChange = (date) => {
    setSelectedDate(moment(date).format("DD MMM YYYY"));
  };

  return (
    <>
      <div className="row dkg-edit-livejobs-adsboxRow-234">
        <Tab.Container id="left-tabs-example" defaultActiveKey="dkg-status">
          <div className="col-md-2 col-sm-2  dkg-edit-livejobs-adsboxleftSide pl-0 pr-0">
            <Nav
              variant="pills"
              className="d-flex dkg-edit-livejobs-adsbox-navpills"
            >
              <Nav.Item className="dkg-edit-livejobs-navitem">
                <Nav.Link
                  eventKey="dkg-status"
                  className="dkg-edit-livejobs-navlink"
                >
                  Recruiters
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="dkg-edit-livejobs-navitem">
                <Nav.Link
                  eventKey="Title"
                  className="dkg-edit-livejobs-navlink"
                >
                  Job Title
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="dkg-edit-livejobs-navitem">
                <Nav.Link
                  eventKey="Company"
                  className="dkg-edit-livejobs-navlink"
                >
                  Company
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="dkg-edit-livejobs-navitem">
                <Nav.Link
                  eventKey="Responsibilities"
                  className="dkg-edit-livejobs-navlink"
                >
                  Responsibilities
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="dkg-edit-livejobs-navitem">
                <Nav.Link
                  eventKey="Requirements"
                  className="dkg-edit-livejobs-navlink"
                >
                  Requirements
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="dkg-edit-livejobs-navitem">
                <Nav.Link
                  eventKey="salaryBenefits"
                  className="dkg-edit-livejobs-navlink"
                >
                  Salary & Benefits
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="dkg-edit-livejobs-navitem">
                <Nav.Link
                  eventKey="relocation-support"
                  className="dkg-edit-livejobs-navlink"
                >
                  Relocation Support
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="dkg-edit-livejobs-navitem">
                <Nav.Link eventKey="GDPR" className="dkg-edit-livejobs-navlink">
                  GDPR Consent
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="dkg-edit-livejobs-navitem">
                <Nav.Link
                  eventKey="dkg-editjobs-summary"
                  className="dkg-edit-livejobs-navlink"
                >
                  Summary
                </Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
                <Nav.Link eventKey="JobInfo">Job Short Info</Nav.Link>
              </Nav.Item> */}
            </Nav>
          </div>
          <div className="col-md-10 col-sm-10 dkg-editjob-adsboxRightSide pr-0">
            <Tab.Content className="dkg-editjob-adsbox-jobcontent">
              <Tab.Pane
                eventKey="dkg-status"
                className="dkg-editjob-adsbox-tabpane"
              >
                <div>
                  <div className="row dk-Adsbox">
                    <div
                      className="col-md-12 dkg-edit-jobstus-leftcol dkg-job-staus-col pl-0 pr-3"
                      style={{ marginRight: "auto" }}
                    >
                      <div className="dk-suMMformGroupCon pb-2 mb-2">
                        <label
                          style={{
                            background: "none",
                            minWidth: "185px",
                          }}
                        >
                          AM
                        </label>
                        <div
                          className="dk-AmDropDwnCon"
                          style={{ marginLeft: "4px" }}
                        >
                          <Dropdown className="dk-AmDropDwn">
                            <Dropdown.Toggle
                              variant=""
                              className="dk-AmDropDwnToggle"
                            >
                              <div className="tooltipMain">
                                <img
                                  title="AM"
                                  style={{
                                    width: "37px",
                                    height: "37px",
                                    border: "2px dotted #999",
                                  }}
                                  src={
                                    data.recAM !== undefined
                                      ? process.env
                                          .REACT_APP_IMG_CLOUD_BASE_URL +
                                        data.recAM.profileImage
                                      : ""
                                  }
                                  className="filterImg"
                                  alt={
                                    data.recAM !== undefined
                                      ? data.recAM.firstName +
                                        " " +
                                        data.recAM.lastName
                                      : ""
                                  }
                                />
                                <div className="tooltipText">
                                  {data.recAM !== undefined
                                    ? data.recAM.firstName +
                                      " " +
                                      data.recAM.lastName
                                    : ""}
                                </div>
                              </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dk-AmDropDwnMenu">
                              <div className="d-flex">
                                <UserList
                                  id={data.id}
                                  columnId="rec_am"
                                  onItemClick={onItemClick}
                                />
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                      <div className="dk-suMMformGroupCon pb-2 mb-2">
                        <label
                          style={{
                            background: "none",
                            minWidth: "185px",
                          }}
                        >
                          RC1
                        </label>
                        {data.assignWithjob !== undefined
                          ? data.assignWithjob.map((recdata, index) => (
                              <div
                                className="thridBox"
                                style={{
                                  paddingLeft: "0.30rem",
                                }}
                                key={recdata.id}
                              >
                                <div title="RC" className="AmImgConDrop">
                                  <UserSearchFilter
                                    job_id={recdata.job_id}
                                    recImg={
                                      process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                                      recdata.userInfo.profileImage
                                    }
                                    recName={
                                      recdata.userInfo.firstName +
                                      recdata.userInfo.lastName
                                    }
                                    rcList={data.assignWithjob}
                                  />
                                </div>
                              </div>
                            ))
                          : null}

                        {data.assignWithjob !== undefined &&
                        data.assignWithjob.length === 0 ? (
                          <div
                            className="thridBox"
                            style={{ paddingLeft: "0.30rem" }}
                            key={data.id}
                          >
                            <div title="RC" className="AmImgConDrop">
                              <UserSearchFilter
                                job_id={data.id}
                                recImg={
                                  process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                                  "/users/images/user.png"
                                }
                                recName=""
                                rcList={data.assignWithjob}
                              />
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <div className="dk-suMMformGroupCon pb-2 mb-2">
                        <label
                          style={{
                            background: "none",
                            minWidth: "185px",
                          }}
                        >
                          RC2
                        </label>
                        <div
                          className="dk-AmDropDwnCon"
                          style={{ marginLeft: "4px" }}
                        >
                          <Dropdown className="dk-AmDropDwn">
                            <Dropdown.Toggle
                              variant=""
                              className="dk-AmDropDwnToggle"
                            >
                              <div className="tooltipMain">
                                <img
                                  title="RA"
                                  style={{
                                    width: "37px",
                                    height: "37px",
                                    border: "2px dotted #999",
                                  }}
                                  src={
                                    data.recRA !== undefined
                                      ? process.env
                                          .REACT_APP_IMG_CLOUD_BASE_URL +
                                        data.recRA.profileImage
                                      : ""
                                  }
                                  className="filterImg"
                                  alt={
                                    data.recRA !== undefined
                                      ? data.recRA.firstName +
                                        " " +
                                        data.recRA.lastName
                                      : ""
                                  }
                                />
                                <div className="tooltipText">
                                  {data.recRA !== undefined
                                    ? data.recRA.firstName +
                                      " " +
                                      data.recRA.lastName
                                    : ""}
                                </div>
                              </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dk-AmDropDwnMenu">
                              <div className="d-flex">
                                <UserList
                                  id={data.id}
                                  columnId="rec_ra"
                                  onItemClick={onItemClick}
                                />
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                      <div className="dk-suMMformGroupCon pb-2 mb-2 d-none">
                        <label
                          style={{
                            background: "none",
                            minWidth: "185px",
                          }}
                        >
                          RC3
                        </label>
                        <div
                          className="dk-AmDropDwnCon"
                          style={{ marginLeft: "4px" }}
                        >
                          <Dropdown className="dk-AmDropDwn">
                            <Dropdown.Toggle
                              variant=""
                              className="dk-AmDropDwnToggle"
                            >
                              <div className="tooltipMain">
                                <img
                                  title="RA"
                                  style={{
                                    width: "37px",
                                    height: "37px",
                                    border: "2px dotted #999",
                                  }}
                                  src={
                                    data.rec3rc !== undefined
                                      ? process.env
                                          .REACT_APP_IMG_CLOUD_BASE_URL +
                                        data.rec3rc.profileImage
                                      : ""
                                  }
                                  className="filterImg"
                                  alt={
                                    data.rec3rc !== undefined
                                      ? data.rec3rc.firstName +
                                        " " +
                                        data.rec3rc.lastName
                                      : ""
                                  }
                                />
                                <div className="tooltipText">
                                  {data.rec3rc !== undefined
                                    ? data.rec3rc.firstName +
                                      " " +
                                      data.rec3rc.lastName
                                    : ""}
                                </div>
                              </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dk-AmDropDwnMenu">
                              <div className="d-flex">
                                <UserList
                                  id={data.id}
                                  columnId="rc3"
                                  onItemClick={onItemClick}
                                />
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                      {/* <div className="dk-suMMformGroupCon pb-2 mb-2">
                        <label
                          style={{
                            background: "none",
                            minWidth: "185px",
                          }}
                        >
                          Job ID
                        </label>

                        <FormControl
                          style={{
                            border: "1px solid #ccc",
                            borderRight: "0px",
                            height:
                              "calc(1.5em + .75rem + 2px)",
                            backgroundColor: "#fff"
                          }}
                          aria-label="Small"
                          aria-describedby="inputGroup-sizing-sm"
                          // onBlur={updateMyData}
                          // data-columnid="fee"
                          defaultValue={data.job_id}
                          readOnly
                        />
                      </div>
                      <div className="dk-suMMformGroupCon pb-2 mb-2">
                        <label
                          style={{
                            background: "none",
                            minWidth: "185px",
                          }}
                        >
                          Per Hire
                        </label>
                        <InputGroup
                          size="sm"
                          className="dk-inputGrp"
                        >
                          <FormControl
                            style={{
                              border: "1px solid #ccc",
                              borderRight: "0px",
                              height:
                                "calc(1.5em + .75rem + 2px)",
                            }}
                            aria-label="Small"
                            aria-describedby="inputGroup-sizing-sm"
                            onBlur={updateMyData}
                            data-columnid="fee"
                            defaultValue={data.fee}
                          />
                          <InputGroup.Prepend>
                            <InputGroup.Text id="inputGroup-sizing-sm">
                              <i className="fas fa-euro-sign"></i>
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                        </InputGroup>
                      </div> */}
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="Title" className="dkg-editjob-adsbox-tabpane">
                <div className="dkg-editjob-title-tabCon">
                  <div className="d-flex dkg-jobtitleInput-flex w-100">
                    <div
                      className="dkg-interviewnotes-editor12 mr-auto d-flex align-items-center"
                      style={{ width: "40%" }}
                    >
                      <label
                        className="dkg-jobtitle-label"
                        style={{ minWidth: "180px", marginBottom: "0" }}
                      >
                        CREATED
                      </label>
                      <div className="dkg-jobinfo-date-timeCon">
                        <div className="w-100">
                          <DatePicker
                            selectedDate={selectedDate}
                            handleDateChange={handleDateChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="dkg-interviewnotes-editor12 mr-auto d-flex align-items-center"
                      style={{ width: "90%" }}
                    >
                      <label
                        className="dkg-jobtitle-label"
                        style={{ minWidth: "180px", marginBottom: "0" }}
                      >
                        JOB TITLE
                      </label>
                      <input
                        type="text"
                        name="titledescription"
                        className="w-100 dkg-jobtitleInput"
                        value={titleDesc}
                        onChange={(e) => setTitleDesc(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="dkg-editjob-savebtnCon">
                    <SubmitButton
                      txt="SAVE"
                      loading={loading}
                      success={success}
                      className="dkg-editjob-savebtn"
                      onClick={handleButtonClick}
                    />
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane
                eventKey="Company"
                className="dkg-editjob-adsbox-tabpane"
              >
                <div
                  className="dk-ClentViewTabPane pl-0 pr-3 summaryTabPane"
                  style={{ height: "calc(100vh - 151px)" }}
                >
                  <div className="dkg-interviewnotes-editor12">
                    <EditorComponents
                      jobId={ids}
                      name="company_description"
                      details={
                        data.company_description != "" &&
                        data.company_description != null
                          ? data.company_description
                          : ""
                      }
                      jobbid={ids}
                    />
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane
                eventKey="Requirements"
                className="dkg-editjob-adsbox-tabpane"
              >
                <div
                  className="dk-ClentViewTabPane pl-0 pr-3 summaryTabPane"
                  style={{ height: "calc(100vh - 151px)" }}
                >
                  <div className="dkg-interviewnotes-editor12">
                    <EditorComponents
                      jobId={ids}
                      name="requirnment_description"
                      details={
                        data.requirnment_description != "" &&
                        data.requirnment_description != null
                          ? data.requirnment_description
                          : ""
                      }
                    />
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane
                eventKey="Responsibilities"
                className="dkg-editjob-adsbox-tabpane"
              >
                <div
                  className="dk-ClentViewTabPane pl-0 pr-3 summaryTabPane"
                  style={{ height: "calc(100vh - 151px)" }}
                >
                  <div className="dkg-interviewnotes-editor12">
                    <EditorComponents
                      jobId={ids}
                      name="responsibility_description"
                      details={
                        data.responsibility_description != "" &&
                        data.responsibility_description != null
                          ? data.responsibility_description
                          : ""
                      }
                    />
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane
                eventKey="salaryBenefits"
                className="dkg-editjob-adsbox-tabpane"
              >
                <div
                  className="dk-ClentViewTabPane pl-0 pr-3 summaryTabPane"
                  style={{ height: "calc(100vh - 151px)" }}
                >
                  <div className="dkg-interviewnotes-editor12">
                    <EditorComponents
                      jobId={ids}
                      name="salary_benifit_description"
                      details={
                        data.salary_benifit_description != "" &&
                        data.salary_benifit_description != null
                          ? data.salary_benifit_description
                          : ""
                      }
                    />
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane
                eventKey="relocation-support"
                className="dkg-editjob-adsbox-tabpane"
              >
                <div
                  className="dk-ClentViewTabPane pl-0 pr-3 summaryTabPane"
                  style={{ height: "calc(100vh - 151px)" }}
                >
                  <div className="dkg-interviewnotes-editor12">
                    <EditorComponents
                      jobId={ids}
                      name="relocation_support"
                      details={
                        data.relocation_support != "" &&
                        data.relocation_support != null
                          ? data.relocation_support
                          : ""
                      }
                    />
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="GDPR" className="dkg-editjob-adsbox-tabpane">
                <div
                  className="dk-ClentViewTabPane pl-0 pr-3 summaryTabPane"
                  style={{ height: "calc(100vh - 151px)" }}
                >
                  <div className="dkg-interviewnotes-editor12">
                    <EditorComponents
                      jobId={ids}
                      name="gdpr_description"
                      details={
                        data.gdpr_description != "" &&
                        data.gdpr_description != null
                          ? data.gdpr_description
                          : ""
                      }
                    />
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane
                eventKey="dkg-editjobs-summary"
                className="dkg-editjob-adsbox-tabpane"
              >
                <div
                  className="dk-ClentViewTabPane pl-0 pr-3 summaryTabPane"
                  style={{ height: "calc(100vh - 151px)" }}
                >
                  <div className="row dk-Adsbox">
                    <div className="col-md-6 col-6">
                      <div
                        className="row dkg-editjob-summ-tabCon dk-suMMLeftCon border-0"
                        style={{
                          height: "auto",
                          marginTop: "0",
                          backgroundColor: "#eaeaea",
                        }}
                      >
                        <div className="col-md-12">
                          <div className="dk-suMMformGroupCon pb-2">
                            <label
                              style={{
                                background: "none",
                                minWidth: "185px",
                              }}
                            >
                              Status
                            </label>
                            <div className="dk-statusDrpDwnCon">
                              <JobStatus
                                caseStatus={newStatus}
                                list={jobStatusList}
                                jobId={jobId}
                                updateStatus={updateProfile}
                                column="status"
                              />
                            </div>
                          </div>
                          <div className="dk-suMMformGroupCon pb-2">
                            <label
                              style={{
                                background: "none",
                                minWidth: "185px",
                              }}
                            >
                              Total Jobs
                            </label>

                            <SimpleDropDwn
                              categoryList={jobsNoofJobsList}
                              text={data.no_of_jobs}
                              columnId="no_of_jobs"
                            />
                          </div>
                          <div className="dk-suMMformGroupCon pb-2">
                            <label
                              style={{
                                background: "none",
                                minWidth: "185px",
                              }}
                            >
                              Monthly Salary
                            </label>

                            <input
                              type="text"
                              style={{
                                border: "1px solid #ccc",
                                width: "100%",
                                textAlign: "center",
                                height: "calc(1.5em + .75rem + 2px)",
                              }}
                              defaultValue={data.monthly_salary}
                              onBlur={updateMyData}
                              data-columnid="monthly_salary"
                              className="form-control"
                            />
                          </div>
                          <div className="dk-suMMformGroupCon pb-2">
                            <label
                              style={{
                                background: "none",
                                minWidth: "185px",
                              }}
                            >
                              Performance Bonus
                            </label>

                            <input
                              type="text"
                              style={{
                                border: "1px solid #ccc",
                                width: "100%",
                                textAlign: "center",
                                height: "calc(1.5em + .75rem + 2px)",
                              }}
                              defaultValue={data.performance_bonus}
                              onBlur={updateMyData}
                              data-columnid="performance_bonus"
                              className="form-control"
                            />
                          </div>
                          <div className="dk-suMMformGroupCon pb-2">
                            <label
                              style={{
                                background: "none",
                                minWidth: "185px",
                              }}
                            >
                              Industry
                            </label>

                            <SimpleDropDwn
                              categoryList={jobsIndustryIdList}
                              text={data.industry}
                              columnId="industry"
                            />
                          </div>
                          <div className="dk-suMMformGroupCon pb-2">
                            <label
                              style={{
                                background: "none",
                                minWidth: "185px",
                              }}
                            >
                              Company
                            </label>

                            <SimpleDropDwn
                              categoryList={jobCompList}
                              text={data.company}
                              columnId="company"
                            />
                          </div>
                          <div className="dk-suMMformGroupCon pb-2">
                            <label
                              style={{
                                background: "none",
                                minWidth: "185px",
                              }}
                            >
                              Category
                            </label>

                            <SimpleDropDwn
                              categoryList={jobsCategoryList}
                              text={data.category}
                              columnId="category"
                            />
                          </div>
                          <div className="dk-suMMformGroupCon pb-2">
                            <label
                              style={{
                                background: "none",
                                minWidth: "185px",
                              }}
                            >
                              Country
                            </label>

                            <SimpleDropDwn
                              categoryList={jobsCountryList}
                              text={data.job_country}
                              columnId="job_country"
                            />
                          </div>
                          <div className="dk-suMMformGroupCon pb-2">
                            <label
                              style={{
                                background: "none",
                                minWidth: "185px",
                              }}
                            >
                              City/Town
                            </label>
                            <SimpleDropDwn
                              categoryList={jobsCityList}
                              text={data.location}
                              columnId="location"
                            />
                          </div>

                          <div className="dk-suMMformGroupCon pb-2">
                            <label
                              style={{
                                background: "none",
                                minWidth: "185px",
                              }}
                            >
                              Project
                            </label>

                            <SimpleDropDwn
                              categoryList={jobsProjectList}
                              text={data.project}
                              columnId="project"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6  col-6">
                      <div
                        className="row dkg-editjob-summ-tabCon dk-suMMLeftCon border-0"
                        style={{
                          height: "auto",
                          marginTop: "0",
                          backgroundColor: "#eaeaea",
                        }}
                      >
                        <div className="col-md-12">
                          <div className="dk-suMMformGroupCon pb-2">
                            <label
                              style={{
                                background: "none",
                                minWidth: "185px",
                              }}
                            >
                              Role Type
                            </label>

                            <SimpleDropDwn
                              categoryList={jobsRoleTypeList}
                              text={data.role}
                              columnId="role"
                            />
                          </div>
                          <div className="dk-suMMformGroupCon pb-2">
                            <label
                              style={{
                                background: "none",
                                minWidth: "185px",
                              }}
                            >
                              Language
                            </label>

                            <SimpleDropDwn
                              categoryList={jobsLanguageList}
                              text={data.lang}
                              columnId="lang"
                            />
                          </div>
                          <div className="dk-suMMformGroupCon pb-2">
                            <label
                              style={{
                                background: "none",
                                minWidth: "185px",
                              }}
                            >
                              Contract
                            </label>

                            <SimpleDropDwn
                              categoryList={jobsContracttypeList}
                              text={data.contract}
                              columnId="contract"
                            />
                          </div>
                          <div className="dk-suMMformGroupCon pb-2">
                            <label
                              style={{
                                background: "none",
                                minWidth: "185px",
                              }}
                              className="relocationlabel"
                            >
                              Work Permit
                            </label>

                            <SimpleDropDwn
                              categoryList={jobsWorkPermitList}
                              text={data.work_permit}
                              columnId="work_permit"
                            />
                          </div>
                          <div className="dk-suMMformGroupCon pb-2 d-none">
                            <label
                              style={{
                                background: "none",
                                minWidth: "185px",
                              }}
                            >
                              Locality
                            </label>

                            <SimpleDropDwn
                              categoryList={jobsLocalityList}
                              text={data.locality}
                              columnId="locality"
                            />
                          </div>

                          <div className="dk-suMMformGroupCon pb-2">
                            <label
                              style={{
                                background: "none",
                                minWidth: "185px",
                              }}
                            >
                              Work From
                            </label>
                            <SimpleDropDwn
                              categoryList={jobsWorkplaceList}
                              text={data.workplace}
                              columnId="workplace"
                            />
                          </div>
                          <div className="dk-suMMformGroupCon pb-2">
                            <label
                              style={{
                                background: "none",
                                minWidth: "185px",
                              }}
                            >
                              Work Type
                            </label>

                            <SimpleDropDwn
                              categoryList={jobsWorkTypeList}
                              text={data.worktype}
                              columnId="worktype"
                            />
                          </div>
                          <div className="dk-suMMformGroupCon pb-2">
                            <label
                              style={{
                                background: "none",
                                minWidth: "185px",
                              }}
                            >
                              Work Days
                            </label>

                            <SimpleDropDwn
                              categoryList={jobsWorkdaysList}
                              text={data.workdays}
                              columnId="workdays"
                            />
                          </div>
                          <div className="dk-suMMformGroupCon pb-2">
                            <label
                              style={{
                                background: "none",
                                minWidth: "185px",
                              }}
                            >
                              Work Hours
                            </label>

                            <input
                              type="text"
                              style={{
                                border: "1px solid #ccc",
                                height: "calc(1.5em + .75rem + 2px)",
                              }}
                              defaultValue={data.shifts_work}
                              onBlur={updateMyData}
                              data-columnid="shifts_work"
                              className="form-control"
                            />
                          </div>
                          <div className="dk-suMMformGroupCon pb-2">
                            <label
                              style={{
                                background: "none",
                                minWidth: "185px",
                              }}
                            >
                              Start Date 1
                            </label>

                            <input
                              type="text"
                              style={{
                                border: "1px solid #ccc",
                                borderRight: "0px",
                                height: "calc(1.5em + .75rem + 2px)",
                              }}
                              defaultValue={data.start_date1}
                              onBlur={updateMyData}
                              data-columnid="start_date1"
                              className="form-control"
                            />
                          </div>
                          <div className="dk-suMMformGroupCon pb-2">
                            <label
                              style={{
                                background: "none",
                                minWidth: "185px",
                              }}
                            >
                              Start Date 2
                            </label>

                            <input
                              type="text"
                              style={{
                                border: "1px solid #ccc",
                                borderRight: "0px",
                                height: "calc(1.5em + .75rem + 2px)",
                              }}
                              defaultValue={data.start_date1}
                              onBlur={updateMyData}
                              data-columnid="start_date1"
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </div>
        </Tab.Container>
      </div>
    </>
  );
};

export default Adplan;
