import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";

const HrMenu = () => {
    const pathname = window.location.pathname;
    const segment1 = pathname.split("/")[1];
    const segment2 = pathname.split("/")[2];
    const segment3 = pathname.split("/")[3];

    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | DK MasterBox";
    }, [pathname]);

    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-accountingNavbar dk-dkglobalAdminHrMainMenu">
                <Route>
                    <ul>
                        <li><Link to="/business-overview/hr/leaves" className={(segment2 === 'hr') ? 'active' : ''}>Dashboard</Link></li>
                        <li><Link to="#/business-overview/onboarding/welcome" className={`${(segment3 === 'onboarding') ? 'active' : ''}`}>Onboarding</Link></li>
                        <li><Link to="#/business-overview/joboffer/overview" className={`${(segment3 === 'joboffer') ? 'active' : ''}`}>Job Offer</Link></li>
                        <li><Link to="#/business-overview/contracts/overview" className={`${(segment3 === 'contracts') ? 'active' : ''}`}>Contracts</Link></li>
                        <li><Link to="#/business-overview/documents/overview" className={`${(segment3 === 'documents') ? 'active' : ''}`}>Documents</Link></li>
                        <li><Link to="#/business-overview/refrences/overview" className={`${(segment3 === 'refrences') ? 'active' : ''}`}>Refrences</Link></li>
                        <li><Link to="#/business-overview/work-station/overview" className={`${(segment3 === 'work-station') ? 'active' : ''}`}>Work Station</Link></li>
                        <li><Link to="#/business-overview/tech-check/overview" className={`${(segment3 === 'tech-check') ? 'active' : ''}`}>Tech Check</Link></li>
                        <li><Link to="#/business-overview/accounts/overview" className={`${(segment3 === 'accounts') ? 'active' : ''}`}>Accounts</Link></li>
                        <li><Link to="#/business-overview/instructions/overview" className={`${(segment3 === 'instructions') ? 'active' : ''}`}>Instructions</Link></li>
                        {/* <li><Link to="#" className={`${(segment2 === 'structure') ? '' : ''}`}>Highlights</Link></li>
                        <li><Link to="#" className={`${(segment2 === 'teams') ? '' : ''}`}>Company</Link></li>
                        <li><Link to="#" className={`${(segment2 === 'pipelines') ? '' : ''}`}>Employee</Link></li>
                        <li><Link to="#" className={`${(segment2 === 'team-1') ? '' : ''}`}>New Hire</Link></li>
                        <li><Link to="#" className={`${(segment2 === 'team-2') ? '' : ''}`}>Contracts</Link></li>
                        <li><Link to="#" className={`${(segment2 === 'team-3') ? '' : ''}`}>Appraisals</Link></li>
                        <li><Link to="#" className={`${(segment2 === 'team-4') ? '' : ''}`}>Leaves</Link></li>
                        <li><Link to="#" className={`${(segment2 === 'team-5') ? '' : ''}`}>Handbook</Link></li>
                        <li><Link to="#" className={`${(segment2 === 'team-5') ? '' : ''}`}>Compliance</Link></li>
                        <li><Link to="#" className={`${(segment2 === 'team-5') ? '' : ''}`}>Training</Link></li> */}
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default HrMenu;