import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
//import { FaUser } from "react-icons/fa";
import './search-db.scss';
//import SelectDropDown from './dropdown/SelectDropDown';
import CreateNewModal from './modal/CreateNew';
//import SelectLongDropDown from './dropdown/SelectLongDropDown';
import SelectWithSearchDropdown from './dropdown/SelectWithSearchDropdown';
//import DefaultDropdown from './dropdown/defaultDropdown';
//import CircularProgressBar from './CircularProgressBar';
//import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@mui/material/Backdrop';
//import CircularProgress from '@mui/material/CircularProgress';
//import Button from '@mui/material/Button';
import AnimationProgress from './AnimationProgress';

import { databaseSearchList, candidateFilterSummaryList } from "../../../../slice/candidates/candidatesSlice";
//import { pipelineSummaryRcFilter, pipelineClearSummaryRcFilter } from "../../../../slice/layout/layoutSlice";
//import { countAllTask } from "../../../../slice/task/taskSlice"
//import { summaryFilter, dbReadyListFilter, dbReadyList, invoiceFilterList, recAdminList, countAll } from "../../../../slice/candidates/list/listSlice";
//import { FaCheck, FaQuestion, FaTimes, FaRegCalendarAlt, FaCcVisa, FaMale, FaFemale, FaTransgenderAlt } from 'react-icons/fa';
import { FaStarOfLife } from "react-icons/fa";


const Index = () => {

    const dispatch = useDispatch();
    const { isLoading, databaseSearchCvList } = useSelector(state => state.candidate);
    //const { filterRecId, RecValue, RecName } = useSelector(state => state.common);
    const { isInsert } = useSelector(state => state.projects)

    //const pathname = window.location.pathname;
    //const segment3 = pathname.split("/")[3];

    const [list, setList] = useState([]);
    const [isAddNew, setAddNew] = useState(false)
    console.log(list)

    const showAddNewModal = () => {
        setAddNew(true)
    }
    const hideAddNewModal = () => {
        setAddNew(false)
    }

    useEffect(() => {
        document.title = "Candidate Summary | DKG Recruitment | DK Global CRM"
    }, [])

    const [recordYear, setRecordYear] = useState('Record Year')

    const [isRecordYearFilter, setIsRecordYearFilter] = useState(false);
    const [recordYearFilterText, setRecordYearFilterText] = useState(['Record Year' , <span className='dkg-search-cv-db-star-span'><FaStarOfLife className='dkg-search-cv-db-star'/></span>]);
    const [isRecordMonthFilter, setIsRecordMonthFilter] = useState(false);
    const [recordYearMonthFilterText, setRecordMonthFilterText] = useState(['Months' , <span className='dkg-search-cv-db-star-span'><FaStarOfLife className='dkg-search-cv-db-star'/></span>]);
    const [pipelineFilterText, setPipelineFilterText] = useState(['Pipeline' , <span className='dkg-search-cv-db-star-span'><FaStarOfLife className='dkg-search-cv-db-star'/></span>]);

    const [isCategoryFilter, setIsCategoryFilter] = useState(false);
    const [categoryFilterText, setCategoryFilterText] = useState(['Category' , <span className='dkg-search-cv-db-star-span'><FaStarOfLife className='dkg-search-cv-db-star'/></span>]);

    // const [isFlagFilter, setIsFlagFilter] = useState(false);
    // const [flagFilterText, setFlagFilterText] = useState('');

    // const [isProfileIndicatorFilter, setIsProfileIndicatorFilter] = useState(false);
    // const [profileIndicatorText, setProfileIndicatorText] = useState('');

    // const [isCanMotivationStatusFilter, setIsCanMotivationStatusFilter] = useState(false);
    // const [canMotivationStatusText, setCanMotivationStatusText] = useState('');

    const [isAccountManagerFilter, setIsAccountManagerFilter] = useState(false);
    const [accountManagerFilterText, setAccountManagerFilterText] = useState(['AM' , <span className='dkg-search-cv-db-star-span'><FaStarOfLife className='dkg-search-cv-db-star'/></span>]);

    // const [isRecruiterFilter, setIsRecruiterFilter] = useState(false);
    const [recruiterOneFilterText, setRecruiterOneFilterText] = useState('RC');

    const [isRecruiterFilter, setIsRecruiterFilter] = useState(false);
    const [recruiterFilterText, setRecruiterFilterText] = useState('RR');

    const [isJobTitleFilter, setIsJobTitleFilter] = useState(false);
    const [jobTitleFilterText, setJobTitleFilterText] = useState('Job Title');

    const [isCompanyFilter, setIsCompanyFilter] = useState(false);
    const [companyFilterText, setCompanyFilterText] = useState('Job Company');

    const [isJobCityFilter, setIsJobCityFilter] = useState(false);
    const [jobCityFilterText, setJobCityFilterText] = useState('Town/City');

    const [isJobCountryFilter, setIsJobCountryFilter] = useState(false);
    const [jobCountryFilterText, setJobCountryFilterText] = useState('Job Country');

    const [isLocalityFilter, setIsLocalityFilter] = useState(false);
    const [localityFilterText, setLocalityFilterText] = useState('Job City/Town');

    const [isRoleFilter, setIsRoleFilter] = useState(false);
    const [roleFilterText, setRoleFilterText] = useState('Job Role Type');

    const [isProjectFilter, setIsProjectFilter] = useState(false);
    const [projectFilterText, setProjectFilterText] = useState('Job Project');

    const [isLanguageFilter, setIsLanguageFilter] = useState(false);
    const [languageFilterText, setLanguageFilterText] = useState('Job Language');

    const [isCaseStatusFilter, setIsCaseStatusFilter] = useState(false);
    const [CaseStatusFilterText, setCaseStatusFilterText] = useState('Stage');

    const [isPipelineFilter, setIsPipelineFilter] = useState(false);
    //const [pipelineFilterText, setPipelineFilterText] = useState('');

    const [isCaseProgressFilter, setIsCaseProgressFilter] = useState(false);
    const [CaseProgressFilterText, setCaseProgressFilterText] = useState('Situation');

    const [isCaseSituationFilter, setIsCaseSituationFilter] = useState(false);
    const [CaseSituationFilterText, setCaseSituationFilterText] = useState(['Status' , <span className='dkg-search-cv-db-star-span'><FaStarOfLife className='dkg-search-cv-db-star'/></span>]);

    const [isCaseStageFilter, setIsCaseStageFilter] = useState(false);
    const [CaseStageFilterText, setCaseStageFilterText] = useState('Progress');

    const [isCvSourceFilter, setIsCvSourceFilter] = useState(false);
    const [cvSourceFilterText, setCvSourceFilterText] = useState(['CV Source' , <span className='dkg-search-cv-db-star-span'><FaStarOfLife className='dkg-search-cv-db-star'/></span>]);

    // const [isApprovelFilter, setIsApprovelFilter] = useState(false);
    // const [approvelFilterText, setApprovelFilterText] = useState('');

    const [isCityFilter, setIsCityFilter] = useState(false);
    const [cityFilterText, setCityFilterText] = useState('City/Town');

    const [isCountryFilter, setIsCountryFilter] = useState(false);
    const [countryFilterText, setCountryFilterText] = useState( ['Country' , <span className='dkg-search-cv-db-star-span'><FaStarOfLife className='dkg-search-cv-db-star'/></span>]);

    const [isNationalityFilter, setIsNationalityFilter] = useState(false);
    const [nationalityFilterText, setNationalityFilterText] = useState(['Nationality' , <span className='dkg-search-cv-db-star-span'><FaStarOfLife className='dkg-search-cv-db-star'/></span>]);

    const [isVisaStatusFilter, setIsVisaStatusFilter] = useState(false);
    const [visaStatusFilterText, setVisaStatusFilterText] = useState('Visa Status');

    const [isEducationFilter, setIsEducationFilter] = useState(false);
    const [educationFilterText, setEducationFilterText] = useState('Qualification');

    const [isCareerLevelFilter, setIsCareerLevelFilter] = useState(false);
    const [careerLevelFilterText, setCareerLevelFilterText] = useState('Career Level');

    const [isCurrentJobFilter, setIsCurrentJobFilter] = useState(false);
    const [currentJobFilterText, setCurrentJobFilterText] = useState('Job Title');

    const [isIndustriesFilter, setIsIndustriesFilter] = useState(false);
    const [industriesFilterText, setIndustriesFilterText] = useState('Industry');

    const [isNative1Filter, setIsNative1Filter] = useState(false);
    const [native1FilterText, setNative1FilterText] = useState(['Native Language' , <span className='dkg-search-cv-db-star-span'><FaStarOfLife className='dkg-search-cv-db-star'/></span>]);

    const [isFluent1Filter, setIsFluent1Filter] = useState(false);
    const [fluent1FilterText, setFluent1FilterText] = useState(['Fluent Language' , <span className='dkg-search-cv-db-star-span'><FaStarOfLife className='dkg-search-cv-db-star'/></span>]);

    const [isIntermediateLangFilter, setIsIntermediateLang] = useState(false);
    const [isIntermediateLangFilterText, setIsIntermediateLangFilterText] = useState('Intermediate Language');

    const [isExperienceFilter, setIsExperienceFilter] = useState(false);
    const [experienceFilterText, setIsExperienceFilterText] = useState('Experienced In');

    const [isCasePriorityFilter, setIsCasePriorityFilter] = useState(false);
    const [casePriorityFilterText, setCasePriorityFilterText] = useState('Case Priority');

    const [isCrmProfileFilter, setIsCrmProfileFilterFilter] = useState(false);
    const [crmProfileFilterText, setCrmProfileFilterText] = useState(['Case Updated' , <span className='dkg-search-cv-db-star-span'><FaStarOfLife className='dkg-search-cv-db-star'/></span>]);

    const [isMotivationFilter, setIsMotivationFilter] = useState(false);
    const [motivationFilterText, setMotivationFilterText] = useState('Case Complexity');

    const [isInterviewFilter, setIsInterviewFilter] = useState(false);
    const [interviewFilterText, setInterviewFilterText] = useState('Case Response');

    const [isGenderFilter, setIsGenderFilter] = useState(false);
    const [genderFilterText, setGenderFilterText] = useState('Gender');

    const [itSkill, setItSkill] = useState(['Tech Skills' , <span className='dkg-search-cv-db-star-span'><FaStarOfLife className='dkg-search-cv-db-star'/></span>])


    const [jobskill, setJobskill] = useState('Job Skills')

    const [isemailOneVerify, setIsEmailOneverify] = useState(['Email 1 Verified' , <span className='dkg-search-cv-db-star-span'><FaStarOfLife className='dkg-search-cv-db-star'/></span>])


    const [projectList, setProjectList] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [jobCityList, setJobCityList] = useState([]);
    const [jobCountryList, setJobCountryList] = useState([]);
    const [jobLocalityList, setJobLocalityList] = useState([]);
    const [cpList, setCpList] = useState([]);
    const [csList, setCsList] = useState([]);
    const [cvSourceList, setCvSourceList] = useState([]);


    const [langList, setLangList] = useState([]);
    // const [statusList, setStatusList] = useState([]);
    const [roleList, setRoleList] = useState([]);
    const [casestatusList, setCasestatusList] = useState([]);
    const [recordYearlist, setRecordYearlist] = useState([]);
    const [monthsList, setMonthsList] = useState(['Jan','Feb','Mar',"Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]);
    const [categoryList, setCategoryList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [nationalityList, setNationalityList] = useState([]);
    const [visaStatusList, setVisaStatusList] = useState([]);
    const [educationList, setEducationList] = useState([]);
    const [careerLevelList, setCareerLevelList] = useState([]);
    const [currentJobsList, setCurrentJobsList] = useState([]);
    const [industriesList, setIndustriesList] = useState([]);
    const [nativeLanguageList, setNativeLanguageList] = useState([]);
    const [fluentLanguageList, setFluentLanguageList] = useState([]);
    const [intermediate1List, setIntermediate1List] = useState([]);
    const [priorityList, setPriorityList] = useState([]);
    const [profileStatusList, setProfileStatusList] = useState([]);
    const [crmProfileList, setCrmProfileList] = useState([]);
    const [candidateList, setCandidateList] = useState([]);
    const [interviewsList, setInterviewsList] = useState([]);
    const [genderList, setGenderList] = useState([]);
    const [amUserList, setAmUserList] = useState([]);
    const [canIdList, setCanIdList] = useState([]);
    const [rcUserList, setRcUserList] = useState([]);
    const [jobTitleList, setJobTitleList] = useState([]);

    const [totalCount, setTotalCount] = useState(0);

    const [newLoading, setNewLoading] = useState(true);

    const [filter, setFilter] = useState({});
    const [companyfilter, setCompanyfilter] = useState({});
    const [filterTags, setFilterTags] = useState([]);
    const [tagLength, setTagLength] = useState(0);
    const [removeFilter, setRemoveFilter] = useState(0);
    console.log(monthsList)
    useEffect(() => {
        if (!isLoading && databaseSearchCvList.length > 0) {

            setList(databaseSearchCvList)
        }
        if (isInsert) {
            setAddNew(false)
            setRecordYearFilterText('Record Year')
            setCategoryFilterText('Category')
            setAccountManagerFilterText('Account Manager')
            setRecruiterFilterText('Recruitment Consultant')
            setCaseStatusFilterText('Case Status')
            setCaseProgressFilterText('Case Progress')
            setCaseStageFilterText('Case Stage')
            setCvSourceFilterText('CV Source')
            setCityFilterText('City')
            setCountryFilterText('Country')
            setNationalityFilterText('Nationality')
            setVisaStatusFilterText('Visa Status')
            setEducationFilterText('Education')
            setCareerLevelFilterText('Career Level')
            setCurrentJobFilterText('Current Job')
            setIndustriesFilterText('Industries')
            setNative1FilterText('Native Language')
            setFluent1FilterText('Fluent Language')
            setIsIntermediateLangFilterText('Intermediate Language')
            setIsExperienceFilterText('Experience')
            setCasePriorityFilterText('Case Priority')
            setCrmProfileFilterText('CRM Profile')
            setMotivationFilterText('Candidate')
            setInterviewFilterText('Interviews')
            setGenderFilterText('Gender')
            setIsCategoryFilter(false)
            setIsCaseStatusFilter(false)
            setIsRecordYearFilter(false)
            setIsRecordMonthFilter(false)
            setIsCvSourceFilter(false)
            setIsAccountManagerFilter(false)
            setIsRecruiterFilter(false)
            setIsCaseProgressFilter(false)
            setIsCaseStageFilter(false)
            setIsCityFilter(false)
            setIsCountryFilter(false)
            setIsNationalityFilter(false)
            setIsVisaStatusFilter(false)
            setIsEducationFilter(false)
            setIsCareerLevelFilter(false)
            setIsCurrentJobFilter(false)
            setIsIndustriesFilter(false)
            setIsNative1Filter(false)
            setIsFluent1Filter(false)
            setIsIntermediateLang(false)
            setIsExperienceFilter(false)
            setIsCrmProfileFilterFilter(false)
            setIsMotivationFilter(false)
            setIsGenderFilter(false)
            setIsInterviewFilter(false)
            setIsCasePriorityFilter(false)
            const params = getRequestParams(filter);
            dispatch(databaseSearchList(params));

        }
    }, [databaseSearchCvList, isLoading, isInsert])

    useEffect(() => {

        // const jobLocalityResult = [];
        // const jobLocalitymap = new Map();
        // for (const item of list) {
        //     if (!jobLocalitymap.has(item.locality)) {
        //         jobLocalitymap.set(item.locality, true);
        //         jobLocalityResult.push({
        //             name: (item.locality != '') ? item.locality : 'Blank',
        //             count: list.filter(function (val) {
        //                 return val.locality === item.locality;
        //             }).length
        //         });
        //     }
        // }
        // jobLocalityResult.sort(function (a, b) {
        //     return b.count - a.count;
        // });
        // setJobLocalityList(jobLocalityResult)

        // const jobCountryResult = [];
        // const jobCountrymap = new Map();
        // for (const item of list) {
        //     if (!jobCountrymap.has(item.job_country)) {
        //         jobCountrymap.set(item.job_country, true);
        //         jobCountryResult.push({
        //             name: (item.job_country != '') ? item.job_country : 'Blank',
        //             count: list.filter(function (val) {
        //                 return val.job_country === item.job_country;
        //             }).length
        //         });
        //     }
        // }
        // jobCountryResult.sort(function (a, b) {
        //     return b.count - a.count;
        // });
        // setJobCountryList(jobCountryResult)

        // const jobcityResult = [];
        // const jobcitymap = new Map();
        // for (const item of list) {
        //     if (!jobcitymap.has(item.location)) {
        //         jobcitymap.set(item.location, true);
        //         jobcityResult.push({
        //             name: (item.location != '') ? item.location : 'Blank',
        //             count: list.filter(function (val) {
        //                 return val.location === item.location;
        //             }).length
        //         });
        //     }
        // }
        // jobcityResult.sort(function (a, b) {
        //     return b.count - a.count;
        // });
        // setJobCityList(jobcityResult)

        // const jobTitleResult = [];
        // const jobTitlemap = new Map();
        // for (const item of list) {
        //     if (!jobTitlemap.has(item.name)) {
        //         jobTitlemap.set(item.name, true);
        //         jobTitleResult.push({
        //             name: (item.name != '') ? item.name : 'Blank',
        //             count: list.filter(function (val) {
        //                 return val.name === item.name;
        //             }).length
        //         });
        //     }
        // }
        // jobTitleResult.sort(function (a, b) {
        //     return b.count - a.count;
        // });
        // setJobTitleList(jobTitleResult)

        let totalNumber = 0;
        const categoryResult = [];
        const canIdResult = [];
        const cpResult = [];
        const caseResult = [];
        const csResult = [];
        const cvSourceResult = [];
        const cityResult = [];
        const countryResult = [];
        const nationalityResult = [];
        const recordYearResult = [];
        const monthsList = ["A" , "J", "A" , "Y"];
        const genderResult = [];
        const interviewResult = [];
        const candidateLResult = [];
        const crmProfileResult = [];
        const profileStatusResult = [];
        const preoriyResult = [];
        const intermediateResult = [];
        const fluent1Result = [];
        const nativeLangResult = [];
        const industriesResult = [];
        const currentJobResult = [];
        const careerLResult = [];
        const educationResult = [];
        const visaStatusResult = [];
        const rcResult = [];
        const amResult = [];
        for (const item of list) {
            if (item.category || item.category == "") {
                categoryResult.push({
                    name: item.category,
                    count: item.count
                });
                totalNumber += parseInt(item.count);
            }
            if (item.case_progress || item.case_progress == "") {
                cpResult.push({
                    name: item.case_progress,
                    count: item.count
                });
            }
            if (item.cv_source || item.cv_source == "") {
                cvSourceResult.push({
                    name: item.cv_source,
                    count: item.count
                });
            }
            if (item.case_stage || item.case_stage == "") {
                csResult.push({
                    name: item.case_stage,
                    count: item.count
                });
            }
            if (item.caseStatus || item.caseStatus == "") {
                caseResult.push({
                    name: item.caseStatus,
                    count: item.count
                });
            }
            if (item.rcId || item.rcId == "") {
                rcResult.push({
                    id: item.rcId,
                    name: item.rcFirstName,
                    userImg: item.rcProfileImage,
                    count: item.count
                });
            }
            if (item.year || item.year == "") {
                recordYearResult.push({
                    name: item.year,
                    count: item.count
                });
            }
            if (item.gender || item.gender == "") {
                genderResult.push({
                    name: item.gender,
                    count: item.count
                });
            }
            if (item.flag_type || item.flag_type == "") {
                interviewResult.push({
                    name: item.flag_type,
                    count: item.count
                });
            }
            if (item.motivationStatus || item.motivationStatus == "") {
                candidateLResult.push({
                    name: item.motivationStatus,
                    count: item.count
                });
            }
            if (item.profileIndicator || item.profileIndicator == "") {
                crmProfileResult.push({
                    name: item.profileIndicator,
                    count: item.count
                });
            }
            if (item.profileStatus || item.profileStatus == "") {
                if (item.profileStatus != 3) {
                    profileStatusResult.push({
                        name: item.profileStatus,
                        count: item.count
                    });
                }
            }
            if (item.priority || item.priority == "") {
                preoriyResult.push({
                    name: item.priority,
                    count: item.count
                });
            }
            if (item.intermediate1 || item.intermediate1 == "") {
                intermediateResult.push({
                    name: item.intermediate1,
                    count: item.count
                });
            }
            if (item.fluent1 || item.fluent1 == "") {
                fluent1Result.push({
                    name: item.fluent1,
                    count: item.count
                });
            }
            if (item.native1 || item.native1 == "") {
                nativeLangResult.push({
                    name: item.native1,
                    count: item.count
                });
            }
            if (item.industries || item.industries == "") {
                industriesResult.push({
                    name: item.industries,
                    count: item.count
                });
            }
            if (item.latest_job || item.latest_job == "") {
                currentJobResult.push({
                    name: item.latest_job,
                    count: item.count
                });
            }
            if (item.career_level || item.career_level == "") {
                careerLResult.push({
                    name: item.career_level,
                    count: item.count
                });
            }
            if (item.education || item.education == "") {
                educationResult.push({
                    name: item.education,
                    count: item.count
                });
            }
            if (item.visaStatus || item.visaStatus == "") {
                visaStatusResult.push({
                    name: item.visaStatus,
                    count: item.count
                });
            }
            if (item.nationality || item.nationality == "") {
                nationalityResult.push({
                    name: item.nationality,
                    count: item.count
                });
            }
            if (item.c_country || item.c_country == "") {
                countryResult.push({
                    name: item.c_country,
                    count: item.count
                });
            }
            if (item.c_city || item.c_city == "") {
                cityResult.push({
                    name: item.c_city,
                    count: item.count
                });
            }
            if (item.amId || item.amId == "") {
                amResult.push({
                    id: item.amId,
                    name: item.amFirstName,
                    userImg: item.amProfileImage,
                    count: item.count
                });
            }
            if (item.canId || item.canId == "") {
                canIdResult.push({
                    canId: item.canId,
                });
            }
        }

        categoryResult.sort(function (a, b) {
            return b.count - a.count;
        });
        cpResult.sort(function (a, b) {
            return b.count - a.count;
        });
        caseResult.sort(function (a, b) {
            return b.count - a.count;
        });
        csResult.sort(function (a, b) {
            return b.count - a.count;
        });
        cvSourceResult.sort(function (a, b) {
            return b.count - a.count;
        });
        cityResult.sort(function (a, b) {
            return b.count - a.count;
        });
        countryResult.sort(function (a, b) {
            return b.count - a.count;
        });
        nationalityResult.sort(function (a, b) {
            return b.count - a.count;
        });
        visaStatusResult.sort(function (a, b) {
            return b.count - a.count;
        });
        careerLResult.sort(function (a, b) {
            return b.count - a.count;
        });
        industriesResult.sort(function (a, b) {
            return b.count - a.count;
        });
        nativeLangResult.sort(function (a, b) {
            return b.count - a.count;
        });
        currentJobResult.sort(function (a, b) {
            return b.count - a.count;
        });
        fluent1Result.sort(function (a, b) {
            return b.count - a.count;
        });
        recordYearResult.sort(function (a, b) {
            return b.count - a.count;
        });
        genderResult.sort(function (a, b) {
            return b.count - a.count;
        });
        interviewResult.sort(function (a, b) {
            return b.count - a.count;
        });
        candidateLResult.sort(function (a, b) {
            return b.count - a.count;
        });
        crmProfileResult.sort(function (a, b) {
            return b.count - a.count;
        });
        profileStatusResult.sort(function (a, b) {
            return b.count - a.count;
        });
        preoriyResult.sort(function (a, b) {
            return b.count - a.count;
        });
        intermediateResult.sort(function (a, b) {
            return b.count - a.count;
        });
        amResult.sort(function (a, b) {
            return b.count - a.count;
        });
        educationResult.sort(function (a, b) {
            return b.count - a.count;
        });
        rcResult.sort(function (a, b) {
            return b.count - a.count;
        });

        setAmUserList(amResult)
        setRcUserList(rcResult)
        setRecordYearlist(recordYearResult)
        setMonthsList(monthsList)
        setGenderList(genderResult)
        setInterviewsList(interviewResult)
        setCandidateList(candidateLResult)
        setCrmProfileList(crmProfileResult)
        setProfileStatusList(profileStatusResult)
        setPriorityList(preoriyResult)
        setIntermediate1List(intermediateResult)
        setFluentLanguageList(fluent1Result)
        setNativeLanguageList(nativeLangResult)
        setIndustriesList(industriesResult)
        setCurrentJobsList(currentJobResult)
        setCareerLevelList(careerLResult)
        setEducationList(educationResult)
        setVisaStatusList(visaStatusResult)
        setNationalityList(nationalityResult)
        setCountryList(countryResult)
        setCityList(cityResult)
        setCsList(csResult)
        setCasestatusList(caseResult)
        setCvSourceList(cvSourceResult)
        setCategoryList(categoryResult)
        setCpList(cpResult)
        setTotalCount(totalNumber)
        setCanIdList(canIdResult)


        // // const companyResult = [];
        // // const companymap = new Map();
        // // for (const item of list) {
        // //     if (!companymap.has(item.company)) {
        // //         companymap.set(item.company, true);
        // //         companyResult.push({
        // //             name: (item.company != '') ? item.company : 'Blank',
        // //             count: list.filter(function (val) {
        // //                 return val.company === item.company;
        // //             }).length
        // //         });
        // //     }
        // // }
        // // companyResult.sort(function (a, b) {
        // //     return b.count - a.count;
        // // });
        // // setCompanyList(companyResult)

        // const statusResult = [];
        // const statusmap = new Map();
        // for (const item of list) {
        //     if (!statusmap.has(item.status)) {
        //         statusmap.set(item.status, true);
        //         statusResult.push({
        //             name: (item.status != '') ? item.status : 'Blank',
        //             count: list.filter(function (val) {
        //                 return val.status === item.status;
        //             }).length
        //         });
        //     }
        // }
        // statusResult.sort(function (a, b) {
        //     return b.count - a.count;
        // });
        // setStatusList(statusResult)

        const langResult = [];
        const langmap = new Map();
        for (const item of list) {
            if (!langmap.has(item.lang)) {
                langmap.set(item.lang, true);
                langResult.push({
                    name: (item.lang != '') ? item.lang : 'Blank',
                    count: list.filter(function (val) {
                        return val.lang === item.lang;
                    }).length
                });
            }
        }
        langResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setLangList(langResult)



        const projectResult = [];
        const projectmap = new Map();
        for (const item of list) {
            if (!projectmap.has(item.project)) {
                projectmap.set(item.project, true);
                projectResult.push({
                    name: (item.project != '') ? item.project : 'Blank',
                    count: list.filter(function (val) {
                        return val.project === item.project;
                    }).length
                });
            }
        }
        projectResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setProjectList(projectResult)

        const roleResult = [];
        const rolemap = new Map();
        for (const item of list) {
            if (!rolemap.has(item.role)) {
                rolemap.set(item.role, true);
                roleResult.push({
                    name: (item.role != '') ? item.role : 'Blank',
                    count: list.filter(function (val) {
                        return val.role === item.role;
                    }).length
                });
            }
        }
        roleResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setRoleList(roleResult)

        setNewLoading(false);

    }, [list])

    const getRequestParams = (filter) => {
        let params = {};
        params["filter"] = filter;
        return params;
    };

    const isEmpty = (obj) => {
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) return false;
        }
        return true;
    };

    useEffect(() => {
        async function fetchData() {
            try {
                const params = getRequestParams(filter);
                dispatch(databaseSearchList(params));
            } catch (err) {
                console.log(err);
            }
        }
        if (isEmpty(filter)) {
            fetchData();
        }
    }, [filter]);


    useEffect(() => {
        (async () => {
            if (removeFilter > 0 && isEmpty(filter)) {
                try {
                    const params = getRequestParams(filter);
                    await dispatch(databaseSearchList(params))
                    setNewLoading(false);
                } catch (err) {
                    console.log(err);
                }
            }
        })();
    }, [removeFilter]);

    const getFilterRequestParams = (
        companyfilter
    ) => {
        let params = {};
        params["filter"] = {
            companyfilter: companyfilter,
        };
        return params;
    };

    const selectFilter = async (e) => {
        const type = e.target.dataset.type;
        const value = e.target.dataset.value;
        setNewLoading(true);
        let i;
        switch (type) {
            case "category":
                setIsCategoryFilter(true)
                setCategoryFilterText(value ? value : "Blank")
                setCompanyfilter({ ...companyfilter, [type]: value });
                setFilter({ ...filter, [type]: value });
                break;

            case "caseStatus":
                setIsCaseStatusFilter(true)
                setCaseStatusFilterText(value ? value : "Blank")
                setCompanyfilter({ ...companyfilter, [type]: value });
                setFilter({ ...filter, [type]: value });
                break;

            case 'year':
                setIsRecordYearFilter(true)
                setRecordYearFilterText(value ? value : "Blank")
                setCompanyfilter({ ...companyfilter, [type]: value });
                setFilter({ ...filter, [type]: value });
                break;
            case 'month':
                setIsRecordMonthFilter(true)
                setRecordMonthFilterText(value ? value : "Blank")
                setCompanyfilter({ ...companyfilter, [type]: value });
                setFilter({ ...filter, [type]: value });
                break;

            case 'cv_source':
                setIsCvSourceFilter(true)
                setCvSourceFilterText(value ? value : "Blank")
                setCompanyfilter({ ...companyfilter, [type]: value });
                setFilter({ ...filter, [type]: value });
                break;

            case 'am_id':
                setIsAccountManagerFilter(true)
                setAccountManagerFilterText(e.target.dataset.name)
                setCompanyfilter({ ...companyfilter, [type]: value });
                setFilter({ ...filter, [type]: value });
                break;

            case 'rec_id':
                setIsRecruiterFilter(true)
                setRecruiterFilterText(e.target.dataset.name)
                setCompanyfilter({ ...companyfilter, [type]: value });
                setFilter({ ...filter, [type]: value });
                break;

            case 'case_progress':
                setIsCaseProgressFilter(true)
                setCaseProgressFilterText(value ? value : "Blank")
                setCompanyfilter({ ...companyfilter, [type]: value });
                setFilter({ ...filter, [type]: value });
                break;

            case 'case_stage':
                setIsCaseStageFilter(true)
                setCaseStageFilterText(value ? value : "Blank")
                setCompanyfilter({ ...companyfilter, [type]: value });
                setFilter({ ...filter, [type]: value });
                break;

            case 'c_city':
                setIsCityFilter(true)
                setCityFilterText(value ? value : "Blank")
                setCompanyfilter({ ...companyfilter, [type]: value });
                setFilter({ ...filter, [type]: value });
                break;

            case 'c_country':
                setIsCountryFilter(true)
                setCountryFilterText(value ? value : "Blank")
                setCompanyfilter({ ...companyfilter, [type]: value });
                setFilter({ ...filter, [type]: value });
                break;

            case 'nationality':
                setIsNationalityFilter(true)
                setNationalityFilterText(value ? value : "Blank")
                setCompanyfilter({ ...companyfilter, [type]: value });
                setFilter({ ...filter, [type]: value });
                break;

            case 'visaStatus':
                setIsVisaStatusFilter(true)
                setVisaStatusFilterText(value ? value : "Blank")
                setCompanyfilter({ ...companyfilter, [type]: value });
                setFilter({ ...filter, [type]: value });
                break;

            case 'education':
                setIsEducationFilter(true)
                setEducationFilterText(value ? value : "Blank")
                setCompanyfilter({ ...companyfilter, [type]: value });
                setFilter({ ...filter, [type]: value });
                break;

            case 'career_level':
                setIsCareerLevelFilter(true)
                setCareerLevelFilterText(value ? value : "Blank")
                setCompanyfilter({ ...companyfilter, [type]: value });
                setFilter({ ...filter, [type]: value });
                break;

            case 'latest_job':
                setIsCurrentJobFilter(true)
                setCurrentJobFilterText(value ? value : "Blank")
                setCompanyfilter({ ...companyfilter, [type]: value });
                setFilter({ ...filter, [type]: value });
                break;

            case 'industries':
                setIsIndustriesFilter(true)
                setIndustriesFilterText(value ? value : "Blank")
                setCompanyfilter({ ...companyfilter, [type]: value });
                setFilter({ ...filter, [type]: value });
                break;

            case 'native1':
                setIsNative1Filter(true)
                setNative1FilterText(value ? value : "Blank")
                setCompanyfilter({ ...companyfilter, [type]: value });
                setFilter({ ...filter, [type]: value });
                break;
            case 'fluent1':
                setIsFluent1Filter(true)
                setFluent1FilterText(value ? value : "Blank")
                setCompanyfilter({ ...companyfilter, [type]: value });
                setFilter({ ...filter, [type]: value });
                break;

            case 'intermediate1':
                setIsIntermediateLang(true)
                setIsIntermediateLangFilterText(value ? value : "Blank")
                setCompanyfilter({ ...companyfilter, [type]: value });
                setFilter({ ...filter, [type]: value });
                break;

            case 'priority':
                setIsExperienceFilter(true)
                setIsExperienceFilterText(value ? value : "Blank")
                setCompanyfilter({ ...companyfilter, [type]: value });
                setFilter({ ...filter, [type]: value });
                break;

            case 'profileIndicator':
                setIsCrmProfileFilterFilter(true)
                setCrmProfileFilterText(e.target.dataset.name)
                setCompanyfilter({ ...companyfilter, [type]: value });
                setFilter({ ...filter, [type]: value });
                break;

            case 'motivationStatus':
                setIsMotivationFilter(true)
                setMotivationFilterText(e.target.dataset.name)
                setCompanyfilter({ ...companyfilter, [type]: value });
                setFilter({ ...filter, [type]: value });
                break;

            case 'gender':
                setIsGenderFilter(true)
                setGenderFilterText(value ? value : "Blank")
                setCompanyfilter({ ...companyfilter, [type]: value });
                setFilter({ ...filter, [type]: value });
                break;

            case 'flag_type':
                setIsInterviewFilter(true)
                setInterviewFilterText(e.target.dataset.name)
                setCompanyfilter({ ...companyfilter, [type]: value });
                setFilter({ ...filter, [type]: value });
                break;

            case 'profileStatus':
                setIsCasePriorityFilter(true)
                setCasePriorityFilterText(e.target.dataset.name)
                setCompanyfilter({ ...companyfilter, [type]: value });
                setFilter({ ...filter, [type]: value });
                break;

            default:
                i = filterTags.findIndex(function (x) {
                    return x.type === type;
                });
                if (i === -1) {
                    filterTags.push({ type, value });
                }
                setFilter({ ...filter, [type]: value });
                break;
        }
        setTagLength(tagLength + 1);
        setFilterTags(filterTags);
    };

    useEffect(() => {
        (async () => {
            if (!isEmpty(filter)) {
                const params = getFilterRequestParams(
                    companyfilter
                );
                await dispatch(databaseSearchList(params));
                setNewLoading(false);
            }
        })();
    }, [filter, removeFilter]);

    const removeTag = async (e) => {
        const type = e.target.dataset.type;
        delete companyfilter[type];
        delete filter[type];
        setFilter(filter);
        setRemoveFilter(removeFilter + 1);
        setNewLoading(true);

        switch (type) {
            case "category":
                setIsCategoryFilter(false)
                setCategoryFilterText('Category')
                setCompanyfilter(companyfilter);
                break;

            case 'caseStatus':
                setIsCaseStatusFilter(false)
                setCaseStatusFilterText('Case Status')
                setCompanyfilter(companyfilter);
                break;

            case 'case_progress':
                setIsCaseProgressFilter(false)
                setCaseProgressFilterText('Case Progress')
                setCompanyfilter(companyfilter);
                break;

            case 'case_stage':
                setIsCaseStageFilter(false)
                setCaseStageFilterText('Case Stage')
                setCompanyfilter(companyfilter);
                break;

            case 'c_city':
                setIsCityFilter(false)
                setCityFilterText('City')
                setCompanyfilter(companyfilter);
                break;

            case 'c_country':
                setIsCountryFilter(false)
                setCountryFilterText('Country')
                setCompanyfilter(companyfilter);
                break;

            case 'nationality':
                setIsNationalityFilter(false)
                setNationalityFilterText('Nationality')
                setCompanyfilter(companyfilter);
                break;

            case 'visaStatus':
                setIsVisaStatusFilter(false)
                setVisaStatusFilterText('Visa Status')
                setCompanyfilter(companyfilter);
                break;

            case 'career_level':
                setIsCareerLevelFilter(false)
                setCareerLevelFilterText('Career Level')
                setCompanyfilter(companyfilter);
                break;

            case 'latest_job':
                setIsCurrentJobFilter(false)
                setCurrentJobFilterText('Current Job')
                setCompanyfilter(companyfilter);
                break;

            case 'industries':
                setIsIndustriesFilter(false)
                setIndustriesFilterText('Industries')
                setCompanyfilter(companyfilter);
                break;

            case 'native1':
                setIsNative1Filter(false)
                setNative1FilterText('Native Language')
                setCompanyfilter(companyfilter);
                break;

            case 'fluent1':
                setIsFluent1Filter(false)
                setFluent1FilterText('Fluent Language')
                setCompanyfilter(companyfilter);
                break;

            case 'gender':
                setIsGenderFilter(false)
                setGenderFilterText('Gender')
                setCompanyfilter(companyfilter);
                break;

            case 'profileStatus':
                setIsCasePriorityFilter(false)
                setCasePriorityFilterText('Case Priority')
                setCompanyfilter(companyfilter);
                break;

            case 'profileIndicator':
                setIsCrmProfileFilterFilter(false)
                setCrmProfileFilterText('CRM Profile')
                setCompanyfilter(companyfilter);
                break;

            case 'motivationStatus':
                setIsMotivationFilter(false)
                setMotivationFilterText('Candidate')
                setCompanyfilter(companyfilter);
                break;

            case 'flag_type':
                setIsInterviewFilter(false)
                setInterviewFilterText('Interviews')
                setCompanyfilter(companyfilter);
                break;

            case 'priority':
                setIsExperienceFilter(false)
                setIsExperienceFilterText('Experience')
                setCompanyfilter(companyfilter);
                break;

            case 'intermediate1':
                setIsIntermediateLang(false)
                setIsIntermediateLangFilterText('Intermediate Language')
                setCompanyfilter(companyfilter);
                break;

            case 'education':
                setIsEducationFilter(false)
                setEducationFilterText('Education')
                setCompanyfilter(companyfilter);
                break;

            case 'cv_source':
                setIsCvSourceFilter(false)
                setCvSourceFilterText('CV Source')
                setCompanyfilter(companyfilter);
                break;

            case 'year':
                setIsRecordYearFilter(false)
                setRecordYearFilterText('Record Year')
                setCompanyfilter(companyfilter);
                break;
            case 'month':
                setIsRecordMonthFilter(false)
                setRecordMonthFilterText('Record Month')
                setCompanyfilter(companyfilter);
                break;

            case 'status':
                setIsPipelineFilter(false)
                setCaseStatusFilterText('')
                break;

            case 'flag_type':
                setIsInterviewFilter(false)
                setInterviewFilterText('Interviews')
                setCompanyfilter(companyfilter);
                break;

            case 'profileIndicator':
                setIsCrmProfileFilterFilter(false)
                setCrmProfileFilterText('CRM Profile')
                setCompanyfilter(companyfilter);
                break;

            case 'motivationStatus':
                setIsMotivationFilter(false)
                setMotivationFilterText('Candidate')
                setCompanyfilter(companyfilter);
                break;

            case 'am_id':
                setIsAccountManagerFilter(false)
                setAccountManagerFilterText('Account Manager')
                setCompanyfilter(companyfilter);
                break;

            default:
                delete filter[type];
                setFilter(filter);
                setRemoveFilter(removeFilter + 1);
                break;
        }
        setTagLength(tagLength - 1);
        setFilterTags(filterTags.filter((item) => item.type !== type));
    };

    return (
        <>
            {
                (isAddNew) ? <CreateNewModal hideModal={hideAddNewModal} canIdList={canIdList} allCandidate={canIdList.length} /> : null
            }

            <div className="dkg-searchdb-page-mainCon container-fluid" id='dkg-cvdb-mainDIV'>
                {/* <CircularProgressBar /> */}
                <div className='dkg-srchcv-progressBarMain'>
                    <div id="dkg-srchcv-progressBar">
                        <h2 className='dkg-loadingtext'>
                        </h2>
                    </div>
                </div>
                <div className='row dkg-searchdb-page-Con'>
                    <div className="col-12 dkg-searchdb-boxCon">
                        <div className='dkg-searchdb-topHeaderCon'>

                            {
                                databaseSearchCvList.length === 0 ?
                                    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true} >
                                        <AnimationProgress />
                                    </Backdrop>
                                    : newLoading ? <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true} >
                                        <AnimationProgress />
                                    </Backdrop> : ""
                            }
                            <div className='dkg-center-searchdb'>
                                <Link to='#' className='dkg-searchdb-totalCount'>Total Records - {totalCount}</Link>
                            </div>
                            <div className='dkg-searchdb-searchboxCon'>
                                <div className="input-group dkg-srchinput-grp">
                                    <input type="text" className="form-control dkg-srchinput" placeholder="Search with Keywords" aria-label="Search Keywords" />
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-search"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="dk-create-project-btnCon">
                                <button onClick={showAddNewModal} className='dk-create-project-btn'>Create Project List<i className="fa fa-plus ml-2"></i></button>
                            </div>
                        </div>
                        <div className="dkg-search-cv-maincontent">
                            <h2 className='dkg-searchdb-filter-title d-none' style={{ marginTop: "10px" }}>CANDIDATE PROFILE</h2>
                            <div className="dkg-searchdb-leftCon">
                                <div className="dkg-searchdb-filter-cols dkg-srchdb-hideSrchInput">
                                    <SelectWithSearchDropdown selectFilter={selectFilter} totalCount={totalCount} removeTag={removeTag} isFilter={isRecordYearFilter} title={recordYearFilterText} list={recordYearlist} column="recordYear" />
                                </div>
                                <div className="dkg-searchdb-filter-cols dkg-srchdb-hideSrchInput">
                                    <SelectWithSearchDropdown selectFilter={selectFilter} removeTag={removeTag} isFilter={isRecordMonthFilter} title={recordYearMonthFilterText}  list={monthsList} column="Months" />
                                </div>
                                <div className="dkg-searchdb-filter-cols dkg-srchdb-hideSrchInput">
                                    <SelectWithSearchDropdown selectFilter={selectFilter} totalCount={totalCount} removeTag={removeTag} isFilter={isRecordYearFilter} title={pipelineFilterText} list={recordYearlist} column="Pipelines" />
                                </div>
                            </div>
                            <div className="dkg-searchdb-leftCon" style={{marginBottom: "45px"}}>
                                <div className="dkg-searchdb-filter-cols dkg-srchdb-hideSrchInput">
                                    <SelectWithSearchDropdown selectFilter={selectFilter} totalCount={totalCount} removeTag={removeTag} isFilter={isRecruiterFilter} title={recruiterFilterText} list={rcUserList} column="rcAdmin" />
                                </div>
                                <div className="dkg-searchdb-filter-cols dkg-srchdb-hideSrchInput">
                                    <SelectWithSearchDropdown selectFilter={selectFilter} totalCount={totalCount} removeTag={removeTag} isFilter={isRecruiterFilter} title={recruiterOneFilterText} list={rcUserList} column="recruiter-one" />
                                </div>
                                <div className="dkg-searchdb-filter-cols dkg-srchdb-hideSrchInput">
                                    <SelectWithSearchDropdown selectFilter={selectFilter} totalCount={totalCount} removeTag={removeTag} isFilter={isAccountManagerFilter} title={accountManagerFilterText} list={amUserList} column="accountManager" />
                                </div>
                            </div>
                            <div className="dkg-searchdb-leftCon">
                                <div className="dkg-searchdb-filter-cols dkg-srchdb-hideSrchInput">
                                    {
                                        <SelectWithSearchDropdown selectFilter={selectFilter} totalCount={totalCount} removeTag={removeTag} isFilter={isCategoryFilter} title={categoryFilterText} list={categoryList} column="category" />
                                    }
                                </div>
                                <div className="dkg-searchdb-filter-cols dkg-srchdb-hideSrchInput">
                                    <SelectWithSearchDropdown selectFilter={selectFilter} removeTag={removeTag} isFilter={isCaseProgressFilter} totalCount={totalCount} title={CaseSituationFilterText} list={cpList} column="case_situation" />
                                </div>
                                <div className="dkg-searchdb-filter-cols dkg-srchdb-hideSrchInput">
                                    <SelectWithSearchDropdown selectFilter={selectFilter} totalCount={totalCount} removeTag={removeTag} isFilter={isCaseStatusFilter} title={CaseStatusFilterText} list={casestatusList} column="caseStatus" />
                                </div>
                                <div className="dkg-searchdb-filter-cols dkg-srchdb-hideSrchInput">
                                    <SelectWithSearchDropdown selectFilter={selectFilter} totalCount={totalCount} removeTag={removeTag} isFilter={isCaseStageFilter} title={CaseStageFilterText} list={csList} column="case_stage" />
                                </div> 
                                <div className="dkg-searchdb-filter-cols dkg-srchdb-hideSrchInput">
                                    <SelectWithSearchDropdown selectFilter={selectFilter} removeTag={removeTag} isFilter={isCaseProgressFilter} totalCount={totalCount} title={CaseProgressFilterText} list={cpList} column="case_progress" />
                                </div>
                            </div>
                            <div className="dkg-searchdb-leftCon">
                                <div className="dkg-searchdb-filter-cols dkg-srchdb-hideSrchInput">
                                    <SelectWithSearchDropdown selectFilter={selectFilter} totalCount={totalCount} removeTag={removeTag} isFilter={isCvSourceFilter} title={cvSourceFilterText} list={cvSourceList} column="cv_source" />
                                </div>
                                <div className="dkg-searchdb-filter-cols dkg-srchdb-hideSrchInput">
                                    <SelectWithSearchDropdown selectFilter={selectFilter} removeTag={removeTag} isFilter={isCountryFilter} totalCount={totalCount} title={countryFilterText} list={countryList} column="c_country" />
                                </div>
                                <div className="dkg-searchdb-filter-cols dkg-srchdb-hideSrchInput">
                                    <SelectWithSearchDropdown selectFilter={selectFilter} removeTag={removeTag} isFilter={isCityFilter} totalCount={totalCount} title={cityFilterText} list={cityList} column="c_city" />
                                </div>
                                <div className="dkg-searchdb-filter-cols dkg-srchdb-hideSrchInput">
                                    <SelectWithSearchDropdown selectFilter={selectFilter} totalCount={totalCount} removeTag={removeTag} isFilter={isIndustriesFilter} title={industriesFilterText} list={industriesList} column="industries" />
                                </div>
                                <div className="dkg-searchdb-filter-cols dkg-srchdb-hideSrchInput">
                                    <SelectWithSearchDropdown selectFilter={selectFilter} totalCount={totalCount} removeTag={removeTag} isFilter={isVisaStatusFilter} title={visaStatusFilterText} list={visaStatusList} column="visaStatus" />
                                </div>
                            </div>
                            <div className="dkg-searchdb-leftCon">
                                <div className="dkg-searchdb-filter-cols dkg-srchdb-hideSrchInput">
                                    <SelectWithSearchDropdown selectFilter={selectFilter} totalCount={totalCount} removeTag={removeTag} isFilter={isEducationFilter} title={educationFilterText} list={educationList} column="education" />
                                </div>

                                <div className="dkg-searchdb-filter-cols dkg-srchdb-hideSrchInput">
                                    <SelectWithSearchDropdown selectFilter={selectFilter} totalCount={totalCount} removeTag={removeTag} isFilter={isCareerLevelFilter} title={careerLevelFilterText} list={careerLevelList} column="careerLevel" />
                                </div>
                                <div className="dkg-searchdb-filter-cols dkg-srchdb-hideSrchInput">
                                    <SelectWithSearchDropdown selectFilter={selectFilter} totalCount={totalCount} removeTag={removeTag} isFilter={isCurrentJobFilter} title={currentJobFilterText} list={currentJobsList} column="latest_job" />
                                </div>
                                <div className="dkg-searchdb-filter-cols dkg-srchdb-hideSrchInput">
                                    <SelectWithSearchDropdown selectFilter={selectFilter} totalCount={totalCount} removeTag={removeTag} isFilter={isExperienceFilter} title={experienceFilterText} list={priorityList} column="priority" />
                                </div>
                                <div className="dkg-searchdb-filter-cols dkg-srchdb-hideSrchInput">
                                    <SelectWithSearchDropdown selectFilter={selectFilter} totalCount={totalCount} removeTag={removeTag} isFilter={isNationalityFilter} title={nationalityFilterText} list={nationalityList} column="nationality" />
                                </div>
                            </div>
                            <div className="dkg-searchdb-leftCon">
                                {/* <div className="dkg-searchdb-filter-cols dkg-srchdb-hideSrchInput">
                                    <SelectWithSearchDropdown selectFilter={selectFilter} totalCount={totalCount} title={itSkill} list={[]} column="itSkill" />
                                </div> */}
                                <div className="dkg-searchdb-filter-cols dkg-srchdb-hideSrchInput">
                                    <SelectWithSearchDropdown selectFilter={selectFilter} totalCount={totalCount} removeTag={removeTag} isFilter={isNative1Filter} title={native1FilterText} list={nativeLanguageList} column="native1" />
                                </div>
                                <div className="dkg-searchdb-filter-cols dkg-srchdb-hideSrchInput">
                                    <SelectWithSearchDropdown selectFilter={selectFilter} totalCount={totalCount} removeTag={removeTag} isFilter={isFluent1Filter} title={fluent1FilterText} list={fluentLanguageList} column="fluent1" />
                                </div>
                                <div className="dkg-searchdb-filter-cols dkg-srchdb-hideSrchInput">
                                    <SelectWithSearchDropdown selectFilter={selectFilter} totalCount={totalCount} removeTag={removeTag} isFilter={isIntermediateLangFilter} title={isIntermediateLangFilterText} list={intermediate1List} column="intermediate1" />
                                </div>
                                <div className="dkg-searchdb-filter-cols dkg-srchdb-hideSrchInput">
                                    <SelectWithSearchDropdown selectFilter={selectFilter} totalCount={totalCount} title={jobskill} list={[]} column="jobskill" />
                                </div>
                                <div className="dkg-searchdb-filter-cols dkg-srchdb-hideSrchInput">
                                    <SelectWithSearchDropdown selectFilter={selectFilter} totalCount={totalCount} title={itSkill} list={[]} column="techSkill" />
                                </div>
                            </div>
                            {/* <div className="dkg-searchdb-leftCon">
                                <div className="dkg-searchdb-filter-cols dkg-srchdb-hideSrchInput">
                                    <SelectWithSearchDropdown selectFilter={selectFilter} totalCount={totalCount} removeTag={removeTag} isFilter={isInterviewFilter} title={interviewFilterText} list={interviewsList} column="flag_type" />
                                </div>
                                <div className="dkg-searchdb-filter-cols dkg-srchdb-hideSrchInput">
                                    <SelectWithSearchDropdown selectFilter={selectFilter} totalCount={totalCount} removeTag={removeTag} isFilter={isGenderFilter} title={genderFilterText} list={genderList} column="gender" />
                                </div>

                            </div> */}
                            {/* <h2 className='dkg-searchdb-filter-title' style={{ marginTop: "30px" }}>JOB APPLIED FOR</h2>*/}
                            <div className="dkg-searchdb-leftCon">
                                <div className="dkg-searchdb-filter-cols dkg-srchdb-hideSrchInput">
                                    <SelectWithSearchDropdown selectFilter={selectFilter} totalCount={totalCount} removeTag={removeTag} isFilter={isCrmProfileFilter} title={crmProfileFilterText} list={crmProfileList} column="profileIndicator" />
                                </div>
                                <div className="dkg-searchdb-filter-cols dkg-srchdb-hideSrchInput">
                                    <SelectWithSearchDropdown selectFilter={selectFilter} totalCount={totalCount} removeTag={removeTag} isFilter={isMotivationFilter} title={motivationFilterText} list={candidateList} column="motivationStatus" />
                                </div>
                                <div className="dkg-searchdb-filter-cols dkg-srchdb-hideSrchInput">
                                    {/* <SelectWithSearchDropdown selectFilter={selectFilter} totalCount={totalCount} title={jobskill} list={[]} column="jobskill" /> */}
                                    <SelectWithSearchDropdown selectFilter={selectFilter} totalCount={totalCount} removeTag={removeTag} isFilter={isInterviewFilter} title={interviewFilterText} list={interviewsList} column="flag_type" />
                                </div>
                                <div className="dkg-searchdb-filter-cols dkg-srchdb-hideSrchInput">
                                    <SelectWithSearchDropdown selectFilter={selectFilter} totalCount={totalCount} removeTag={removeTag} isFilter={isCasePriorityFilter} title={casePriorityFilterText} list={profileStatusList} column="profileStatus" />
                                </div>
                                <div className="dkg-searchdb-filter-cols dkg-srchdb-hideSrchInput">
                                    <SelectWithSearchDropdown selectFilter={selectFilter} totalCount={totalCount} title={isemailOneVerify} list={[]} column="emailoneverify" />
                                </div>
                            </div>
                            <div className="dkg-searchdb-leftCon" style={{marginTop: "45px"}}>
                                <div className="dkg-searchdb-filter-cols dkg-srchdb-hideSrchInput dkg-srchdb-openUpper-Dropdown">
                                    <SelectWithSearchDropdown selectFilter={selectFilter} totalCount={totalCount} removeTag={removeTag} isFilter={isCompanyFilter} title={companyFilterText} list={companyList} column="company" />
                                </div>
                                <div className="dkg-searchdb-filter-cols dkg-srchdb-hideSrchInput dkg-srchdb-openUpper-Dropdown">
                                    <SelectWithSearchDropdown selectFilter={selectFilter} totalCount={totalCount} removeTag={removeTag} isFilter={isLocalityFilter} title={localityFilterText} list={jobLocalityList} column="locality" />
                                </div>
                                <div className="dkg-searchdb-filter-cols dkg-srchdb-hideSrchInput dkg-srchdb-openUpper-Dropdown">
                                    {/* <SelectWithSearchDropdown selectFilter={selectFilter} totalCount={totalCount} removeTag={removeTag} isFilter={isJobTitleFilter} title={jobTitleFilterText} list={jobTitleList} column="name" /> */}
                                    <SelectWithSearchDropdown selectFilter={selectFilter} totalCount={totalCount} removeTag={removeTag} isFilter={isRoleFilter} title={roleFilterText} list={roleList} column="role" />
                                </div>
                                <div className="dkg-searchdb-filter-cols dkg-srchdb-hideSrchInput dkg-srchdb-openUpper-Dropdown">
                                    {/* <SelectWithSearchDropdown selectFilter={selectFilter} totalCount={totalCount} removeTag={removeTag} isFilter={isJobCityFilter} title={jobCityFilterText} list={jobCityList} column="location" /> */}
                                    <SelectWithSearchDropdown selectFilter={selectFilter} totalCount={totalCount} removeTag={removeTag} isFilter={isProjectFilter} title={projectFilterText} list={projectList} column="project" />
                                </div>
                                <div className="dkg-searchdb-filter-cols dkg-srchdb-hideSrchInput dkg-srchdb-openUpper-Dropdown">
                                    {/* <SelectWithSearchDropdown selectFilter={selectFilter} totalCount={totalCount} removeTag={removeTag} isFilter={isJobCountryFilter} title={jobCountryFilterText} list={jobCountryList} column="job_country" /> */}
                                    <SelectWithSearchDropdown selectFilter={selectFilter} totalCount={totalCount} removeTag={removeTag} isFilter={isLanguageFilter} title={languageFilterText} list={langList} column="lang" />
                                </div>
                                {/* <div className="dkg-searchdb-filter-cols dkg-srchdb-hideSrchInput dkg-srchdb-openUpper-Dropdown">
                                    <SelectWithSearchDropdown selectFilter={selectFilter} totalCount={totalCount} removeTag={removeTag} isFilter={isRoleFilter} title={roleFilterText} list={roleList} column="role" />
                                </div>
                                <div className="dkg-searchdb-filter-cols dkg-srchdb-hideSrchInput dkg-srchdb-openUpper-Dropdown">
                                    <SelectWithSearchDropdown selectFilter={selectFilter} totalCount={totalCount} removeTag={removeTag} isFilter={isProjectFilter} title={projectFilterText} list={projectList} column="project" />
                                </div>
                                <div className="dkg-searchdb-filter-cols dkg-srchdb-hideSrchInput dkg-srchdb-openUpper-Dropdown">
                                    <SelectWithSearchDropdown selectFilter={selectFilter} totalCount={totalCount} removeTag={removeTag} isFilter={isLanguageFilter} title={languageFilterText} list={langList} column="lang" />
                                </div> */}
                            </div>
                            </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Index;
