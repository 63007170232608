import React, { useEffect, useState, useMemo, lazy, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../pipeline.scss";
import "../../../../../assets/scss/extradiv.scss";
import { FaCheck } from "react-icons/fa";
import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  usePagination,
  useAsyncDebounce,
} from "react-table";
import { ButtonGroup, Button, Dropdown } from "react-bootstrap";
import {
  indicatorColor,
  flagColor,
  profileStatus,
  profileMotivationStatus,
} from "../../../../../helper/commonFunctions";
import CustomDateFormat from "../../../../ui/dateFormats/CustomDateFormat";
import ProfileIndicatorOptions from "../../../../ui/profileIndicatorOptions";
import FlagOptions from "../../../../ui/flagOptions";
import UserList from "../../../../ui/userList/UserListByRoleForCandidates";
import CaseStatus from "../dropdown/caseStatus";
import CaseOther from "../dropdown/other";
import Pagination from "@material-ui/lab/Pagination";
import RecruiterAdminFilter from "../tableFilters/users/recruiterAdminFilter";
import RecruiterFilter from "../tableFilters/users/recruiterFilter";
import AccountManagerFilter from "../tableFilters/users/accountManagerFilter";
import ProfileIndicatorFilter from "../tableFilters/profileIndicator";
import FlagFilter from "../tableFilters/flag";
import ShowPipelineName from "../dropdown/pipeline";
import CaseStatusFilter from "../tableFilters/statusfilter";
import AdvanceFilterModal from "../tableFilters/rightModalFilter";
import Checkbox from "../utils/checkbox";
import EditableCell from "../utils/editCell";
import CandidateProfile from "../modal/candidateprofile";
import CandidateDocumentModal from "../modal/documents";
import CommentModal from "../../../../utils/comment";
import BulkEmailModal from "../modal/bulkemail/BulkEmail";
import CandidateFilter from "../tableFilters/candidatefilter";
import CandprofileIndicator from "../../../../ui/candprofileindicator";
import DetialViewPoup from "../../../jobs/livejobs/modal/DetialViewPoup";
import $ from "jquery";
// import { clearState, moveCandidateRecords, updateTable, updateRa, updateRc, updateAm, updateOtherdata, candidateList } from "../../../../../slice/candidates/candidatesSlice";
import {
  clearState,
  onHoldList,
  updateTable,
  moveCandidateRecords,
  moveDatabaseCandidateRecords,
  onHoldFilterList,
} from "../../../../../slice/candidates/list/listSlice";
import { commentList } from "../../../../../slice/comments/commentSlice";
//import { fetchById } from "../../../../../slice/candidatePrecreeningForm/candidatePrecreeningSlice";

import CaseStatusList from "../../../../ui/dropdownList/CaseStatusList";
import CaseProgressList from "../../../../ui/dropdownList/CaseProgressList";
import CaseStageList from "../../../../ui/dropdownList/CaseStageList";
//import DeleteModal from "../modal/delete/BulkDelete";
import TrickFilter from "../tableFilters/trickdropdown/TrickFilter";
// import checkIcon from "../../../../../assets/images/check.png";
// import CrossIcon from "../../../../../assets/images/cross.png";
// import QuestionIcon from "../../../../../assets/images/question-mark.png";
import ProfileStatusOptions from "../../../../ui/profileStatus";
import TableLoader from "../../../../ui/tableLoader";
import { showSuccess } from "../../../../../slice/utils/message/messageSlice";
import { daysAgo } from "../utils/DaysAgo";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import ProflieStatus from "../utils/profileStatus";
import CanProfilesStatus from "../../../../ui/dropdownList/CanProfilesStatus";
import UserPopup from "../modal/usermodal/UserPopup";

const TableSuspence = lazy(() =>
  import("../../../../ui/tableLoader/tableSuspence")
);

const useInstance = (instance) => {
  if (instance && instance.getInstanceCallback) {
    instance.getInstanceCallback(instance);
  }
};

function Table({
  columns,
  data,
  loading,
  getInstanceCallback,
  filter,
  raFilterId,
  rcFilterId,
  amFilterId,
  profileIndicatorFilterId,
  profileMotivationFilterId,
  profileStatusFilterId,
  flagFilterId,
  categoryId,
  caseStatusId,
  newprofilestatusId,
  monthsId,
  yearsId,
  caseProgressId,
  caseStageId,
  caseSituationId,
  companyId,
  projectId,
  roleId,
  languageId,
  cvSourceId,
  updateMyData,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    page,
    prepareRow,
    state,
    setFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      updateMyData,
      getInstanceCallback,
      autoResetPage: false,
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetRowState: false,
      initialState: {
        pageIndex: 0,
        pageSize: 100,
        hiddenColumns: [
          "RAID",
          "RCID",
          "AMID",
          "FlagID",
          "PIID",
          "PSID",
          "PMID",
        ],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    (hooks) => hooks.useInstance.push(useInstance)
  );

  const handlePageChange = (event, value) => {
    gotoPage(Number(value) - 1);
  };

  const [filterType, setFilterType] = useState("");

  useEffect(() => {
    if (raFilterId === "0") {
      setTimeout(() => {
        setFilter("RAID", "");
      }, 200);
    } else {
      setFilterType("RAID");
      doFilter(raFilterId);
    }
  }, [raFilterId]);

  useEffect(() => {
    if (rcFilterId === "0") {
      setTimeout(() => {
        setFilter("RCID", "");
      }, 200);
    } else {
      setFilterType("RCID");
      doFilter(rcFilterId);
    }
  }, [rcFilterId]);

  useEffect(() => {
    if (amFilterId === "0") {
      setTimeout(() => {
        setFilter("AMID", "");
      }, 200);
    } else {
      setFilterType("AMID");
      doFilter(amFilterId);
    }
  }, [amFilterId]);

  useEffect(() => {
    if (profileIndicatorFilterId === "0") {
      setTimeout(() => {
        setFilter("PIID", "");
      }, 200);
    } else {
      setFilterType("PIID");
      doFilter(profileIndicatorFilterId);
    }
  }, [profileIndicatorFilterId]);

  useEffect(() => {
    if (profileMotivationFilterId === "-1") {
      setTimeout(() => {
        setFilter("PMID", "");
      }, 200);
    } else {
      setFilterType("PMID");
      doFilter(profileMotivationFilterId);
    }
  }, [profileMotivationFilterId]);

  useEffect(() => {
    if (profileStatusFilterId === "-1") {
      setTimeout(() => {
        setFilter("PSID", "");
      }, 200);
    } else {
      setFilterType("PSID");
      doFilter(profileStatusFilterId);
    }
  }, [profileStatusFilterId]);

  useEffect(() => {
    if (flagFilterId === "0") {
      setTimeout(() => {
        setFilter("FlagID", "");
      }, 200);
    } else {
      setFilterType("FlagID");
      doFilter(flagFilterId);
    }
  }, [flagFilterId]);

  useEffect(() => {
    if (categoryId === "0") {
      setTimeout(() => {
        setFilter("category", "");
      }, 200);
    } else {
      setFilterType("category");
      doFilter(categoryId);
    }
  }, [categoryId]);

  useEffect(() => {
    if (caseStatusId === "0") {
      setTimeout(() => {
        setFilter("caseStatus", "");
      }, 200);
    } else {
      setFilterType("caseStatus");
      doFilter(caseStatusId);
    }
  }, [caseStatusId]);

  useEffect(() => {
    if (newprofilestatusId === "0") {
      setTimeout(() => {
        setFilter("newprofilestatus", "");
      }, 2000);
    } else {
      setFilterType("newprofilestatus");
      doFilter(newprofilestatusId);
    }
  }, [newprofilestatusId]);

  useEffect(() => {
    if (yearsId === "0") {
      setTimeout(() => {
        setFilter("year", "");
      }, 200);
    } else {
      setFilterType("year");
      doFilter(yearsId);
    }
  }, [yearsId]);

  useEffect(() => {
    if (monthsId === "0") {
      setTimeout(() => {
        setFilter("month", "");
      }, 200);
    } else {
      setFilterType("month");
      doFilter(monthsId);
    }
  }, [monthsId]);

  useEffect(() => {
    if (caseProgressId === "0") {
      setTimeout(() => {
        setFilter("case_progress", "");
      }, 200);
    } else {
      setFilterType("case_progress");
      doFilter(caseProgressId);
    }
  }, [caseProgressId]);

  useEffect(() => {
    if (caseSituationId === "0") {
      setTimeout(() => {
        setFilter("case_stage", "");
      }, 200);
    } else {
      setFilterType("case_stage");
      doFilter(caseSituationId);
    }
  }, [caseSituationId]);

  useEffect(() => {
    if (caseStageId === "0") {
      setTimeout(() => {
        setFilter("caseStatus", "");
      }, 200);
    } else {
      setFilterType("caseStatus");
      doFilter(caseStageId);
    }
  }, [caseStageId]);

  useEffect(() => {
    if (companyId === "0") {
      setTimeout(() => {
        setFilter("company", "");
      }, 200);
    } else {
      setFilterType("company");
      doFilter(companyId);
    }
  }, [companyId]);

  useEffect(() => {
    if (projectId === "0") {
      setTimeout(() => {
        setFilter("project", "");
      }, 200);
    } else {
      setFilterType("project");
      doFilter(projectId);
    }
  }, [projectId]);

  useEffect(() => {
    if (roleId === "0") {
      setTimeout(() => {
        setFilter("role", "");
      }, 200);
    } else {
      setFilterType("role");
      doFilter(roleId);
    }
  }, [roleId]);

  useEffect(() => {
    if (languageId === "0") {
      setTimeout(() => {
        setFilter("lang", "");
      }, 200);
    } else {
      setFilterType("lang");
      doFilter(languageId);
    }
  }, [languageId]);

  useEffect(() => {
    if (cvSourceId === "0") {
      setTimeout(() => {
        setFilter("cv_source", "");
      }, 200);
    } else {
      setFilterType("cv_source");
      doFilter(cvSourceId);
    }
  }, [cvSourceId]);

  const doFilter = React.useCallback(
    useAsyncDebounce((id) => {
      setFilter(filterType, id);
    }, 200),
    [filterType]
  );

  return (
    <React.Fragment>
      <table className="table dkpipeline-newCVTable" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return loading ? (
              [...Array(10)].map((e, i) => (
                <tr>
                  <TableLoader colSpan="22" />
                </tr>
              ))
            ) : (
              <Suspense fallback={<TableSuspence colSpan="22" />}>
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps([
                        {
                          className: cell.column.className, // pay attention to this
                          style: cell.column.style,
                          // set here your other custom props
                        },
                      ])}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              </Suspense>
            );
          })}
        </tbody>
        <tfoot>
          {footerGroups.map((group) => (
            <tr {...group.getFooterGroupProps()}>
              {group.headers.map((column) => (
                <td {...column.getFooterProps()}>{column.render("Footer")}</td>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
      <div className="col-sm-12">
        <div className="dk-resPaginationCon pagination">
          <div className="dk-resPagination">
            <div className="dk-resLeftPag">
              <div
                className="dk-texCounter"
                style={{ color: "#fff !imporant" }}
              >
                Showing {pageIndex + 1} to {pageOptions.length} (of{" "}
                {rows.length} entries)
              </div>
            </div>
            <div className="dk-resrightPag pagination-btns">
              <Pagination
                count={pageCount}
                shape="rounded"
                onChange={handlePageChange}
              />
              {/* <TablePagination count={pageCount} onChange={handlePageChange} /> */}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

function debounce(func, wait, immediate) {
  var timeout;

  return (...args) => {
    var context = this;

    var later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    var callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  isClearAll,
  restClearAllFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState("");

  // const [value, setValue] = React.useState(globalFilter)
  // const onChange = useAsyncDebounce(value => {
  //     setGlobalFilter(value || undefined)
  // }, 200)

  const onChangeSearchTitle = (e) => {
    const { value: nextValue } = e.target;
    setValue(e.target.value);
    debouncedSave(nextValue);
  };

  const debouncedSave = React.useCallback(
    debounce((nextValue) => {
      setGlobalFilter(nextValue);
    }, 1000)
  );

  const clearSearch = () => {
    setValue("");
    setTimeout(() => {
      setGlobalFilter(undefined);
    }, 200);
  };

  useEffect(() => {
    if (isClearAll) {
      clearSearch();
      restClearAllFilter();
    }
  }, [isClearAll]);

  return (
    <React.Fragment>
      <input
        value={value}
        className="form-control"
        onChange={onChangeSearchTitle}
        placeholder={`Search ...`}
      />
      {value !== undefined && value.length > 0 ? (
        <div className="input-group-append">
          <button
            className="btn btn-secondary"
            type="button"
            onClick={clearSearch}
          >
            <i className="fa fa-times"></i>
          </button>
        </div>
      ) : (
        <div className="input-group-append">
          <button className="btn btn-secondary" type="button">
            <i className="fa fa-search"></i>
          </button>
        </div>
      )}
    </React.Fragment>
  );
}

const OnHold = ({ presskey }) => {
  const dispatch = useDispatch();
  const {
    isLoading,
    isUpdate,
    isProfileUpdate,
    iscommentUpdate,
    isMoved,
    dataList,
    message,
  } = useSelector((state) => state.candidates);
  const { filterRecId } = useSelector((state) => state.common);

  const [show, setShow] = useState(false);
  const [isProfile, setIsProfile] = useState(false);
  const [isBulkEmail, setIsBulkEmail] = useState(false);
  const [jobId, setjobId] = useState(0);

  const [list, setList] = useState([]);
  const [data, setData] = useState([]);

  const [cellCaseStatus, setCellCaseStatus] = useState("");
  // const [databaseMove, setDatabaseMove] = useState('Database')

  useEffect(() => {
    document.title = "2nd Level  | Recruitment | Dk Global CRM";
  }, []);
  //const { filterRecId } = JSON.parse(localStorage.getItem("summaryRecFilter"));

  useEffect(() => {
    const fetchList = async () => {
      try {
        if (filterRecId != "") {
          await dispatch(onHoldFilterList({ rec_id: filterRecId.rec_id }));
        } else {
          await dispatch(onHoldList());
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchList();
  }, [dispatch]);

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        setData(dataList);
      }, 200);
    }
    if (isUpdate) {
      dispatch(clearState());
      setData(dataList);
    }
    if (isMoved) {
      dispatch(clearState());
      setLoading(false);
      setSuccess(false);
      dispatch(showSuccess({ msg: message }));
      SetDataMoveTo("");
      const fetchList = async () => {
        try {
          await dispatch(onHoldList());
        } catch (err) {
          console.log(err);
        }
      };
      fetchList();
    }

    // if (isProfileUpdate) {
    //     dispatch(clearState())
    //     const fetchList = async () => {
    //         try {
    //             await dispatch(onHoldList())
    //         } catch (err) {
    //             console.log(err)
    //         }
    //     }
    //     fetchList();
    // }
    if (iscommentUpdate) {
      dispatch(clearState());
      const fetchList = async () => {
        try {
          await dispatch(onHoldList());
        } catch (err) {
          console.log(err);
        }
      };
      fetchList();
    }
  }, [
    isLoading,
    isUpdate,
    iscommentUpdate,
    isProfileUpdate,
    isMoved,
    dispatch,
  ]);

  //const data = React.useMemo(() => dataList, [dataList])

  const [canId, setCanId] = useState(0);
  const [isProfileModal, setIsProfileModal] = useState(false);
  /******comment popup start**** */
  const [refTable, setRefTable] = useState("candidates");
  const [refId, setRefId] = useState(0);
  const [commentsCount, setCommentCount] = useState(0);
  const [title, setTitle] = useState("Candidate Comments");
  const [newStatus, setNewStatus] = useState("");
  const [newProgess, setNewProgess] = useState("");
  const [newStage, setNewStage] = useState("");
  const [candidateStatus, setCandidteStatus] = useState("");
  const [candidateProgess, setCandidateProgess] = useState("");
  const [candidateStage, setCandidateStage] = useState("");
  const [commentPopupShow, setCommentPopupShow] = useState(false);
  /******comment popup end**** */

  const handleCloseProfile = () => {
    setIsProfileModal(false);
    dispatch(clearState());
    const fetchList = async () => {
      try {
        await dispatch(onHoldList());
      } catch (err) {
        console.log(err);
      }
    };
    fetchList();
  };
  const handleClose = () => setShow(false);

  const commentHandleClose = () => {
    setNewStatus("");
    setNewProgess("");
    setNewStage("");
    setCommentPopupShow(false);
    dispatch(clearState());
    const fetchList = async () => {
      try {
        await dispatch(onHoldList());
      } catch (err) {
        console.log(err);
      }
    };
    fetchList();
  };

  const columns = useMemo(
    () => [
      {
        Header: "ID & Preview",
        accessor: "canId",
        Cell: ({ cell }) => {
          const handleShow = async (e) => {
            //await dispatch(fetchById({ id: e.target.dataset.canid }))
            setCanId(e.target.dataset.canid);
            setIsProfileModal(true);
            await dispatch(
              commentList({ ref: "candidates", refId: e.target.dataset.canid })
            );
          };
          return (
            <React.Fragment>
              <div className="pipleFilterCols">
                <span
                  className="dk-chevron-rightIcon"
                  style={{ marginRight: "2px" }}
                >
                  <i
                    className="fa fa-chevron-right"
                    data-canid={cell.row.original.canId}
                    onClick={handleShow}
                  ></i>
                  {/* {cell.row.original.canId} */}
                </span>
                <span className="dk-checkinputIcon dkg-usericon-cols">
                  <UserPopup />
                </span>
                <span className="dk-checkinputIcon">
                  <Checkbox
                    id={cell.row.original.id}
                    checkActiveExtraDiv={checkActiveExtraDiv}
                    checkExtraDivBtnTxt={checkExtraDivBtnTxt}
                  />
                </span>
                <span className="openCricleOptions">
                  <div className="dk-pipeFlagFilterCon">
                    <Dropdown id={`profileIndicator${cell.row.original.id}`}>
                      <Dropdown.Toggle variant="" className="dk-fliterbtn">
                        <i
                          className="fas fa-circle"
                          style={{
                            color: indicatorColor(
                              cell.row.original.profileIndicator
                            ),
                            fontSize: "13px",
                          }}
                        ></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dk-pipleuserFilterMenu">
                        <div className="d-flex">
                          <ProfileIndicatorOptions
                            id={cell.row.original.id}
                            columnId="profileIndicator"
                            updateMyData={updateMyData}
                          />
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </span>
                <span className="openFlagOptions">
                  <div className="dk-pipeFlagFilterCon">
                    <Dropdown id={`flag_type${cell.row.original.id}`}>
                      <Dropdown.Toggle variant="" className="dk-fliterbtn">
                        <i
                          className="fas fa-flag"
                          style={{
                            color: flagColor(cell.row.original.flag_type),
                            fontSize: "15px",
                          }}
                        ></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dk-pipleuserFilterMenu">
                        <div className="d-flex">
                          <FlagOptions
                            id={cell.row.original.id}
                            columnId="flag_type"
                            updateMyData={updateMyData}
                          />
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </span>
                <span className="openCricleOptions">
                  <div
                    className="dk-pipeFlagFilterCon"
                    id={`motivationStatus${cell.row.original.id}`}
                  >
                    <Dropdown>
                      <Dropdown.Toggle variant="" className="dk-fliterbtn">
                        <i
                          className="fa fa-reply-all"
                          style={{
                            color: profileMotivationStatus(
                              cell.row.original.motivationStatus
                            ),
                            fontSize: "13px",
                          }}
                        ></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dk-pipleuserFilterMenu">
                        <div className="d-flex">
                          <CandprofileIndicator
                            id={cell.row.original.id}
                            columnId="motivationStatus"
                            updateMyData={updateMyData}
                          />
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </span>
                <span className="dk-checkDropdown">
                  <Dropdown
                    className="dk-idDropdownMain"
                    id={`profileStatus${cell.row.original.id}`}
                  >
                    <Dropdown.Toggle variant="" id="dropdown-basic">
                      {
                        <FaCheck
                          style={{
                            color: profileStatus(
                              cell.row.original.profileStatus
                            ),
                          }}
                        />
                      }
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <ProfileStatusOptions
                        id={cell.row.original.id}
                        columnId="profileStatus"
                        updateMyData={updateMyData}
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                </span>
              </div>
            </React.Fragment>
          );
        },
      },
      {
        id: "RAID",
        Header: "RAID",
        accessor: "recAdminInfo.uid",
      },
      {
        id: "RCID",
        Header: "RCID",
        accessor: "recInfo.uid",
      },
      {
        id: "AMID",
        Header: "AMID",
        accessor: "amInfo.uid",
      },
      {
        id: "FlagID",
        Header: "FlagID",
        accessor: "flag_type",
      },
      {
        id: "PIID",
        Header: "PIID",
        accessor: "profileIndicator",
      },
      {
        id: "PSID",
        Header: "PSID",
        accessor: "profileStatus",
      },
      {
        id: "PMID",
        Header: "PMID",
        accessor: "motivationStatus",
      },
      {
        Header: "Month",
        accessor: "month",
        className: "pl-3 pr-3",
      },
      {
        id: "RA",
        Header: "RR",
        accessor: "recAdminInfo.firstName",
        Cell: ({ cell }) => {
          const onItemClick = (id, columnId, value, img) => {
            updateMyData(id, columnId, value, img);
          };
          return (
            <React.Fragment>
              <div className="dk-useFilterCon">
                <Dropdown id={`rec_admin${cell.row.original.id}`}>
                  <Dropdown.Toggle variant="" className="dk-fliterbtn">
                    <div className="tooltipMain">
                      <img
                        src={
                          process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                          cell.row.original.recAdminInfo.profileImage
                        }
                        className="filterImg"
                        alt={cell.row.original.recAdminInfo.firstName}
                      />
                      <div className="tooltipText">
                        {cell.row.original.recAdminInfo.firstName +
                          " " +
                          cell.row.original.recAdminInfo.lastName}
                      </div>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dk-pipleuserFilterMenu">
                    <div className="d-flex">
                      <UserList
                        id={cell.row.original.id}
                        columnId="rec_admin"
                        onItemClick={onItemClick}
                      />
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </React.Fragment>
          );
        },
      },
      {
        id: "RC",
        Header: "RC",
        accessor: "recInfo.firstName",
        Cell: ({ cell }) => {
          const onItemClick = (id, columnId, value, img) => {
            updateMyData(id, columnId, value, img);
          };
          return (
            <React.Fragment>
              <div className="dk-useFilterCon">
                <Dropdown id={`rec_id${cell.row.original.id}`}>
                  <Dropdown.Toggle variant="" className="dk-fliterbtn">
                    <div className="tooltipMain">
                      <img
                        src={
                          process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                          cell.row.original.recInfo.profileImage
                        }
                        className="filterImg"
                        alt={cell.row.original.recInfo.firstName}
                      />
                      <div className="tooltipText">
                        {cell.row.original.recInfo.firstName +
                          " " +
                          cell.row.original.recInfo.lastName}
                      </div>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dk-pipleuserFilterMenu">
                    <div className="d-flex">
                      <UserList
                        id={cell.row.original.id}
                        columnId="rec_id"
                        onItemClick={onItemClick}
                      />
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </React.Fragment>
          );
        },
      },
      {
        id: "AM",
        Header: "AM",
        accessor: "amInfo.firstName",
        Cell: ({ cell }) => {
          const onItemClick = (id, columnId, value, img) => {
            updateMyData(id, columnId, value, img);
          };
          return (
            <React.Fragment>
              <div className="dk-useFilterCon">
                <Dropdown id={`am_id${cell.row.original.id}`}>
                  <Dropdown.Toggle variant="" className="dk-fliterbtn">
                    <div className="tooltipMain">
                      <img
                        src={
                          process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                          cell.row.original.amInfo.profileImage
                        }
                        className="filterImg"
                        alt={cell.row.original.amInfo.firstName}
                      />
                      <div className="tooltipText">
                        {cell.row.original.amInfo.firstName +
                          " " +
                          cell.row.original.amInfo.lastName}
                      </div>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dk-pipleuserFilterMenu">
                    <div className="d-flex">
                      <UserList
                        id={cell.row.original.id}
                        columnId="am_id"
                        onItemClick={onItemClick}
                      />
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </React.Fragment>
          );
        },
      },
      {
        id: "company",
        Header: "Company",
        accessor: "jobDetail.company",
        className: "pl-2 pr-2 text-left",
        Cell: ({ cell }) => {
          return cell.row.original.jobDetail.company == "Blank"
            ? ""
            : cell.row.original.jobDetail.company;
        },
      },
      {
        id: "jobtitle",
        Header: "Job Title",
        accessor: "jobDetail.title_description",
        className: "pl-2 pr-2 text-left",
        Cell: ({ cell }) => {
          const JobhandleShow = (e) => {
            setjobId(e.target.dataset.id);
            setIsProfile(true);
            setShow(true);
          };

          return (
            <span
              className="dkg-jobtitle-cols"
              onClick={JobhandleShow}
              style={{ cursor: "pointer" }}
              data-id={cell.row.original.jobDetail.id}
            >
              {cell.row.original.jobDetail.title_description}
            </span>
          );
        },
      },
      {
        Header: "Candidate Name",
        accessor: "name",
        className: "pl-2 pr-2 text-left",
        Cell: ({ cell }) => {
          return (
            <CandidateDocumentModal
              name={cell.row.original.first_name}
              canId={cell.row.original.canId}
              lastName={cell.row.original.last_name}
            />
          );
        },
      },
      {
        Header: "Status",
        accessor: "newprofilestatus",
        Cell: ({ cell }) => {
          const onItemClick = async (id, columnId, value, img) => {
            updateMyData(id, columnId, value, img);
          };
          return (
            <React.Fragment>
              <div className="dk-caseStatusDropCon">
                <Dropdown id={`newprofilestatus${cell.row.original.id}`}>
                  <Dropdown.Toggle
                    variant=""
                    className="dk-caseStatusfliterbtn"
                  >
                    <ProflieStatus value={cell.row.original.newprofilestatus} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="caseStatusMenu">
                    <CanProfilesStatus
                      id={cell.row.original.id}
                      columnId="newprofilestatus"
                      onItemClick={onItemClick}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </React.Fragment>
          );
        },
      },
      {
        Header: "Case Stage",
        accessor: "caseStatus",
        Cell: ({ cell }) => {
          const onItemClick = async (id, columnId, value, img) => {
            updateMyData(id, columnId, value, img);
          };
          return (
            <React.Fragment>
              <div className="dk-caseStatusDropCon">
                <Dropdown id={`caseStatus${cell.row.original.id}`}>
                  <Dropdown.Toggle
                    variant=""
                    className="dk-caseStatusfliterbtn"
                  >
                    <CaseStatus value={cell.row.original.caseStatus} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="caseStatusMenu">
                    <CaseStatusList
                      id={cell.row.original.id}
                      columnId="caseStatus"
                      onItemClick={onItemClick}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </React.Fragment>
          );
        },
      },
      {
        id: "case_progress",
        Header: "Case Progress",
        accessor: "case_progress",
        Cell: ({ cell }) => {
          const onItemClick = async (id, columnId, value, img) => {
            updateMyData(id, columnId, value, img);
          };
          return (
            <React.Fragment>
              <div
                className="dk-caseStatusDropCon"
                onClick={() => {
                  let text = $(
                    `#caseStatus${cell.row.original.id} button .caseDiv`
                  ).html();
                  setCellCaseStatus(text);
                }}
              >
                <Dropdown id={`case_progress${cell.row.original.id}`}>
                  <Dropdown.Toggle
                    variant=""
                    className="dk-caseStatusfliterbtn"
                  >
                    <CaseOther
                      caseStatus={cell.row.original.caseStatus}
                      value={cell.row.original.case_progress}
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="caseStatusMenu">
                    <CaseProgressList
                      caseStatus={$(
                        `#caseStatus${cell.row.original.id} button .caseDiv`
                      ).html()}
                      id={cell.row.original.id}
                      columnId="case_progress"
                      onItemClick={onItemClick}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </React.Fragment>
          );
        },
      },
      {
        id: "case_stage",
        Header: "Case Situation",
        accessor: "case_stage",
        Cell: ({ cell }) => {
          const onItemClick = async (id, columnId, value, img) => {
            updateMyData(id, columnId, value, img);
          };
          return (
            <React.Fragment>
              <div
                className="dk-caseStatusDropCon"
                onClick={() => {
                  let text = $(
                    `#caseStatus${cell.row.original.id} button .caseDiv`
                  ).html();
                  setCellCaseStatus(text);
                }}
              >
                <Dropdown id={`case_stage${cell.row.original.id}`}>
                  <Dropdown.Toggle
                    variant=""
                    className="dk-caseStatusfliterbtn"
                  >
                    <CaseOther
                      caseStatus={cell.row.original.caseStatus}
                      value={cell.row.original.case_stage}
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="caseStatusMenu">
                    <CaseStageList
                      caseStatus={$(
                        `#caseStatus${cell.row.original.id} button .caseDiv`
                      ).html()}
                      id={cell.row.original.id}
                      columnId="case_stage"
                      onItemClick={onItemClick}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </React.Fragment>
          );
        },
      },
      {
        Header: "Comment",
        accessor: "commentCount",
        className: "pl-2 pr-2",
        Cell: ({ cell }) => {
          const commentPoupShow = (e) => {
            setRefId(cell.row.original.canId);
            setCommentCount(cell.row.original.commentCount);
            setCandidteStatus(cell.row.original.caseStatus);
            setCandidateProgess(cell.row.original.case_progress);
            setCandidateStage(cell.row.original.case_stage);
            setCommentPopupShow(true);
          };
          return (
            <>
              {cell.row.original.commentCount === 0 ? (
                <span
                  className="commentBoxCon zeroComment"
                  onClick={commentPoupShow}
                >
                  <span className="numCon zeroCommentCount">
                    {cell.row.original.commentCount}
                  </span>
                </span>
              ) : (
                <span className="commentBoxCon" onClick={commentPoupShow}>
                  <span className="numCon">
                    {cell.row.original.commentCount}
                  </span>
                </span>
              )}
            </>
          );
        },
      },
      {
        Header: "Case Age",
        accessor: "caseage",
        className: "pl-2 pr-2",
        Cell: ({ cell }) => {
          return <>10 Days</>;
        },
      },

      // {
      //     id: 'CandidateFilter',
      //     Header: '',
      //     accessor: 'canId',
      //     Cell: ({ cell }) => {
      //         const handleShow = (e) => {
      //             setCanId(e.target.dataset.canid)
      //             setIsProfileModal(true)
      //         }
      //         return (
      //             <React.Fragment>
      //                 <div className="pipleFilterCols">
      //                     <span className="dk-checkDropdown">
      //                         <Dropdown className="dk-idDropdownMain" id={`profileStatus${cell.row.original.id}`}>
      //                             <Dropdown.Toggle variant="" id="dropdown-basic">
      //                                 {
      //                                     <FaCheck style={{ color: profileStatus(cell.row.original.profileStatus) }} />
      //                                 }
      //                             </Dropdown.Toggle>
      //                             <Dropdown.Menu>
      //                                 <ProfileStatusOptions id={cell.row.original.id} columnId="profileStatus" updateMyData={updateMyData} />
      //                             </Dropdown.Menu>
      //                         </Dropdown>
      //                     </span>
      //                     <span className="openCricleOptions">
      //                         <div className="dk-pipeFlagFilterCon" id={`motivationStatus${cell.row.original.id}`}>
      //                             <Dropdown>
      //                                 <Dropdown.Toggle variant="" className="dk-fliterbtn">
      //                                     <i className="fas fa-user" style={{ color: profileMotivationStatus(cell.row.original.motivationStatus), fontSize: '13px' }}></i>
      //                                 </Dropdown.Toggle>
      //                                 <Dropdown.Menu className="dk-pipleuserFilterMenu">
      //                                     <div className="d-flex">
      //                                         <CandprofileIndicator id={cell.row.original.id} columnId="motivationStatus" updateMyData={updateMyData} />
      //                                     </div>
      //                                 </Dropdown.Menu>
      //                             </Dropdown>
      //                         </div>
      //                     </span>
      //                     <span className="openFlagOptions">
      //                         <div className="dk-pipeFlagFilterCon">
      //                             <Dropdown id={`flag_type${cell.row.original.id}`}>
      //                                 <Dropdown.Toggle variant="" className="dk-fliterbtn">
      //                                     <i className="fas fa-flag" style={{ color: flagColor(cell.row.original.flag_type), fontSize: '15px' }}></i>
      //                                 </Dropdown.Toggle>
      //                                 <Dropdown.Menu className="dk-pipleuserFilterMenu">
      //                                     <div className="d-flex">
      //                                         <FlagOptions id={cell.row.original.id} columnId="flag_type" updateMyData={updateMyData} />
      //                                     </div>
      //                                 </Dropdown.Menu>
      //                             </Dropdown>
      //                         </div>
      //                     </span>
      //                 </div>
      //             </React.Fragment>
      //         )
      //     }
      // },

      {
        id: "category",
        Header: "Category",
        accessor: "category",
        className: "pl-2 pr-2",
      },
      {
        id: "project",
        Header: "Project",
        accessor: "jobDetail.project",
        className: "pl-2 pr-2",
        Cell: ({ cell }) => {
          return cell.row.original.jobDetail.project == "Blank"
            ? ""
            : cell.row.original.jobDetail.project;
        },
      },
      {
        id: "lang",
        Header: "Job Language",
        accessor: "jobDetail.lang",
        className: "pl-2 pr-2",
        Cell: ({ cell }) => {
          return cell.row.original.jobDetail.lang == "Blank"
            ? ""
            : cell.row.original.jobDetail.lang;
        },
      },
      {
        id: "role",
        Header: "Role Type",
        accessor: "jobDetail.role",
        className: "pl-2 pr-2",
        Cell: ({ cell }) => {
          return cell.row.original.jobDetail.role == "Blank"
            ? ""
            : cell.row.original.jobDetail.role;
        },
      },
      {
        id: "cv_source",
        Header: "CV Source",
        accessor: "cv_source",
        className: "pl-2 pr-2",
      },

      // {
      //   Header: "Full Fee",
      //   accessor: "jobDetail.fee",
      //   className: "pl-2 pr-2",
      //   Footer: (info) => {
      //     const total = React.useMemo(
      //       () =>
      //         info.flatRows.reduce(
      //           (sum, row) => Number(row.original.jobDetail.fee) + sum,
      //           0
      //         ),
      //       [info.rows]
      //     );
      //     return <>{total}</>;
      //   },
      // },
      // {
      //   Header: "Refuse/Cr",
      //   accessor: "credit",
      //   Cell: ({ cell }) => {
      //     return (
      //       <EditableCell
      //         id={cell.row.original.id}
      //         columnId="credit"
      //         initialValue={cell.row.original.credit}
      //         updateMyData={updateMyData}
      //       />
      //     );
      //   },
      //   Footer: (info) => {
      //     const total = React.useMemo(
      //       () =>
      //         info.flatRows.reduce(
      //           (sum, row) => Number(row.original.credit) + sum,
      //           0
      //         ),
      //       [info.rows]
      //     );
      //     return <>{total}</>;
      //   },
      // },
      // {
      //   Header: "Bonus",
      //   accessor: "bonus",
      //   Cell: ({ cell }) => {
      //     return (
      //       <EditableCell
      //         id={cell.row.original.id}
      //         columnId="bonus"
      //         initialValue={cell.row.original.bonus}
      //         updateMyData={updateMyData}
      //       />
      //     );
      //   },
      //   Footer: (info) => {
      //     const total = React.useMemo(
      //       () =>
      //         info.flatRows.reduce(
      //           (sum, row) => Number(row.original.bonus) + sum,
      //           0
      //         ),
      //       [info.rows]
      //     );
      //     return <>{total}</>;
      //   },
      // },
      // {
      //   Header: "Final Fee",
      //   accessor: "finalFee",
      //   Cell: ({ cell }) => {
      //     return (
      //       <EditableCell
      //         id={cell.row.original.id}
      //         columnId="finalFee"
      //         initialValue={cell.row.original.finalFee}
      //         updateMyData={updateMyData}
      //       />
      //     );
      //   },
      //   Footer: (info) => {
      //     const total = React.useMemo(
      //       () =>
      //         info.flatRows.reduce(
      //           (sum, row) => Number(row.original.finalFee) + sum,
      //           0
      //         ),
      //       [info.rows]
      //     );
      //     return <>{total}</>;
      //   },
      // },
      {
        Header: "Case Created",
        accessor: "createdAt",
        className: "pl-2 pr-2",
        Cell: ({ cell }) => {
          return (
            <CustomDateFormat
              date={cell.row.original.createdAt}
              format="DD MMM YY, HH:mm"
            />
          );
        },
      },
      {
        Header: "Case Opened",
        accessor: "caseOpened",
        className: "pl-2 pr-2",
        Cell: ({ cell }) => {
          return (
            <CustomDateFormat
              date={cell.row.original.createdAt}
              format="DD MMM YY, HH:mm"
            />
          );
        },
      },
      {
        Header: "Case Updated",
        accessor: "commentAt",
        className: "pl-2 pr-2",
        Cell: ({ cell }) => {
          return daysAgo(cell.row.original.commentAt);
        },
      },
      {
        Header: "Pipeline",
        className: "p-0 dkg-maindatatable-td",
        accessor: "status",
        Cell: ({ cell }) => {
          return (
            <ShowPipelineName
              columnId="status"
              value={
                cell.row.original.status === "To Progress" ? "Qualifying" : ""
              }
            />
          );
        },
      },
      {
        Header: "Year",
        accessor: "year",
        className: "pl-3 pr-3",
      },
    ],
    []
  );

  const [instanceData, setInstanceData] = useState("0");
  const reactTableInstance = (instance) => {
    if (instance) {
      setList(instance.flatRows);
      // console.log(instance)
      setInstanceData(instance);
    }
  };

  const [rtFilterId, setRtFilterId] = useState("0");
  const [rtFilterImg, setRtFilterImg] = useState("0");

  const [raFilterId, setRaFilterId] = useState("0");
  const [raFilterImg, setRaFilterImg] = useState("0");

  const [rcFilterId, setRcFilterId] = useState("0");
  const [rcFilterImg, setRcFilterImg] = useState("0");

  const [amFilterId, setAmFilterId] = useState("0");
  const [amFilterImg, setAmFilterImg] = useState("0");

  const [flagFilterId, setFlagFilterId] = useState("0");
  const [flagFilterImg, setFlagFilterImg] = useState("0");

  const [profileIndicatorFilterId, setProfileIndicatorFilterId] = useState("0");
  const [profileIndicatorFilterImg, setProfileIndicatorFilterImg] =
    useState("0");

  const [profileStatusFilterId, setProfileStatusFilterId] = useState("-1");
  const [profileStatusFilterImg, setProfileStatusFilterImg] = useState("0");

  const [profileMotivationFilterId, setProfileMotivationFilterId] =
    useState("-1");
  const [profileMotivationFilterImg, setProfileMotivationFilterImg] =
    useState("0");

  const [categoryId, setCategoryId] = useState("0");
  const [caseStatusId, setCaseStatusId] = useState("0");
  const [newprofilestatusId, setnewprofilestatusId] = useState("0");
  const [monthsId, setMonthsId] = useState("0");
  const [yearsId, setYearsId] = useState("0");
  const [caseProgressId, setCaseProgressId] = useState("0");
  const [caseStageId, setCaseStageId] = useState("0");
  const [caseSituationId, setCaseSituationId] = useState("0");
  const [companyId, setCompanyId] = useState("0");
  const [projectId, setProjectId] = useState("0");
  const [roleId, setRoleId] = useState("0");
  const [languageId, setLanguageId] = useState("0");
  const [cvSourceId, setCvSourceId] = useState("0");

  const [filter, setFilter] = React.useState([]);

  const selectUserFilter = async (e) => {
    const type = e.target.dataset.type;
    switch (type) {
      case "RTID":
        setRtFilterId(e.target.dataset.id);
        setRtFilterImg(e.target.dataset.img);
        // setFilter({ ...filter, [type]: e.target.dataset.id })
        break;

      case "RAID":
        setRaFilterId(e.target.dataset.id);
        setRaFilterImg(e.target.dataset.img);
        // setFilter({ ...filter, [type]: e.target.dataset.id })
        break;

      case "RCID":
        setRcFilterId(e.target.dataset.id);
        setRcFilterImg(e.target.dataset.img);
        // setFilter({ ...filter, [type]: e.target.dataset.id })
        break;

      case "AMID":
        setAmFilterId(e.target.dataset.id);
        setAmFilterImg(e.target.dataset.img);
        // setFilter({ ...filter, [type]: e.target.dataset.id })
        break;

      case "FlagID":
        setFlagFilterId(e.target.dataset.id);
        setFlagFilterImg(e.target.dataset.img);
        // setFilter({ ...filter, [type]: e.target.dataset.id })
        break;

      case "PIID":
        setProfileIndicatorFilterId(e.target.dataset.id);
        setProfileIndicatorFilterImg(e.target.dataset.img);
        // setFilter({ ...filter, [type]: e.target.dataset.id })
        break;

      case "PSID":
        setProfileStatusFilterId(e.target.dataset.id);
        setProfileStatusFilterImg(e.target.dataset.img);
        // setFilter({ ...filter, [type]: e.target.dataset.id })
        break;

      case "PMID":
        setProfileMotivationFilterId(e.target.dataset.id);
        setProfileMotivationFilterImg(e.target.dataset.img);
        //  setFilter({ ...filter, [type]: e.target.dataset.id })
        break;
    }
  };

  const [isClearAll, setIsClearAll] = useState(false);
  const clearAllFilter = async () => {
    await dispatch(onHoldList());
    setRtFilterId("0");
    setRtFilterImg("0");
    setRaFilterId("0");
    setRaFilterImg("0");
    setRcFilterId("0");
    setRcFilterImg("0");
    setAmFilterId("0");
    setAmFilterImg("0");
    setFlagFilterId("0");
    setFlagFilterImg("0");
    setProfileIndicatorFilterId("0");
    setProfileIndicatorFilterImg("0");
    setProfileStatusFilterId("-1");
    setProfileStatusFilterImg("0");
    setProfileMotivationFilterId("-1");
    setProfileMotivationFilterImg("0");
    setCaseStatusId("0");
    setnewprofilestatusId("0");
    setCaseStatusFilterDDLText("Case Stage");
    setCategoryId("0");
    setCaseProgressId("0");
    setCaseStageId("0");
    setCaseSituationId("0");
    setCompanyId("0");
    setProjectId("0");
    setRoleId("0");
    setLanguageId("0");
    setCvSourceId("0");
    setFilterTags([]);
    setIsClearAll(true);
    setCheckBtnTxt("Check All");
    setCountCheck(0);
    handleToggle();
  };

  const restClearAllFilter = async () => {
    setIsClearAll(false);
  };

  const clearUserFilter = async (e) => {
    const type = e.target.dataset.type;
    switch (type) {
      case "RTID":
        setRtFilterId("0");
        setRtFilterImg("0");
        break;

      case "RAID":
        setRaFilterId("0");
        setRaFilterImg("0");
        break;

      case "RCID":
        setRcFilterId("0");
        setRcFilterImg("0");
        break;

      case "AMID":
        setAmFilterId("0");
        setAmFilterImg("0");
        break;

      case "FlagID":
        setFlagFilterId("0");
        setFlagFilterImg("0");
        break;

      case "PIID":
        setProfileIndicatorFilterId("0");
        setProfileIndicatorFilterImg("0");
        break;

      case "PSID":
        setProfileStatusFilterId("-1");
        setProfileStatusFilterImg("0");
        break;

      case "PMID":
        setProfileMotivationFilterId("-1");
        setProfileMotivationFilterImg("0");
        break;
    }
  };

  const [filterTags, setFilterTags] = useState([]);
  const [caseStatusFilterDDLText, setCaseStatusFilterDDLText] =
    useState("Case Stage");

  const selectOtherFilter = async (e) => {
    const type = e.target.dataset.type;
    const value = e.target.dataset.value;

    let i;
    switch (type) {
      case "year":
        i = filterTags.findIndex(function (x) {
          return x.type === "year";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setYearsId(value);
        break;
      case "month":
        i = filterTags.findIndex(function (x) {
          return x.type === "month";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setMonthsId(value);
        break;

      case "caseStatus":
        i = filterTags.findIndex(function (x) {
          return x.type === "caseStatus";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setCaseStatusId(value);
        setCaseStatusFilterDDLText(value);
        break;

      case "newprofilestatus":
        i = filterTags.findIndex(function (x) {
          return x.type === "newprofilestatus";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setnewprofilestatusId(value);
        break;

      case "category":
        i = filterTags.findIndex(function (x) {
          return x.type === "category";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setCategoryId(value);
        break;

      case "case_progress":
        i = filterTags.findIndex(function (x) {
          return x.type === "case_progress";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setCaseProgressId(value);
        break;

      case "caseSituation":
        i = filterTags.findIndex(function (x) {
          return x.type === "caseSituation";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setCaseSituationId(value);
        break;

      case "case_stage":
        i = filterTags.findIndex(function (x) {
          return x.type === "case_stage";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setCaseStageId(value);
        break;

      case "company":
        i = filterTags.findIndex(function (x) {
          return x.type === "company";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setCompanyId(value);
        break;

      case "project":
        i = filterTags.findIndex(function (x) {
          return x.type === "project";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setProjectId(value);
        break;

      case "role":
        i = filterTags.findIndex(function (x) {
          return x.type === "role";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setRoleId(value);
        break;

      case "lang":
        i = filterTags.findIndex(function (x) {
          return x.type === "lang";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setLanguageId(value);
        break;

      case "cv_source":
        i = filterTags.findIndex(function (x) {
          return x.type === "cv_source";
        });
        if (i === -1) {
          filterTags.push({ type, value });
        } else {
          filterTags.splice(i, 1);
          filterTags.push({ type, value });
        }
        setCvSourceId(value);
        break;

      default:
        i = filterTags.findIndex(function (x) {
          return x.type === type;
        });
        if (i === -1) {
          filterTags.push({ type, value });
        }
        break;
    }
  };

  const removeTag = async (e) => {
    const type = e.target.dataset.type;
    setFilterTags(filterTags.filter((item) => item.type !== type));
    switch (type) {
      case "year":
        setYearsId("0");
        break;
      case "month":
        setMonthsId("0");
        break;
      case "caseStatus":
        setCaseStatusId("0");
        setCaseStatusFilterDDLText("Case Stage");
        break;

      case "newprofilestatus":
        setnewprofilestatusId("0");
        setCaseStatusFilterDDLText("Case Stage");
        break;

      case "category":
        setCategoryId("0");
        break;

      case "case_progress":
        setCaseProgressId("0");
        break;
      case "caseSituation":
        setCaseSituationId("0");
        break;
      case "case_stage":
        setCaseStageId("0");
        break;

      case "company":
        setCompanyId("0");
        break;

      case "project":
        setProjectId("0");
        break;

      case "role":
        setRoleId("0");
        break;

      case "lang":
        setLanguageId("0");
        break;

      case "cv_source":
        setCvSourceId("0");
        break;

      default:
        break;
    }
  };

  // const updateRAData = async (id, columnId, value, uid, firstName, lastName, img) => {
  //     dispatch(updateRa({ id, uid, firstName, lastName, profileImage: img }))
  // }

  // const updateRCData = async (id, columnId, value, uid, firstName, lastName, img) => {
  //     dispatch(updateRc({ id, uid, firstName, lastName, profileImage: img }))
  // }

  // const updateAMData = async (id, columnId, value, uid, firstName, lastName, img) => {
  //     dispatch(updateAm({ id, uid, firstName, lastName, profileImage: img }))
  // }

  const updateMyData = async (id, columnId, value, img) => {
    switch (columnId) {
      case "newprofilestatus":
        $(`#newprofilestatus${id} button .caseDiv`).html(value);
        $(`#newprofilestatus${id} button .caseDiv`)
          .html(value)
          .css("background-color", img);
        break;
      case "profileStatus":
        $(`#profileStatus${id} button svg`).css("color", img);
        break;

      case "profileIndicator":
        $(`#profileIndicator${id} button i`).css("color", img);
        break;

      case "motivationStatus":
        $(`#motivationStatus${id} button i`).css("color", img);
        break;

      case "flag_type":
        $(`#flag_type${id} button i`).css("color", img);
        break;

      case "am_id":
        $(`#am_id${id} button .tooltipMain img`).attr(
          "src",
          process.env.REACT_APP_IMG_CLOUD_BASE_URL + img
        );
        break;

      case "rec_id":
        $(`#rec_id${id} button .tooltipMain img`).attr(
          "src",
          process.env.REACT_APP_IMG_CLOUD_BASE_URL + img
        );
        break;

      case "rec_admin":
        $(`#rec_admin${id} button .tooltipMain img`).attr(
          "src",
          process.env.REACT_APP_IMG_CLOUD_BASE_URL + img
        );
        break;

      case "caseStatus":
        setNewStatus(value);
        setNewProgess("");
        setNewStage("");
        $(`#caseStatus${id} button .caseDiv`)
          .html(value)
          .css("background-color", img);
        $(`#case_progress${id} button .caseDiv`)
          .html("")
          .css("background-color", img);
        $(`#case_stage${id} button .caseDiv`)
          .html("")
          .css("background-color", img);
        break;

      case "case_progress":
        setNewProgess(value);
        setNewStage("");
        $(`#case_progress${id} button .caseDiv`).html(value);
        $(`#case_stage${id} button .caseDiv`).html("");
        break;

      case "case_stage":
        setNewStage(value);
        $(`#case_stage${id} button .caseDiv`).html(value);
        break;
    }

    dispatch(updateTable({ id, key: columnId, value }));

    switch (columnId) {
      case "caseStatus":
        dispatch(updateTable({ id, key: "case_progress", value: "Blank" }));
        dispatch(updateTable({ id, key: "case_stage", value: "Blank" }));
        break;

      case "case_progress":
        dispatch(updateTable({ id, key: "case_stage", value: "Blank" }));
        break;
      default:
        break;
    }
  };

  const [checkBtnTxt, setCheckBtnTxt] = useState("Check All");
  const [countCheck, setCountCheck] = useState(0);
  const [isActive, setIsActive] = useState(false);

  const checkActiveExtraDiv = (isShowExitra, checkedBoxCount) => {
    if (isShowExitra) {
      setIsActive(true);
      setCountCheck(checkedBoxCount);
    } else {
      setIsActive(false);
      setCountCheck(checkedBoxCount);
    }
  };

  const checkExtraDivBtnTxt = (btnTxt) => {
    setCheckBtnTxt(btnTxt);
  };

  const toggleCheckAll = () => {
    var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
    var totalCheckboxes = $('input[name="chkCandidate[]"]').length;
    if (checkedBoxCount < totalCheckboxes) {
      setCheckBtnTxt("Uncheck All");
      setCountCheck(totalCheckboxes);
      $(".chkCandidate").prop("checked", true);
    }
    if (checkedBoxCount == totalCheckboxes) {
      setCheckBtnTxt("Check All");
      setCountCheck(0);
      $(".chkCandidate").prop("checked", false);
      setIsActive(false);
    }
  };

  const handleToggle = () => {
    $(".chkCandidate").prop("checked", false);
    setIsActive(false);
  };

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  // const deleteItemBulk = async () => {
  //     var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
  //     if (checkedBoxCount == 0) {
  //     } else {
  //         var canIds = [];
  //         $.each($(".chkCandidate:checked"), function () {
  //             canIds.push($(this).val());
  //         });
  //         setLoading(true)
  //         setSuccess(false)
  //         await dispatch(moveCandidateRecords({ ids: canIds, status: 'Archive' }))
  //         $('.chkCandidate').prop('checked', false);
  //         setIsActive(false);
  //     }
  // }

  const [dataMoveTo, SetDataMoveTo] = useState("");

  const moveRecords = async (e) => {
    SetDataMoveTo(e.target.dataset.moveto);
  };

  useEffect(() => {
    if (dataMoveTo !== "") {
      (async () => {
        var checkedBoxCount = $('input[name="chkCandidate[]"]:checked').length;
        if (checkedBoxCount == 0) {
        } else {
          var canIds = [];
          $.each($(".chkCandidate:checked"), function () {
            canIds.push($(this).val());
          });

          // if(dataMoveTo==="2022" || dataMoveTo==="2021" || dataMoveTo==="2020" || dataMoveTo==="2019" || dataMoveTo==="2018" || dataMoveTo==="2017" || dataMoveTo==="2016" )
          // {
          //     let newDate = new Date()
          //     let date = newDate.getDate();
          //     let month = newDate.getMonth() + 1;
          //    // let year = newDate.getFullYear();
          //     const defaultCreatedAt = dataMoveTo +"-"+month+"-"+ date
          //     await dispatch(moveDatabaseCandidateRecords({ ids: canIds, status: "Database", createdAt: defaultCreatedAt  }))
          // }else{
          //      await dispatch(moveCandidateRecords({ ids: canIds, status: dataMoveTo }))
          //     }
          let aa = await dispatch(
            moveCandidateRecords({ ids: canIds, status: dataMoveTo })
          );

          $(".chkCandidate").prop("checked", false);
          setIsActive(false);
          if (dataMoveTo == "Download") {
            const fileName = "user-list";
            const fileType =
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
            const fileExtension = ".xlsx";
            const ws = XLSX.utils.json_to_sheet(aa.payload.result);
            const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
            const excelBuffer = XLSX.write(wb, {
              bookType: "xlsx",
              type: "array",
            });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, fileName + fileExtension);
          }
        }
      })();
    }
  }, [dataMoveTo]);

  const showBulkEmailModal = () => {
    setIsBulkEmail(true);
  };
  const hideBulkEmailModal = () => {
    setIsBulkEmail(false);
  };
  return (
    <React.Fragment>
      {commentPopupShow ? (
        <CommentModal
          refTable={refTable}
          refId={refId}
          count={commentsCount}
          title={title}
          commentHandleClose={commentHandleClose}
          candidateStatus={newStatus != "" ? newStatus : candidateStatus}
          candidateProgess={newProgess != "" ? newProgess : candidateProgess}
          candidateStage={newStage != "" ? newStage : candidateStage}
        />
      ) : null}
      <BulkEmailModal show={isBulkEmail} onHide={() => hideBulkEmailModal()} />
      {isProfileModal ? (
        <CandidateProfile
          canid={canId}
          list={list}
          isProfileModal={isProfileModal}
          handleCloseProfile={handleCloseProfile}
        />
      ) : null}
      {isProfile ? (
        <DetialViewPoup jobId={jobId} show={show} onHide={handleClose} />
      ) : null}
      <div
        className="container-fluid dk-candinatePipeMain page-margin-top dkg-pipeline-mainCon
             dkg-pipelines-mainpageCon"
      >
        <div className="row">
          <div className="col-md-12">
            <div className="row dk-pipelinePageHead">
              <div className="col-md-3 dk-pipelineLeftCols">
                <div className="dk-pipelineLeft">
                  <div className="dk-mailshotsSearch mr-2">
                    <div className="input-group">
                      {instanceData !== "0" ? (
                        <GlobalFilter
                          preGlobalFilteredRows={
                            instanceData.preGlobalFilteredRows
                          }
                          globalFilter={instanceData.state.globalFilter}
                          setGlobalFilter={instanceData.setGlobalFilter}
                          isClearAll={isClearAll}
                          restClearAllFilter={restClearAllFilter}
                        />
                      ) : null}
                    </div>
                  </div>
                  {/* <div className="mr-2" title='RT'>
                                        <div className='dk-useFilterNumCon'>
                                            <ResourceTeamFilter list={list} id={rtFilterId} img={rtFilterImg} selectUserFilter={selectUserFilter} />
                                            {
                                                (rtFilterId !== '0') ? <i className="fas fa-times-circle crossUserFilter dk-lrftCross" data-type="RTID" onClick={clearUserFilter}></i> : null
                                            }
                                        </div>
                                    </div> */}
                  <div className="mr-2" title="RR">
                    <div className="dk-useFilterNumCon">
                      <RecruiterAdminFilter
                        list={list}
                        id={raFilterId}
                        img={raFilterImg}
                        selectUserFilter={selectUserFilter}
                      />
                      {raFilterId !== "0" ? (
                        <i
                          className="fas fa-times-circle crossUserFilter dk-lrftCross"
                          data-type="RAID"
                          onClick={clearUserFilter}
                        ></i>
                      ) : null}
                    </div>
                  </div>
                  <div className="mr-2" title="RC">
                    <div className="dk-useFilterNumCon">
                      <RecruiterFilter
                        list={list}
                        id={rcFilterId}
                        img={rcFilterImg}
                        selectUserFilter={selectUserFilter}
                      />
                      {rcFilterId !== "0" ? (
                        <i
                          className="fas fa-times-circle crossUserFilter dk-lrftCross"
                          data-type="RCID"
                          onClick={clearUserFilter}
                        ></i>
                      ) : null}
                    </div>
                  </div>
                  <div className="mr-2" title="AM">
                    <div className="dk-useFilterNumCon">
                      <AccountManagerFilter
                        list={list}
                        id={amFilterId}
                        img={amFilterImg}
                        selectUserFilter={selectUserFilter}
                      />
                      {amFilterId !== "0" ? (
                        <i
                          className="fas fa-times-circle crossUserFilter dk-lrftCross"
                          data-type="AMID"
                          onClick={clearUserFilter}
                        ></i>
                      ) : null}
                    </div>
                  </div>
                  {/* <div className="mr-2" title='RA'>
                                        <div className='dk-useFilterNumCon'>
                                            <RecruiterAdminFilter list={list} id={raFilterId} img={raFilterImg} selectUserFilter={selectUserFilter} />
                                            {
                                                (raFilterId !== '0') ? <i className="fas fa-times-circle crossUserFilter dk-lrftCross" data-type="RAID" onClick={clearUserFilter}></i> : null
                                            }
                                        </div>
                                    </div> */}
                  <div className="mr-2">
                    <button className="dk-reoderBtn" onClick={clearAllFilter}>
                      <i className="fas fa-sync-alt"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-6 pl-0 pr-0 dk-pipelineCenterCols">
                <div className="dk-filterTagsCon">
                  {filterTags.length > 0 ? (
                    filterTags.length > 0 &&
                    filterTags.map((item, index) => (
                      <ButtonGroup
                        className="dk-filterbtnGrp mr-2"
                        key={`tagButton${index}`}
                      >
                        <Button className="dk-filterbtntext">
                          {item.value}
                        </Button>
                        <Button
                          className="dk-filterbtnicon"
                          data-type={item.type}
                          onClick={removeTag}
                        >
                          <i
                            className="fas fa-times"
                            data-type={item.type}
                            onClick={removeTag}
                          ></i>
                        </Button>
                      </ButtonGroup>
                    ))
                  ) : (
                    <div className="dk-pipeline-pagetitle">
                      Qualify (L3) -{" "}
                      <span className="countNum">{dataList.length}</span>{" "}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-3 dk-pipelineRightCols">
                <div className="dk-pipelineright">
                  <button
                    className="dk-reoderBtn mr-2"
                    onClick={showBulkEmailModal}
                  >
                    <i className="fas fa-envelope"></i>
                  </button>
                  <div className="dk-btnCicleCon">
                    {isActive ? (
                      <button
                        className="dk-reoderBtn mr-2"
                        data-moveto="Download"
                        onClick={moveRecords}
                      >
                        <i
                          data-moveto="Download"
                          onClick={moveRecords}
                          class="fa fa-file"
                          aria-hidden="true"
                        ></i>
                      </button>
                    ) : (
                      ""
                    )}

                    <div className="dk-newCVRightFilter mr-2">
                      <AdvanceFilterModal
                        list={list}
                        selectUserFilter={selectUserFilter}
                        selectOtherFilter={selectOtherFilter}
                      />
                    </div>
                    <div className="mr-2 dk-pipe-prfind-Con">
                      <ProfileIndicatorFilter
                        list={list}
                        id={profileIndicatorFilterId}
                        img={profileIndicatorFilterImg}
                        selectUserFilter={selectUserFilter}
                      />
                      {profileIndicatorFilterId !== "0" ? (
                        <i
                          className="fas fa-times-circle crossUserFilter dk-lrftCross"
                          data-type="PIID"
                          onClick={clearUserFilter}
                        ></i>
                      ) : null}
                    </div>
                    <div className="dk-flagfilterIcon mr-2">
                      <div className="dk-useFilterNumCon">
                        <div className="dk-lhs-FlagFilter">
                          <FlagFilter
                            list={list}
                            id={flagFilterId}
                            img={flagFilterImg}
                            selectUserFilter={selectUserFilter}
                          />
                          {flagFilterId !== "0" ? (
                            <i
                              className="fas fa-times-circle crossUserFilter dk-lrftCross"
                              data-type="FlagID"
                              onClick={clearUserFilter}
                            ></i>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className=" mr-2 dkg-candfilterIconCon">
                      <CandidateFilter
                        list={list}
                        id={profileMotivationFilterId}
                        img={profileIndicatorFilterImg}
                        selectUserFilter={selectUserFilter}
                      />
                      {profileMotivationFilterId !== "-1" ? (
                        <i
                          className="fas fa-times-circle crossUserFilter dk-lrftCross"
                          data-type="PMID"
                          onClick={clearUserFilter}
                        ></i>
                      ) : null}
                    </div>
                    <div className="dk-trickfilterIcon mr-2">
                      <TrickFilter
                        list={list}
                        id={profileStatusFilterId}
                        img={profileStatusFilterImg}
                        selectUserFilter={selectUserFilter}
                      />
                      {profileStatusFilterId !== "-1" ? (
                        <i
                          className="fas fa-times-circle crossUserFilter dk-lrftCross"
                          data-type="PSID"
                          onClick={clearUserFilter}
                        ></i>
                      ) : null}
                    </div>
                    <div className="pipeline-cafileterCon">
                      <CaseStatusFilter
                        list={list}
                        caseStatusFilterDDLText={caseStatusFilterDDLText}
                        selectOtherFilter={selectOtherFilter}
                      />
                      {caseStatusFilterDDLText !== "Case Stage" ? (
                        <i
                          className="fas fa-times-circle crossUserFilter dk-lrftCross"
                          data-type="caseStatus"
                          onClick={removeTag}
                        ></i>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid dk-candinatePipeMain page-margin-top dkg-pipeline-mainCon dkg-pipelines-main-tableCon ">
        <div className="row">
          <div className="col-md-12">
            <div className="pipe-newCVTableCon table-responsive">
              <Table
                data={data}
                columns={columns}
                getInstanceCallback={reactTableInstance}
                loading={isLoading}
                // filter={filter}
                rtFilterId={rtFilterId}
                raFilterId={raFilterId}
                rcFilterId={rcFilterId}
                amFilterId={amFilterId}
                profileIndicatorFilterId={profileIndicatorFilterId}
                profileStatusFilterId={profileStatusFilterId}
                profileMotivationFilterId={profileMotivationFilterId}
                flagFilterId={flagFilterId}
                categoryId={categoryId}
                caseStatusId={caseStatusId}
                newprofilestatusId={newprofilestatusId}
                monthsId={monthsId}
                yearsId={yearsId}
                caseProgressId={caseProgressId}
                caseStageId={caseStageId}
                caseSituationId={caseSituationId}
                companyId={companyId}
                projectId={projectId}
                roleId={roleId}
                languageId={languageId}
                cvSourceId={cvSourceId}
                updateMyData={updateMyData}
                // updateRAData={updateRAData}
                // updateRCData={updateRCData}
                // updateAMData={updateAMData}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`batch-actions-menu-wrapper ${
          isActive ? "activePopup" : ""
        }`}
        style={{ width: "1316px" }}
      >
        <div className="num-of-actions_wrapper">
          <div className="num-of-actions">{countCheck}</div>
        </div>
        <div className="batch-actions-title-section pl-0">
          <div className="dk-rowSelected">
            <span>Row Selected</span>
          </div>
        </div>
        <div
          className="batch-actions-item dk-checkAll"
          onClick={toggleCheckAll}
        >
          <span>
            <i className="fas fa-check"></i>
          </span>
          {checkBtnTxt}
        </div>

        {/* <div className="batch-actions-item bulkemail-checkboxbg">
          <span data-moveto="bluk-email" onClick={showBulkEmailModal}>
            <i className="fa fa-envelope" data-moveto="bluk-email"></i>
          </span>
          Bulk Email
        </div> */}
        <div
          className="batch-actions-item newcase-checkboxbg"
          data-moveto="New CV"
          onClick={moveRecords}
        >
          <span data-moveto="New CV">
            <i className="fa fa-arrow-circle-left" data-moveto="New CV"></i>
          </span>
          New Case
        </div>
        <div
          className="batch-actions-item recadmin-checkboxbg"
          data-moveto="Selected"
          onClick={moveRecords}
        >
          <span data-moveto="Selected" onClick={moveRecords}>
            <i
              className="fa fa-arrow-circle-left"
              data-moveto="Selected"
              onClick={moveRecords}
            ></i>
          </span>
          Selected
        </div>
        {/* <div className="batch-actions-item processing-checkboxbg" data-moveto="To Progress" onClick={moveRecords}>
        <span data-moveto="To Progress" onClick={moveRecords}>
            <i className="fa fa-arrow-circle-right" data-moveto="To Progress" onClick={moveRecords}></i>
        </span>
        Qualifying
    </div> */}
        <div
          className="batch-actions-item notreachable-checkboxbg"
          data-moveto="In Progress"
          onClick={moveRecords}
        >
          <span data-moveto="In Progress" onClick={moveRecords}>
            <i
              className="fa fa-arrow-circle-right"
              data-moveto="In Progress"
              onClick={moveRecords}
            ></i>
          </span>
          Sendouts
        </div>
        <div
          className="batch-actions-item sendouts-checkboxbg"
          data-moveto="Sendout"
          onClick={moveRecords}
        >
          <span data-moveto="Sendout" onClick={moveRecords}>
            <i
              className="fa fa-arrow-circle-right"
              data-moveto="Sendout"
              onClick={moveRecords}
            ></i>
          </span>
          Interviews
        </div>
        <div
          className="batch-actions-item joboffer-checkboxbg"
          data-moveto="Job Offer"
          onClick={moveRecords}
        >
          <span data-moveto="Job Offer" onClick={moveRecords}>
            <i
              className="fa fa-arrow-circle-right"
              data-moveto="Job Offer"
              onClick={moveRecords}
            ></i>
          </span>
          Job Offer
        </div>

        <div
          className="batch-actions-item calling-checkboxbg"
          data-moveto="Case Query"
          onClick={moveRecords}
        >
          <span data-moveto="Case Query" onClick={moveRecords}>
            <i
              className="fa fa-arrow-circle-right"
              data-moveto="Case Query"
              onClick={moveRecords}
            ></i>
          </span>
          In Progress
        </div>

        <div
          className="batch-actions-item interseted-checkboxbg"
          data-moveto="Completed"
          onClick={moveRecords}
        >
          <span data-moveto="Completed" onClick={moveRecords}>
            <i
              className="fa fa-arrow-circle-right"
              data-moveto="Completed"
              onClick={moveRecords}
            ></i>
          </span>
          Completed
        </div>

        <div
          className="batch-actions-item closed-checkboxbg"
          data-moveto="Closed"
          onClick={moveRecords}
        >
          <span data-moveto="Closed" onClick={moveRecords}>
            <i
              className="fa fa-arrow-circle-right"
              data-moveto="Closed"
              onClick={moveRecords}
            ></i>
          </span>
          Closed
        </div>
        <div
          className="batch-actions-item dkg-newdb-checkboxbg"
          data-moveto="Database"
          onClick={moveRecords}
        >
          <span data-moveto="Database" onClick={moveRecords}>
            <i
              className="fa fa-arrow-circle-right"
              data-moveto="Database"
              onClick={moveRecords}
            ></i>
          </span>
          Database
        </div>
        <div
          className="batch-actions-item archived-checkboxbg"
          data-moveto="Archive"
          style={{ backgroundColor: "#6a0707", color: "#fff" }}
          onClick={moveRecords}
        >
          <span data-moveto="Archive" onClick={moveRecords}>
            <i
              className="fa fa-arrow-circle-right"
              data-moveto="Archive"
              onClick={moveRecords}
            ></i>
          </span>
          Archived
        </div>
        {/* <div className="batch-actions-item dkg-newdb-checkboxbg">
                    <Dropdown className='dkg-batch-actions-dropdown'>
                        <Dropdown.Toggle variant="" className='dkg-batch-actions-dropdown-toggle'>
                            {databaseMove}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='dkg-batch-actions-dropdown-menu'>
                            <Dropdown.Item className='dkg-batch-actions-dropdown-item' data-moveto="2022" onClick={moveRecords} style={{ backgroundColor: "#5A4C72", color: "#fff" }} >2022
                                <span className='dkg-db-move-righticon'>
                                    <i className="fa fa-arrow-circle-right"></i>
                                </span></Dropdown.Item>
                            <Dropdown.Item className='dkg-batch-actions-dropdown-item' data-moveto="2021" onClick={moveRecords} style={{ backgroundColor: "#5A4C72", color: "#fff" }} >2021
                                <span className='dkg-db-move-righticon'>
                                    <i className="fa fa-arrow-circle-right"></i>
                                </span></Dropdown.Item>
                            <Dropdown.Item className='dkg-batch-actions-dropdown-item' data-moveto="2020" onClick={moveRecords} style={{ backgroundColor: "#5A4C72", color: "#fff" }} >2020
                                <span className='dkg-db-move-righticon'>
                                    <i className="fa fa-arrow-circle-right"></i>
                                </span></Dropdown.Item>
                            <Dropdown.Item className='dkg-batch-actions-dropdown-item' data-moveto="2019" onClick={moveRecords} style={{ backgroundColor: "#5A4C72", color: "#fff" }} >2019
                                <span className='dkg-db-move-righticon'>
                                    <i className="fa fa-arrow-circle-right"></i>
                                </span></Dropdown.Item>
                            <Dropdown.Item className='dkg-batch-actions-dropdown-item' data-moveto="2018" onClick={moveRecords} style={{ backgroundColor: "#5A4C72", color: "#fff" }} >2018
                                <span className='dkg-db-move-righticon'>
                                    <i className="fa fa-arrow-circle-right"></i>
                                </span></Dropdown.Item>
                            <Dropdown.Item className='dkg-batch-actions-dropdown-item' data-moveto="2017" onClick={moveRecords} style={{ backgroundColor: "#5A4C72", color: "#fff" }} >2017
                                <span className='dkg-db-move-righticon'>
                                    <i className="fa fa-arrow-circle-right"></i>
                                </span></Dropdown.Item>

                        </Dropdown.Menu>
                    </Dropdown>
                </div> */}
        {/* <div className="batch-actions-item db-ready-checkboxbg" data-moveto="DB Ready" onClick={moveRecords}>
                    <span data-moveto="DB Ready" onClick={moveRecords}>
                        <i className="fa fa-arrow-circle-right" data-moveto="DB Ready" onClick={moveRecords}></i>
                    </span>
                     DB Ready
                </div> */}
        {/* <DeleteModal loading={loading} success={success} deleteItem={deleteItemBulk} /> */}
        {/* <div className="batch-actions-item dkg-newblocked-checkboxbg" data-moveto="blocked">
                    <span data-moveto="blocked">
                        <i className="fa fa-arrow-circle-right" data-moveto="blocked"></i>
                    </span>
                    Blocked
                </div> */}
        <div
          className="batch-actions-delete-item CloseAll d-flex align-items-center justify-content-center"
          onClick={handleToggle}
        >
          <span>
            <i className="fas fa-times"></i>
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OnHold;
