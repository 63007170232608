import React from 'react';
import GetStartedLeftPanel from './leftpanel/GetStartedLeftPanel';
// import './moduletraining.scss';

const GetStarted = () => {
    return (
        <>
            <div className="dk-moduleTraining-main">
                <div className="dk-moduleTraining-cols">
                    <GetStartedLeftPanel />
                    <div className="dk-moduleTraining-rightPanel">
                        <div className="title">Overview</div>
                        <div className="dk-moduleTraining-rightCols">

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GetStarted;
