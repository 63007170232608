import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import "./livejobsrightfilter.scss";
import Modal from "react-bootstrap/Modal";

const AttachmentFileModal = ({ show, onHide,title }) => {
  return (
    <>
      <div className="dk-jobsMainModalCon">
        <Modal
          show={show}
          onHide={onHide}
          dialogClassName="dkmb-rightFliterModal"
        >
          <Modal.Header closeButton className="dkmb-Fliterheader">
            <Modal.Title className="dkmb-FliterTitle">
              ATTACHMENT FILES
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="dkmb-Fliterbody dkg-attachment-file-md-body">
            <div className="dkg-compose-mail-right-con">
              <div className="dkg-cand-mail-attachtment-ul">
                <li className="dkg-cand-mail-attachtment-li">
                  <div className="dkg-file-typeicon">
                    <i className="far fa-file-pdf"></i>
                  </div>
                  <div className="dkg-cand-mail-file">
                    <div className="dkg-cand-mail-file-name">{title}</div>
                    <div className="dkg-cand-mail-cross-icon">
                      <i className="fas fa-eye"></i>
                    </div>
                    <div className="dkg-cand-mail-cross-icon">
                      <i className="fas fa-times"></i>
                    </div>
                  </div>
                </li>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default AttachmentFileModal;
