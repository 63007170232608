import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";

const SupplierMenu = () => {
    const pathname = window.location.pathname;
    // const segment1 = pathname.split("/")[1];
    const segment2 = pathname.split("/")[2];

    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | DK MasterBox";
    }, [pathname]);

    return (
        <React.Fragment>
            <div className="dk-navBarMain dk-accountingNavbar dk-dkglobalAdminSupplierMainMenu">
                <Route>
                    <ul>
                        <li><Link to="/business-overview/suppliers/overview" className={(segment2 === 'suppliers') ? 'active' : ''}>Dashboard</Link></li>
                        <li><Link to="#" className={`${(segment2 === 'structure') ? '' : ''}`}>Supplier 1</Link></li>
                        <li><Link to="#" className={`${(segment2 === 'teams') ? '' : ''}`}>Supplier 2</Link></li>
                        <li><Link to="#" className={`${(segment2 === 'pipelines') ? '' : ''}`}>Supplier 3</Link></li>
                        <li><Link to="#" className={`${(segment2 === 'team-1') ? '' : ''}`}>Supplier 4</Link></li>
                        <li><Link to="#" className={`${(segment2 === 'team-2') ? '' : ''}`}>Supplier 5</Link></li>
                        <li><Link to="#" className={`${(segment2 === 'team-3') ? '' : ''}`}>Supplier 6</Link></li>
                        <li><Link to="#" className={`${(segment2 === 'team-4') ? '' : ''}`}>Supplier 7</Link></li>
                        <li><Link to="#" className={`${(segment2 === 'team-5') ? '' : ''}`}>Supplier 8</Link></li>
                        <li><Link to="#" className={`${(segment2 === 'team-5') ? '' : ''}`}>Supplier 9</Link></li>
                        <li><Link to="#" className={`${(segment2 === 'team-5') ? '' : ''}`}>Supplier 10</Link></li>
                    </ul>
                </Route>
            </div>
        </React.Fragment>
    );
}

export default SupplierMenu;