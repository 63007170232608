import React from 'react';
import Header from "../../elements/header";
// import Navbar from '../../elements/header/navbar/mainMenu/dkglobaladmin/BusinessDevelopMenu';
import Navbar from '../../elements/header/navbar/mainMenu/dkglobaladmin/HrMenu';

const NewleadsLayout = ({ children }) => {
    return (
        <React.Fragment>
            <Header dashboardheaderLink="Dashboard -" dashboardLink="/dashboard/plateform" mainheaderLink="New Business" moduleLink="/dashboard/clients" linkcolor="#3c4c62" mainheaderTitle=" - New Leads" textcolor="#5c737d" />
            <Navbar />
            {children}
        </React.Fragment>
    )
}
export default NewleadsLayout;
