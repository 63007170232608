import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import './managementdashboard.scss';
// import ReoprtsImg from "../../../../assets/images/report.png";
// import RecruitmentImg from "../../../../assets/images/building_icon.png";
import CompanyImg from "../../../../assets/images/company-white.png";
import DocumentsImg from "../../../../assets/images/document-white.png";
import MarketingManagerImg from "../../../../assets/images/get-money.png";
import ChecklistImg from "../../../../assets/images/checklist-white.png";
// import Training from "../../../../assets/images/resourcemanagement_icon.png";
import Training from "../../../../assets/images/training.png";
import DKGIconImg from "../../../../assets/images/user-white.png";
import HrImg from "../../../../assets/images/hr_white.png";
// import WiningTrophyImg from "../../../../assets/images/trophy.png";
import ItTeamImg from "../../../../assets/images/software-dev.png";
import ResourceImg from '../../../../assets/images/recruitment.png';





import Moduleheader from "../../../elements/header/index";
import { useDispatch, useSelector } from "react-redux";
import { setMyModule } from "../../../../slice/modules/moduleSlice";

const ModuleManagementDashboard = () => {
    const dispatch = useDispatch();
    const { userInfo } = useSelector(state => state.auth);

    const history = useHistory();
    const setModule = async (myModule) => {
        await dispatch(setMyModule({ module: myModule }))
        switch (myModule) {
            case 'legalmodule':
                history.push("/legalmodule/legal-module");
                break;

            case 'business-overview':
                history.push("/business-overview/dashboard");
                break;
            case 'hr':
                history.push("/business-overview/hr/leaves");
                break;
            case 'training':
                history.push("/business-overview/training");
                break;
            case 'suppliers':
                history.push("/business-overview/suppliers/overview");
                break;
            case 'technical':
                history.push("/business-overview/technical");
                break;

            default:
                break;
        }
    }
    return (
        < >
            <Moduleheader dashboardheaderLink="Dashboard -" dashboardLink="/dashboard/plateform" linkcolor="#3c4c62" mainheaderTitle="BUSINESS OVERVIEW" textcolor="#884c5e" />
            <div className="dk-mngDashboard-main">
                <div className="dk-mngDashboard-container">
                    <div className="title mb-2">BUSINESS OVERVIEW DEPARTMENT</div>
                    <div className="d-flex">
                        <div className="dk-mngModule-box">
                            <Link to="#" onClick={() => setModule('business-overview')} style={{ background: '#884c5e' }}>
                                <img src={DKGIconImg} alt="" />
                                <p>CASE STATS</p>
                            </Link>
                        </div>
                        <div className="dk-mngModule-box">
                            <Link to="#" onClick={() => setModule('legalmodule')} style={{ background: '#535255' }}>
                                <img src={DocumentsImg} alt="" />
                                <p>Legal Documents</p>
                            </Link>
                        </div>
                        <div className="dk-mngModule-box">
                            <Link to="#"  onClick={() => setModule('hr')} style={{ background: '#d58b5d' }}>
                                <img src={HrImg} alt="" />
                                <p>HR</p>
                            </Link>
                        </div>
                      
                    </div>
                    <div className="d-flex">
                        <div className="dk-mngModule-box">
                            <Link to="#" onClick={() => setModule('training')} style={{ background: '#396a95' }}>
                                <img src={Training} alt="" />
                                <p>Training</p>
                            </Link>
                        </div>
                        <div className="dk-mngModule-box">
                            <Link to="#" onClick={() => setModule('suppliers')} style={{ background: '#01796f' }}>
                                <img src={ResourceImg} alt="" />
                                <p>Suppliers</p>
                            </Link>
                        </div>
                        <div className="dk-mngModule-box">
                            <Link to="#"  onClick={() => setModule('technical')} style={{ background: '#B55355' }}>
                                <img src={ItTeamImg} alt="Technical" />
                                <p>Technical</p>
                            </Link>
                        </div>
                        <div className="dk-mngModule-box d-none">
                            <Link to="#" style={{ background: '#144773' }}>
                                <img src={ChecklistImg} alt="" />
                                <p>Checklist</p>
                            </Link>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="dk-mngModule-box d-none">
                            <Link to="#" onClick={() => setModule('profit-loss')} style={{ background: '#009688' }}>
                                <img src={MarketingManagerImg} alt="" />
                                <p>Profit & Loss</p>
                            </Link>
                        </div>
                        <div className="dk-mngModule-box d-none">
                            <Link to="#" onClick={() => setModule('legalmodule')} style={{ background: '#535255' }}>
                                <img src={DocumentsImg} alt="" />
                                <p>Legal Documents</p>
                            </Link>
                        </div>
                        <div className="dk-mngModule-box d-none">
                            <Link to="#" onClick={() => setModule('dkg-projects')} style={{ background: '#5A4C72' }}>
                                <img src={CompanyImg} alt="" />
                                <p>DKG Projects</p>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ModuleManagementDashboard